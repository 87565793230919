import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { CarTypeContainerTypeEntity } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeEntity';
import { Seconds } from '~/framework/typeAliases';
import { ICarTypeData, ISimpleCarTypeData } from '~/framework/server-api/masters/carType';

// Entityの循環参照を防ぐため、表示に必要な名前だけを持つEntityとして定義する
// 現状ではstoreで管理されていないため、更新の際は新しいものを生成する
// そのため、CarTypeEntityの更新に対して、Reactiveにならない課題がある
// TODO: storeを再整備後に、SimpleCarTypeEntity用のstoreを作り、
// CarTypeEntityの更新に追従する仕組みを実装する
export class SimpleCarTypeEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  orderGroup: OrderGroupEntity;

  constructor(data: ISimpleCarTypeData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    // 一時的に直接構成するが、将来的にはstoreを通したい
    this.orderGroup = new OrderGroupEntity(data.orderGroup);
  }
}

export class CarTypeEntity extends SimpleCarTypeEntity {
  drivingSpeedCoefficient: number;
  durationOfBoarding: Seconds;
  durationOfAlighting: Seconds;
  orderGroup: OrderGroupEntity;
  loadableContainerTypes: CarTypeContainerTypeEntity[];

  constructor(data: ICarTypeData, orderGroup: OrderGroupEntity, loadableContainerTypes: CarTypeContainerTypeEntity[]) {
    super(data);
    this.drivingSpeedCoefficient = data.drivingSpeedCoefficient;
    this.durationOfBoarding = data.durationOfBoarding;
    this.durationOfAlighting = data.durationOfAlighting;
    this.orderGroup = orderGroup;
    this.loadableContainerTypes = loadableContainerTypes;
  }

  update(data: ICarTypeData, orderGroup: OrderGroupEntity, loadableContainerTypes: CarTypeContainerTypeEntity[]): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of CarTypeEntity');
    }
    this.name = data.name;
    this.drivingSpeedCoefficient = data.drivingSpeedCoefficient;
    this.durationOfBoarding = data.durationOfBoarding;
    this.durationOfAlighting = data.durationOfAlighting;
    this.orderGroup = orderGroup;
    this.loadableContainerTypes = loadableContainerTypes;
  }
}
