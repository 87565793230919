import { Maybe, PersistentId } from '~/framework/typeAliases';
import { OrderEntity } from '~/framework/domain/schedule/order/orderEntity';
import { secsToHhMm } from '~/framework/services/date-time/date-time';

// NOTE: ルート化可能な受注
export type IRoutableOrder = {
  id: PersistentId;
  name: string;
};

// NOTE: form 上のルート化グループの item
export type IOrderRoutingGroupItem = {
  orderIds: Maybe<string>[];
};

// NOTE: ルート化可能な受注の表示名を取得する
export function getRoutableOrderDisplayName(order: OrderEntity): string {
  const collectablePeriodTemplate = order.activePlan.collectablePeriodTemplateName ?? '';
  const collectablePeriodStart =
    order.activePlan.collectablePeriodStart !== undefined ? secsToHhMm(order.activePlan.collectablePeriodStart) : '';
  const collectablePeriodEnd =
    order.activePlan.collectablePeriodEnd !== undefined ? `〜${secsToHhMm(order.activePlan.collectablePeriodEnd)}` : '';
  const isGenerationSiteTaskSelected = order.generationSiteTasks.length > 0;
  // NOTE: 排出場作業が指定されていれば排出場作業を、そうでなければ例外的作業を表示する
  const tasks = isGenerationSiteTaskSelected
    ? order.generationSiteTasks
        .map((task) => {
          const wasteTypeText = task.wasteType?.name ? `|${task.wasteType?.name}` : '';
          const containerTypeText = task.containerType?.name ? `|${task.containerType?.name}` : '';
          const num =
            task.containerNum && task.containerType?.unitName
              ? `|${task.containerNum}${task.containerType?.unitName}`
              : '';
          return `${task.taskType.name}${wasteTypeText}${containerTypeText}${num}`;
        })
        .join(' ')
    : order.irregularTasks.map((irregularTask) => `${irregularTask.name}`).join(', ');
  return `${order.generationSite.name}[${order.client.name}], ${collectablePeriodTemplate}${collectablePeriodStart}${collectablePeriodEnd}, ${tasks}`;
}
