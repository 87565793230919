import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  disposalSite$createSymbol,
  ICreateDisposalSiteData,
  disposalSite$updateSymbol,
  IUpdateDisposalSiteData,
  disposalSite$getAllSymbol,
} from '~/framework/server-api/masters/disposalSite';

import { DisposalSiteMapper } from '~/framework/domain/masters/disposal-site/disposalSiteMapper';

import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';

export const disposalSiteSymbol = Symbol('disposalSite');

export class DisposalSiteApplicationService {
  private readonly serverApis: ServerApiManager;
  private disposalSiteMapper: DisposalSiteMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.disposalSiteMapper = new DisposalSiteMapper(
      store.masters.disposalSite,
      store.masters.client,
      store.masters.user
    );
  }

  // region list
  async getAll(): Promise<DisposalSiteEntity[]> {
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const result = await disposalSite$getAllApi.getAll();
    const entities = this.disposalSiteMapper.map(result);
    return entities;
  }
  // endregion

  async create(data: ICreateDisposalSiteData): Promise<DisposalSiteEntity> {
    const disposalSite$createApi = this.serverApis.get(disposalSite$createSymbol);
    const [result] = await disposalSite$createApi.create([data]);
    const [entity] = await this.getByIds([result.id]);
    return entity;
  }

  async update(data: IUpdateDisposalSiteData): Promise<DisposalSiteEntity> {
    const disposalSite$updateApi = this.serverApis.get(disposalSite$updateSymbol);
    const [result] = await disposalSite$updateApi.update([data]);

    // TODO: 非効率なのでdisposalSite$updateApi.updateの返り値を修正する
    const [entity] = await this.getByIds([result]);
    return entity;
  }
  // endregion

  // region import
  async createMultiple(data: ICreateDisposalSiteData[]): Promise<DisposalSiteEntity[]> {
    const disposalSite$createApi = this.serverApis.get(disposalSite$createSymbol);
    const result = await disposalSite$createApi.create(data);
    const entities = await this.getByIds(result.map((res) => res.id));
    return entities;
  }

  async updateMultiple(updateData: IUpdateDisposalSiteData[]): Promise<DisposalSiteEntity[]> {
    const disposalSite$updateApi = this.serverApis.get(disposalSite$updateSymbol);
    const result = await disposalSite$updateApi.update(updateData);

    const entities = await this.getByIds(result);
    return entities;
  }
  // endregion

  async getByIds(ids: string[]): Promise<DisposalSiteEntity[]> {
    // TODO ここは getById に書き換えたい、全部取る必要はない
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const disposalSites = await disposalSite$getAllApi.getAll();
    const result = disposalSites.filter((disposalSite) => ids.includes(disposalSite.id));
    const entities = this.disposalSiteMapper.map(result);
    return entities;
  }
}
