import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import {
  baseTaskType$getAllSymbol,
  ICreateTaskTypeData,
  IUpdateTaskTypeData,
  taskType$createSymbol,
  taskType$getAllSymbol,
  taskType$updateSymbol,
} from '~/framework/server-api/masters/taskType';
import { BaseTaskTypeEntity, TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { BaseTaskTypeMapper, TaskTypeMapper } from '~/framework/domain/masters/task-type/taskTypeMapper';
import { IllegalStateException } from '~/framework/core/exception';
import { Store } from '~/framework/domain/store';

export const taskTypeSymbol = Symbol('taskType');

export class TaskTypeApplicationService {
  private readonly serverApis: ServerApiManager;
  private taskTypeMapper: TaskTypeMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.taskTypeMapper = new TaskTypeMapper(
      store.masters.driver,
      store.masters.car,
      store.masters.baseSite,
      store.masters.driverAttendanceTemplate,
      store.masters.user
    );
  }

  async getAll(): Promise<TaskTypeEntity[]> {
    const result = await this.serverApis.get(taskType$getAllSymbol).getAll();
    return this.taskTypeMapper.map(result);
  }

  async getBaseAll(): Promise<BaseTaskTypeEntity[]> {
    const result = await this.serverApis.get(baseTaskType$getAllSymbol).getAll();
    return new BaseTaskTypeMapper().map(result);
  }

  //  本当はMapper とか使うんだとおもわれる
  async create(createData: ICreateTaskTypeData, baseTaskTypes: BaseTaskTypeEntity[]): Promise<TaskTypeEntity> {
    const baseTaskType = baseTaskTypes.find((bTT) => bTT.id === createData.baseTaskTypeId);
    if (!baseTaskType) throw new IllegalStateException('something wrong');

    const result = await this.serverApis.get(taskType$createSymbol).create(createData);
    if (result.__typename === 'TaskTypeMutationError') throw result;

    return this.taskTypeMapper.mapSingle({
      id: result.id,
      name: result.name,
      baseTaskType,
      bannedDrivers: result.bannedDrivers,
    });
  }

  async update(data: IUpdateTaskTypeData, baseTaskTypes: BaseTaskTypeEntity[]): Promise<TaskTypeEntity> {
    const result = await this.serverApis.get(taskType$updateSymbol).update(data);
    if (result.__typename === 'TaskTypeMutationError') throw result;
    const baseTaskType = baseTaskTypes.find((bTT) => bTT.id === result.baseTaskType.id);
    if (!baseTaskType) throw new IllegalStateException('something wrong');

    return this.taskTypeMapper.mapSingle({
      id: result.id,
      name: result.name,
      baseTaskType,
      bannedDrivers: result.bannedDrivers,
    });
  }
}
