import { WithoutId, Maybe } from '~/framework/typeAliases';

import { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

import { IConnection } from '~/framework/server-api/connection';
import { CreateGenerationSiteInput, GenerationSite, UpdateGenerationSiteInput } from '~/graphql/graphQLServerApi';

export const generationSite$createSymbol = Symbol('generationSite$createApi');
export const generationSite$getByIdsSymbol = Symbol('generationSite$getByIdsSymbol');
export const generationSite$getByKeywordsSymbol = Symbol('generationSite$getByKeywordsSymbol');
export const generationSite$updateSymbol = Symbol('generationSite$updateApi');

export type GenerationSiteApiTypes = {
  [generationSite$getByIdsSymbol]: IGetByIdsApi;
  [generationSite$getByKeywordsSymbol]: IGetByKeywordsApi;
  [generationSite$createSymbol]: ICreateApi;
  [generationSite$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: WithoutId<ICreateGenerationSiteData>[]): Promise<ICreateResultData[]>;
}

export interface ICreateResultData {
  id: string;
  createdAt: Date;
  // 今のところ createdBy はサポートされていないが、本当はここで返した方がよい
  // createdBy: IUserData;
}

export type ICreateGenerationSiteData = CreateGenerationSiteInput;

export type IGenerationSiteData = GenerationSite;

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IGenerationSiteData[]>;
}

export { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

export interface IGetByKeywordsApi {
  /**
   * キーワードで排出場を検索する
   * @param first
   * @param after
   * @param condition
   */
  getByKeywords(first: number, after: Maybe<string>, condition: ICondition): Promise<IConnection<IGenerationSiteData>>;
}

export interface ICondition {
  clientId: Maybe<string>;
  keywords: Maybe<string[]>;
  since: Maybe<Date>;
  orderBy: GenerationSitesByKeywordsOrder;
}

export interface IUpdateApi {
  update(data: IUpdateGenerationSiteData[]): Promise<string[]>;
}

export type IUpdateGenerationSiteData = UpdateGenerationSiteInput;
