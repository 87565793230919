import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import {
  driverAttendanceTemplate$getAllSymbol,
  driverAttendanceTemplate$getByIdsSymbol,
  driverAttendanceTemplate$createSymbol,
  driverAttendanceTemplate$updateSymbol,
  IUpdateDriverAttendanceTemplateData,
  ICreateDriverAttendanceTemplateData,
} from '~/framework/server-api/masters/driverAttendanceTemplate';
import { DriverAttendanceTemplateMapper } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateMapper';

export const driverAttendanceTemplateSymbol = Symbol('driverAttendanceTemplate');

export class DriverAttendanceTemplateApplicationService {
  private readonly serverApis: ServerApiManager;
  private driverAttendanceTemplateMapper: DriverAttendanceTemplateMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.driverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(store.masters.driverAttendanceTemplate);
  }

  async getAll(): Promise<DriverAttendanceTemplateEntity[]> {
    const api = this.serverApis.get(driverAttendanceTemplate$getAllSymbol);
    const result = await api.getAll();
    return this.driverAttendanceTemplateMapper.map(result);
  }

  async getByIds(ids: string[]): Promise<DriverAttendanceTemplateEntity[]> {
    const api = this.serverApis.get(driverAttendanceTemplate$getByIdsSymbol);
    const result = await api.getByIds(ids);
    const entities = this.driverAttendanceTemplateMapper.map(result);
    return entities;
  }

  async create(data: ICreateDriverAttendanceTemplateData): Promise<DriverAttendanceTemplateEntity> {
    const api = this.serverApis.get(driverAttendanceTemplate$createSymbol);
    const ids = await api.create([data]);
    const entities = await this.getByIds(ids);
    return entities[0];
  }

  async update(data: IUpdateDriverAttendanceTemplateData): Promise<DriverAttendanceTemplateEntity> {
    const api = this.serverApis.get(driverAttendanceTemplate$updateSymbol);
    const ids = await api.update([data]);
    const entities = await this.getByIds(ids);
    return entities[0];
  }
}
