import { OfficeSetting, UpdateOfficeSettingInput } from '~/graphql/graphQLServerApi';
export const officeSetting$getSymbol = Symbol('officeSetting$get');
export const officeSetting$updateSymbol = Symbol('officeSetting$update');

export type OfficeSettingApiTypes = {
  [officeSetting$getSymbol]: IGetApi;
  [officeSetting$updateSymbol]: IUpdateApi;
};

export interface IGetApi {
  get(): Promise<IOfficeSettingData>;
}

export type IOfficeSettingData = OfficeSetting;

export interface IUpdateApi {
  update(data: IUpdateOfficeSettingData): Promise<string>;
}

export type IUpdateOfficeSettingData = UpdateOfficeSettingInput;
