import { PackingStyleTaskTypeDefaultApiTypes } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';
import { PackingStyle } from '~/graphql/graphQLServerApi';
export const packingStyle$getAllSymbol = Symbol('getAll');

export type PackingStyleApiTypes = {
  [packingStyle$getAllSymbol]: IGetAllApi;
} & PackingStyleTaskTypeDefaultApiTypes;

export interface IGetAllApi {
  getAll(): Promise<IPackingStyleData[]>;
}

export type IPackingStyleData = PackingStyle;
