import { v4 as uuidv4 } from 'uuid';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { CarTypeContainerTypeEntity } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeEntity';

import { round } from '~/framework/domain/number';

/**
 * 搭載できるコンテナのフォーム上のデータ
 */
export interface ILoadableContainerTypeFormValues {
  id: string;
  containerTypeId: Maybe<string>;
  emptyCount: Maybe<number>;
  emptyCountOccupation: Maybe<number>;
  /**
   * 見えている合計の満載の値
   */
  fullCount: Maybe<number>;
  lowerTierFullCount: Maybe<number>;
  upperTierFullCount: Maybe<number>;
  fullCountOccupation: Maybe<number>;
  isUpperTierAvailable: boolean;

  /**
   * 手動で編集する時の満載の値
   * ここに値を入力すると一段目と二段目に分配しなければいけないので分けている
   */
  editableFullCount: Maybe<number>;

  /**
   * 値の範囲をはみ出てしまっている様なものは補正する
   */
  correctData(): void;

  /**
   * 1段目と2段目に入力された内容から、満載の値を算出する
   */
  updateFullCount(): void;

  /**
   * 登録に必要な情報が登録されているかどうか
   * 空、満載、どちらか一方のみ埋まっていればよい事にする
   */
  isValid(): boolean;

  /**
   * 削除できるかどうか
   * 空、満載、どちらか一つでも入力されていなかったらそれは削除してもいいという事にする
   * @param includeSpecifiedContainerType コンテナが指定されているものを含むかどうか
   */
  isRemovable(includeSpecifiedContainerType: boolean): boolean;

  isEqualTo(another: ILoadableContainerTypeFormValues): boolean;
}

export class LoadableContainerTypeFormData implements ILoadableContainerTypeFormValues {
  private _editableFullCount: Maybe<number>;
  private _isUpperTierAvailable: boolean;

  id: string;
  containerTypeId: Maybe<string>;
  emptyCount: Maybe<number>;
  emptyCountOccupation: Maybe<number>;
  fullCount: Maybe<number>;

  lowerTierFullCount: Maybe<number>;
  upperTierFullCount: Maybe<number>;
  fullCountOccupation: Maybe<number>;

  set editableFullCount(value: Maybe<number>) {
    if (value === undefined || (value as any) === '') {
      this._editableFullCount = undefined;
      this.fullCount = undefined;
      this.lowerTierFullCount = undefined;
      this.upperTierFullCount = undefined;
    } else {
      this._editableFullCount = value;
      this.lowerTierFullCount = this._editableFullCount;
    }
  }

  get editableFullCount(): Maybe<number> {
    return this._editableFullCount;
  }

  set isUpperTierAvailable(value: boolean) {
    const maxCount = 9999.99;
    this._isUpperTierAvailable = value;
    if (!this._isUpperTierAvailable) {
      if (this.upperTierFullCount) {
        // 1段目と2段目を合算するが最大値オーバーした場合は最大値にする
        this.lowerTierFullCount =
          (this.lowerTierFullCount ?? 0) + this.upperTierFullCount > maxCount
            ? maxCount
            : (this.lowerTierFullCount ?? 0) + this.upperTierFullCount;
        this.upperTierFullCount = 0;
        // 合計値を更新する
        this.updateFullCount();
      }
    }
  }

  get isUpperTierAvailable(): boolean {
    return this._isUpperTierAvailable;
  }

  constructor(
    id: string,
    containerTypeId: Maybe<string>,
    emptyCount: Maybe<number>,
    emptyCountOccupation: Maybe<number>,
    lowerTierFullCount: Maybe<number>,
    upperTierFullCount: Maybe<number>,
    fullCountOccupation: Maybe<number>,
    isUpperTierAvailable: boolean
  ) {
    this.id = id;
    this.containerTypeId = containerTypeId;
    this.emptyCount = round(emptyCount);
    this.emptyCountOccupation = emptyCountOccupation;
    this.lowerTierFullCount = round(lowerTierFullCount);
    this.upperTierFullCount = round(upperTierFullCount);
    this.fullCountOccupation = fullCountOccupation;
    this._isUpperTierAvailable = isUpperTierAvailable;

    this.updateFullCount();
  }

  correctData(): void {
    const maxCount = 9999.99;
    const maxOccupation = 100;
    if (this.emptyCount !== undefined) {
      if ((this.emptyCount as any) === '') this.emptyCount = 0;
      if (this.emptyCount < 0) this.emptyCount = 0;
      if (this.emptyCount > maxCount) this.emptyCount = maxCount;
    }
    if (this.emptyCountOccupation !== undefined) {
      if ((this.emptyCountOccupation as any) === '') this.emptyCountOccupation = 0;
      if (this.emptyCountOccupation < 0) this.emptyCountOccupation = 0;
      if (maxOccupation < this.emptyCountOccupation) this.emptyCountOccupation = maxOccupation;
    }
    if (this.fullCount !== undefined) {
      if ((this.fullCount as any) === '') this.fullCount = 0;
      if (this.fullCount < 0) this.fullCount = 0;
      // fullCountはlower + upperの値なので、maxCount * 2 まで
      if (this.fullCount > maxCount * 2) this.fullCount = maxCount * 2;
    }
    // 上下どちらかが入力された場合にはどちらかは補完する
    if (this.lowerTierFullCount !== undefined && this.upperTierFullCount === undefined) {
      this.upperTierFullCount = 0;
    }
    if (this.upperTierFullCount !== undefined && this.lowerTierFullCount === undefined) {
      this.lowerTierFullCount = 0;
    }
    if (this.lowerTierFullCount !== undefined) {
      // 気持ち悪いのだが、number として解釈できないものを入力されると空文字になるため
      if ((this.lowerTierFullCount as any) === '') this.lowerTierFullCount = 0;
      if (this.lowerTierFullCount < 0) this.lowerTierFullCount = 0;
      if (this.lowerTierFullCount > maxCount) this.lowerTierFullCount = maxCount;
    }
    if (this.upperTierFullCount !== undefined) {
      // 気持ち悪いのだが、number として解釈できないものを入力されると空文字になるため
      if ((this.upperTierFullCount as any) === '') this.upperTierFullCount = 0;
      if (this.upperTierFullCount < 0) this.upperTierFullCount = 0;
      if (this.upperTierFullCount > maxCount) this.upperTierFullCount = maxCount;
    }
    if (this.fullCountOccupation !== undefined) {
      if ((this.fullCountOccupation as any) === '') this.fullCountOccupation = 0;
      if (this.fullCountOccupation < 0) this.fullCountOccupation = 0;
      if (maxOccupation < this.fullCountOccupation) this.fullCountOccupation = maxOccupation;
    }

    this.updateFullCount();
  }

  isValid(): boolean {
    // 空が入力されていない事は許さないが、満載が入力されていない事は許可される
    // 現場に置いてくるだけで引き上げないという事はあり得るらしい
    const isEmptyCountValid = this.emptyCount !== undefined && 0 < this.emptyCount;
    return (
      this.containerTypeId !== undefined &&
      isEmptyCountValid &&
      this.emptyCountOccupation !== undefined &&
      this.fullCountOccupation !== undefined
    );
  }

  isRemovable(includeSpecifiedContainerType: boolean): boolean {
    return (
      (includeSpecifiedContainerType || this.containerTypeId === undefined) &&
      this.emptyCount === undefined &&
      this.fullCount === undefined
    );
  }

  updateFullCount(): void {
    if (this.lowerTierFullCount !== undefined && this.upperTierFullCount !== undefined) {
      this.fullCount = this.lowerTierFullCount + this.upperTierFullCount;
      // 二進数で循環小数同士の足し算になった場合、丸め誤差が発生する場合があるため足し算結果も端数処理する
      this.fullCount = round(this.fullCount);
    }
    this._editableFullCount = this.fullCount;
  }

  isEqualTo(another: ILoadableContainerTypeFormValues): boolean {
    return (
      this.containerTypeId === another.containerTypeId &&
      this.emptyCount === another.emptyCount &&
      this.emptyCountOccupation === another.emptyCountOccupation &&
      this.lowerTierFullCount === another.lowerTierFullCount &&
      this.upperTierFullCount === another.upperTierFullCount &&
      this.fullCountOccupation === another.fullCountOccupation &&
      this.isUpperTierAvailable === another.isUpperTierAvailable
    );
  }

  static clone(original: ILoadableContainerTypeFormValues): LoadableContainerTypeFormData {
    const id = uuidv4();
    return new LoadableContainerTypeFormData(
      id,
      original.containerTypeId,
      original.emptyCount,
      original.emptyCountOccupation,
      original.lowerTierFullCount,
      original.upperTierFullCount,
      original.fullCountOccupation,
      original.isUpperTierAvailable
    );
  }
}

export class LoadableContainerTypeFormValuesFactory {
  /**
   * デフォルトのデータを生成する
   * 占有率はひとまず 100% という事になっている
   */
  static buildDefault(): ILoadableContainerTypeFormValues {
    const id = uuidv4();
    return new LoadableContainerTypeFormData(id, undefined, undefined, 100, undefined, undefined, 100, true);
  }

  static buildByContainerTypeId(containerTypeId: PersistentId): ILoadableContainerTypeFormValues {
    const data = LoadableContainerTypeFormValuesFactory.buildDefault();
    data.containerTypeId = containerTypeId;
    return data;
  }

  static buildByLoadableContainerType(
    loadableContainerType: CarTypeContainerTypeEntity
  ): ILoadableContainerTypeFormValues {
    const id = uuidv4();
    return new LoadableContainerTypeFormData(
      id,
      loadableContainerType.containerType.id,
      loadableContainerType.emptyCount,
      loadableContainerType.emptyCountOccupation * 100,
      loadableContainerType.lowerTierFullCount,
      loadableContainerType.upperTierFullCount,
      loadableContainerType.fullCountOccupation * 100,
      loadableContainerType.isUpperTierAvailable
    );
  }
}
