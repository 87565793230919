import { defaultPreloadStatusApiValueToEnumMap } from '../preloadOption';
import { defaultRouteCollectionApiValueToEnumMap } from '../routeCollectionOption';
import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { GenerationSiteEntity as IGenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DefaultRouteCollectionOptionsValues } from '~/framework/view-models/routeCollectionOption';
import { DefaultPreloadStatus } from '~/framework/view-models/preloadOption';
import {
  defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues,
} from '~/framework/view-models/fixedArrivalTimeReportOption';
import { MarginType } from '~/framework/domain/typeAliases';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { DefaultCollectablePeriod } from '~/components/pages/masters/generation-site/r-generation-site/defaultCollectablePeriod';
import { ids } from '~/framework/core/entity';

export interface IGenerationSiteFormPanelOption extends IOpenPanelOption {
  /**
   * 登録が完了した時に呼ばれて欲しいコールバック
   * @param createdGenerationSite 新規作成したgenerationSiteのEntityが渡される。編集の場合はundefined
   */
  closeCallback?: (createdGenerationSite: Maybe<IGenerationSiteEntity>) => Promise<void>;

  /**
   * 登録完了ボタンのラベル
   */
  registerButtonLabel?: string;

  /**
   * 「続けて登録」のボタンを表示しない場合にtrueを指定する
   */
  disableContinueRegistrationButton?: boolean;

  /**
   * 指定された値をフォームの初期値としてセットする
   */
  initialFormValues?: Partial<IGenerationSiteFormValues>;
}

/**
 * GenerationSite を編集するためのもの
 */
export interface IGenerationSiteFormPanel
  extends IEntityFormPanel<IGenerationSiteEntity, IGenerationSiteFormPanelOption> {}

export class GenerationSiteFormPanel
  extends AbstractEntityFormPanel<IGenerationSiteEntity, IGenerationSiteFormPanelOption>
  implements IGenerationSiteFormPanel {}

export interface IGenerationSiteFormValues {
  clientId: Maybe<PersistentId>;
  name: string;
  nameRuby: string;
  zipCode: Maybe<string>;
  address1: string;
  address2: string;
  address3: string;
  address4: Maybe<string>;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
  note: Maybe<string>;
  attachments: UploadedFile[];
  inputAttachments: File[];
  attachmentsToAdd: File[];
  attachmentsToRemove: string[];
  bannedDriverIds: PersistentId[];
  defaultAssignedDriverId: Maybe<PersistentId>;
  defaultAssignedDisposalSiteId: Maybe<PersistentId>;
  defaultAssignableCarIds: PersistentId[];
  defaultAssignableCarTypeIds: PersistentId[];
  defaultAvoidHighWays: boolean;
  defaultDurationAtEntrance: number;
  hasRestPeriod: boolean;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  isAddressValidated: boolean;
  isAddressComplete: boolean;
  defaultCollectablePeriodTemplateId: Maybe<PersistentId>;
  defaultCollectablePeriodDistinct: Maybe<number>;
  defaultCollectablePeriodStart: Maybe<number>;
  defaultCollectablePeriodEnd: Maybe<number>;
  defaultRouteCollectionAllowed: DefaultRouteCollectionOptionsValues;
  defaultPreloadStatus: DefaultPreloadStatus;
  defaultIsFixedArrivalTimeReportNeeded: DefaultIsFixedArrivalTimeReportNeededOptionsValues;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: Maybe<string>;
}

export const getInitialFormValuesByGenerationSite = (
  generationSite: IGenerationSiteEntity
): IGenerationSiteFormValues => {
  const defaultRouteCollectionAllowed = defaultRouteCollectionApiValueToEnumMap.get(
    generationSite.defaultRouteCollectionAllowed
  );
  if (defaultRouteCollectionAllowed === undefined)
    throw new Error('Invalid value for `defaultRouteCollectionAllowed`.');

  const defaultPreloadStatus = defaultPreloadStatusApiValueToEnumMap.get(generationSite.defaultPreloadStatus);
  if (defaultPreloadStatus === undefined) throw new Error('Invalid value for `defaultPreloadStatus`.');

  const defaultIsFixedArrivalTimeReportNeeded = defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap.get(
    generationSite.defaultIsFixedArrivalTimeReportNeeded
  );
  if (defaultIsFixedArrivalTimeReportNeeded === undefined)
    throw new Error('Invalid value for `defaultIsFixedArrivalTimeReportNeeded`.');

  // NOTE: デフォルト到着時間テンプレートの template, start, end からフォームに反映すべき値を計算してくれるオブジェクト
  const defaultCollectablePeriod = new DefaultCollectablePeriod(
    generationSite.defaultCollectablePeriodTemplate,
    generationSite.defaultCollectablePeriodStart,
    generationSite.defaultCollectablePeriodEnd
  );

  return {
    clientId: generationSite.client.id,
    name: generationSite.name,
    nameRuby: generationSite.nameRuby,
    zipCode: generationSite.zipCode,
    address1: generationSite.address1,
    address2: generationSite.address2,
    address3: generationSite.address3,
    address4: generationSite.address4,
    latitude: generationSite.latitude,
    longitude: generationSite.longitude,
    note: generationSite.note,
    attachments: generationSite.attachments,
    inputAttachments: [],
    attachmentsToAdd: [],
    attachmentsToRemove: [],
    bannedDriverIds: ids(generationSite.bannedDrivers),
    defaultAssignedDriverId: generationSite.defaultAssignedDriver?.id,
    defaultAssignedDisposalSiteId: generationSite.defaultAssignedDisposalSite?.id,
    defaultAssignableCarIds: ids(generationSite.defaultAssignableCars),
    defaultAssignableCarTypeIds: ids(generationSite.defaultAssignableCarTypes),
    defaultAvoidHighWays: generationSite.defaultAvoidHighways,
    defaultDurationAtEntrance: generationSite.defaultDurationAtEntrance,
    hasRestPeriod: generationSite.hasRestPeriod,
    restPeriodStart: generationSite.restPeriodStart,
    restPeriodEnd: generationSite.restPeriodEnd,
    isAddressValidated: true, // 既に永続化された(検証済み)値なのでtrue
    isAddressComplete: generationSite.isAddressComplete,
    defaultCollectablePeriodTemplateId: defaultCollectablePeriod.templateId,
    defaultCollectablePeriodDistinct: defaultCollectablePeriod.distinct,
    defaultCollectablePeriodStart: defaultCollectablePeriod.start,
    defaultCollectablePeriodEnd: defaultCollectablePeriod.end,
    defaultRouteCollectionAllowed,
    defaultPreloadStatus,
    defaultIsFixedArrivalTimeReportNeeded,
    defaultMarginTypeOfFixedArrivalTime: generationSite.defaultMarginTypeOfFixedArrivalTime,
    defaultMarginOfFixedArrivalTime: generationSite.defaultMarginOfFixedArrivalTime,
    noteForOffice: generationSite.noteForOffice,
  };
};
