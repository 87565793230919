import { CarAttendance, UpdateCarAttendanceInput } from '~/graphql/graphQLServerApi';

export const carAttendance$getByDateRangeSymbol = Symbol('carAttendance$getByDateRangeSymbol');

export const carAttendance$updateSymbol = Symbol('carAttendance$updateSymbol');

export type CarAttendanceApiTypes = {
  [carAttendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [carAttendance$updateSymbol]: IUpdateApi;
};

export type ICarAttendanceData = CarAttendance;

export interface IGetByDateRangeApi {
  getByDateRange(start: Date, end: Date, carIds?: Array<string>): Promise<ICarAttendanceData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateCarAttendanceData): Promise<ICarAttendanceData>;
}

export type IUpdateCarAttendanceData = UpdateCarAttendanceInput;
