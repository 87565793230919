import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { ITypedAsyncEvent, TypedAsyncEvent } from '~/framework/events/typedAsyncEvent';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DriverAttendanceEntity as IDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { ISnackbarData } from '~/framework/view-models/snackbarData';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { IDriverAttendanceError } from '~/components/common/r-driver-monthly-attendances-dialog/driverAttendanceError';
import { SearchOrdersConditions } from '~/pages/schedule/orders/searchOrders';

export type EditDriverMonthlyAttendanceEvent = {
  /**
   * 対象の乗務員
   * まぁこれは取れていると考えてもよいでしょう
   */
  driver: DriverEntity;

  /**
   * 基準となる日
   * この日が含まれる様なカレンダーを表示する
   */
  baseDate: Date;

  /**
   * 他ドライバーの勤怠があれば与える
   * 他ドライバーの勤怠情報が分かると primaryCar がカブっていた時に警告ができる
   */
  driverAttendances?: Maybe<IDriverAttendanceEntity[]>;

  /**
   * 勤怠に関するエラー情報
   * 例えばxx月xx日に残業の時間を増やさないとダメ、みたいな情報
   */
  errors?: Maybe<IDriverAttendanceError[]>;
};

export type CloseDriverMonthlyAttendanceEvent = {
  /**
   * 対象の乗務員
   */
  driver: DriverEntity;
};

export type CreateScheduleEvent = {
  /**
   * 対象の日
   */
  date: Date;
  /**
   * 対象の受注グループ
   */
  orderGroupId: PersistentId;
};

/**
 * グローバルに管理する必要のあるイベントを集約するやつ
 */
export class EventManager {
  /**
   * 乗務員の勤怠を月単位で編集したい時のイベント
   */
  openDriverMonthlyAttendancesFormEvent: ITypedAsyncEvent<EditDriverMonthlyAttendanceEvent>;
  /**
   * 乗務員の勤怠の月単位の編集ダイアログが閉じた時のイベント
   */
  closeDriverMonthlyAttendancesFormEvent: ITypedEvent<CloseDriverMonthlyAttendanceEvent>;
  /**
   * ロードが開始した時に呼ばれる
   */
  loadStartEvent: ITypedEvent<void>;
  /**
   * ロードが終了した時に呼ばれる
   */
  loadFinishEvent: ITypedEvent<void>;
  /**
   * Snackbar を開きたい時に呼ぶ
   */
  snackbarAddEvent: ITypedEvent<ISnackbarData>;
  /**
   * 配車表を作成したい時に呼ぶ
   */
  createScheduleEvent: ITypedAsyncEvent<CreateScheduleEvent>;
  /**
   * 条件付きで受注検索画面を開きたいとき
   */
  searchOrdersEvent: ITypedEvent<SearchOrdersConditions>;

  constructor() {
    this.openDriverMonthlyAttendancesFormEvent = new TypedAsyncEvent();
    this.closeDriverMonthlyAttendancesFormEvent = new TypedEvent();
    this.loadStartEvent = new TypedEvent();
    this.loadFinishEvent = new TypedEvent();
    this.snackbarAddEvent = new TypedEvent();
    this.createScheduleEvent = new TypedAsyncEvent();
    this.searchOrdersEvent = new TypedEvent();
  }
}
