import { StoredMapperBase } from '~/framework/core/mapper';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { ContainerTypeTaskTypeStore } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeStore';
import { IContainerTypeTaskTypeData } from '~/framework/server-api/masters/containerTypeTaskType';
import { ContainerTypeMapper } from '../containerTypeMapper';
import { TaskTypeMapper } from '../../task-type/taskTypeMapper';
import { ContainerTypeStore } from '../containerTypeStore';
import { PackingStyleStore } from '../../packing-style/packingStyleStore';
import { DriverStore } from '../../driver/driverStore';
import { CarStore } from '../../car/carStore';
import { BaseSiteStore } from '../../base-site/baseSiteStore';
import { DriverAttendanceTemplateStore } from '../../driver-attendance-template/driverAttendanceTemplateStore';
import { UserStore } from '../../user/userStore';

export class ContainerTypeTaskTypeMapper extends StoredMapperBase<
  IContainerTypeTaskTypeData,
  ContainerTypeTaskTypeEntity
> {
  protected store: ContainerTypeTaskTypeStore;
  private readonly containerTypeMapper: ContainerTypeMapper;
  private readonly taskTypeMappter: TaskTypeMapper;

  constructor(
    store: ContainerTypeTaskTypeStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore,
    driverStore: DriverStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new ContainerTypeMapper(containerTypeStore, packingStyleStore);
    this.taskTypeMappter = new TaskTypeMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
  }

  protected instantiateWithData(data: IContainerTypeTaskTypeData): ContainerTypeTaskTypeEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    return new ContainerTypeTaskTypeEntity(data, containerType, taskType);
  }

  protected updateWithData(data: IContainerTypeTaskTypeData, entity: ContainerTypeTaskTypeEntity): void {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    entity.update(data, containerType, taskType);
  }
}
