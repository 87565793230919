import _ from 'lodash';
import { Maybe } from '~/framework/typeAliases';
import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';
import { ICreateWasteTypeData, IUpdateWasteTypeData } from '~/framework/server-api/masters/wasteType';

import { ensure } from '~/framework/core/value';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';

export interface IFormValues {
  /** waste type entity id */
  id: Maybe<string>;
  /** 廃棄物カテゴリ */
  category: Maybe<WasteCategory>;
  /** ベースとなる JWNET の廃棄物マスター */
  jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>;
  /** 細分類コード */
  fourthCategoryCode: Maybe<string>;
  /** 表示 */
  status: WasteTypeStatus;
  /** よく使う項目 */
  isProminent: boolean;
  /** 表示名 */
  name: string;

  isDirty(formValues: IFormValues): boolean;

  getCreateData(): ICreateWasteTypeData;

  getUpdateData(): IUpdateWasteTypeData;

  clone(): IFormValues;
}

abstract class FormValuesBase implements IFormValues {
  id: Maybe<string>;
  category: Maybe<WasteCategory>;
  jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>;
  fourthCategoryCode: Maybe<string>;
  status: WasteTypeStatus;
  isProminent: boolean;
  name: string;

  constructor(
    id: Maybe<string>,
    category: Maybe<WasteCategory>,
    jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>,
    fourthCategoryCode: Maybe<string>,
    status: WasteTypeStatus,
    isProminent: boolean,
    name: string
  ) {
    this.id = id;
    this.category = category;
    this.jwnetWasteMaster = jwnetWasteMaster;
    this.fourthCategoryCode = fourthCategoryCode;
    this.status = status;
    this.isProminent = isProminent;
    this.name = name;
  }

  abstract isDirty(formValues: IFormValues): boolean;

  abstract clone(): IFormValues;

  getCreateData(): ICreateWasteTypeData {
    const code = (() => {
      if (this.jwnetWasteMaster === undefined) return undefined;
      return `${this.jwnetWasteMaster.firstCategoryCode}${this.jwnetWasteMaster.secondCategoryCode}${
        this.jwnetWasteMaster.thirdCategoryCode
      }${this.fourthCategoryCode || '000'}`;
    })();
    return {
      category: this.category!,
      code,
      name: this.name,
      status: this.status!,
      isProminent: this.isProminent!,
    };
  }

  getUpdateData(): IUpdateWasteTypeData {
    return {
      id: this.id!,
      name: this.name,
      status: this.status!,
      isProminent: this.isProminent!,
    };
  }
}

export class CreateFormValues extends FormValuesBase {
  static buildEmpty(): CreateFormValues {
    return new CreateFormValues(undefined, undefined, undefined, undefined, WasteTypeStatus.Displayed, false, '');
  }

  isDirty(arg: IFormValues): boolean {
    const getComparableObject = (formValues: IFormValues) => formValues.getCreateData();
    return !_.isEqual(getComparableObject(this), getComparableObject(arg));
  }

  clone(): IFormValues {
    return new CreateFormValues(
      this.id,
      this.category,
      this.jwnetWasteMaster,
      this.fourthCategoryCode,
      this.status,
      this.isProminent,
      this.name
    );
  }
}

export class UpdateFormValues extends FormValuesBase {
  constructor(
    id: string,
    category: WasteCategory,
    jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>,
    status: WasteTypeStatus,
    isProminent: boolean,
    name: string
  ) {
    super(id, category, jwnetWasteMaster, undefined, status, isProminent, name);
    if (this.category === WasteCategory.Waste) {
      ensure(jwnetWasteMaster);
      this.fourthCategoryCode = jwnetWasteMaster.fourthCategoryCode;
    }
  }

  isDirty(arg: IFormValues): boolean {
    const getComparableObject = (formValues: IFormValues) => formValues.getUpdateData();
    return !_.isEqual(getComparableObject(this), getComparableObject(arg));
  }

  clone(): IFormValues {
    ensure(this.id);
    ensure(this.category);
    return new UpdateFormValues(
      this.id,
      this.category,
      this.jwnetWasteMaster,
      this.status,
      this.isProminent,
      this.name
    );
  }
}
