/**
 * useStorage
 * https://vueuse.org/core/useStorage/
 */
import { useLocalStorage } from '@vueuse/core';
import { formatISO } from 'date-fns';
import { IScheduleSolutionStatus } from '~/framework/server-api/user-setting/userSetting';
import { DayOfWeek } from '~/framework/typeAliases';

export const ScheduleTabType = {
  Schedule: 0,
  Orders: 1,
  Errors: 2,
} as const;

type ObjectValueList<T extends Record<any, any>> = T[keyof T];
type ScheduleTabTypeType = ObjectValueList<typeof ScheduleTabType>;

export type UserViewSetting = {
  viewScheduleDetails: boolean;
  scheduleViewMode: 'day' | 'week';
};
export type UserSetting = {
  scheduleDate: string;
  scheduleStartOfWeek: DayOfWeek;
  scheduleTimelineStart: number;
  scheduleTimelineEnd: number;
  scheduleTab: ScheduleTabTypeType;
  scheduleSolutionStatus: IScheduleSolutionStatus[];
  orderGroupId: number;
  loginRememberMe: boolean;
};

export function useRinLocalStorage() {
  // ローカルストレージに新しい値を保存するときはここに追加する
  const userViewSetting = useLocalStorage<UserViewSetting>(
    'userViewSetting',
    {
      viewScheduleDetails: false,
      scheduleViewMode: 'day',
    },
    {
      mergeDefaults: true,
    }
  );
  const userSetting = useLocalStorage(
    'UserSetting:1',
    {
      // 検証中に__typenameとidがないデータがローカルに保存され呼び出され続けるパターンがあったので初期データを同一のものにしておく
      __typename: 'UserSetting',
      id: '1',
      scheduleDate: formatISO(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), {
        representation: 'date',
      }),
      scheduleStartOfWeek: 0,
      scheduleTimelineStart: 8 * 60 * 60,
      scheduleTimelineEnd: 18 * 60 * 60,
      scheduleTab: ScheduleTabType.Schedule,
      orderGroupId: undefined,
      scheduleSolutionStatus: [],
      loginRememberMe: false,
    },
    {
      mergeDefaults: true,
    }
  );
  /* まったく違う文脈の設定を保存するときはこんな感じ
  const sampleSetting = useLocalStorage('sampleSetting', {
    hoge: false,
  });
  */

  return { userViewSetting, userSetting };
}

// FIXME: nuxt2.12.2 -> 2.15.2 に upgrade した際に useLocalStorage によって作成された localStorage の object がリアクティブに更新できなくなっている。
// おそらく、 nuxt2系と compositionAPI, vueUse のいずれかの組み合わせと互換性が失われたからと推測される。
// なので、いったん nuxt3 系になるまでは setItem を使う
export function updateValues(key: string, value: UserViewSetting | UserSetting): void {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e: any) {
    // NOTE: 値が入らなくてもクラッシュさせるほどではない。sentry に送ってもよい
    console.error(e);
  }
}
