import { PersistentId } from '~/framework/typeAliases';
import {
  CreateRoutingRegulationInput,
  CreateRoutingRegulationMutation,
  RoutingRegulationGenerationSite,
  UpdateRoutingRegulationInput,
  UpdateRoutingRegulationMutation,
} from '~/graphql/graphQLServerApi';
export const routingRegulation$getByIdsSymbol = Symbol('routingRegulation$getByIds');
export const routingRegulation$getAllSymbol = Symbol('routingRegulation$getAll');
export const routingRegulation$createSymbol = Symbol('routingRegulation$create');
export const routingRegulation$updateSymbol = Symbol('routingRegulation$update');
export const routingRegulation$deleteSymbol = Symbol('routingRegulation$delete');

export type RoutingRegulationApiTypes = {
  [routingRegulation$getByIdsSymbol]: IGetByIdsApi;
  [routingRegulation$getAllSymbol]: IGetAllApi;
  [routingRegulation$createSymbol]: ICreateApi;
  [routingRegulation$updateSymbol]: IUpdateApi;
  [routingRegulation$deleteSymbol]: IDeleteApi;
};

export type ICreateResultTypes = CreateRoutingRegulationMutation['createRoutingRegulation'];
export type IUpdateResultTypes = UpdateRoutingRegulationMutation['updateRoutingRegulation'];

// NOTE: 今後排出場だけでなく、廃棄物種別・会社を追加する可能性があるため、 interface を拡張してある
export type IRoutingRegulationGenerationSiteData = RoutingRegulationGenerationSite;

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IRoutingRegulationGenerationSiteData[]>;
}

export interface IGetAllApi {
  getAll(): Promise<IRoutingRegulationGenerationSiteData[]>;
}

export type ICreateRoutingRegulationGenerationSiteData = CreateRoutingRegulationInput;

export interface ICreateApi {
  create(data: ICreateRoutingRegulationGenerationSiteData): Promise<ICreateResultTypes>;
}

export type IUpdateRoutingRegulationGenerationSiteData = UpdateRoutingRegulationInput;

export interface IUpdateApi {
  update(data: IUpdateRoutingRegulationGenerationSiteData): Promise<IUpdateResultTypes>;
}

export interface IDeleteApi {
  delete(data: PersistentId): Promise<PersistentId>;
}
