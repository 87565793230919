import { IDuplicatedNameError } from '~/framework/server-api/errors';
import {
  CarType,
  CarTypeContainerType,
  CreateCarTypeContainerTypeInput,
  CreateCarTypeInput,
  SimpleCarType,
  UpdateCarTypeInput,
} from '~/graphql/graphQLServerApi';

export const carType$createSymbol = Symbol('carType$createSymbol');

export const carType$getAllSymbol = Symbol('getAll');

export const carType$getByIdsSymbol = Symbol('getByIds');

export const carType$updateSymbol = Symbol('carType$updateSymbol');

export type CarTypeApiTypes = {
  [carType$getAllSymbol]: IGetAllApi;
  [carType$getByIdsSymbol]: IGetByIdsApi;
  [carType$createSymbol]: ICreateApi;
  [carType$updateSymbol]: IUpdateApi;
};

export type ICarTypeContainerTypeData = CarTypeContainerType;

export type ICarTypeContainerTypeCreateData = CreateCarTypeContainerTypeInput;

// entityが継承関係にある場合に、__typenameを削除しないと型エラーになる
// 現状CarTypeのみ継承を行っているため一時対応をするが、
// 継承が増えたタイミングで__typenameの取り扱いを再考する
export type ISimpleCarTypeData = Omit<SimpleCarType, '__typename'>;

export type ICarTypeData = CarType;

export interface ICreateApi {
  create(data: ICreateCarTypeData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = CarType | ICarTypeMutationError;

export type ICreateCarTypeData = CreateCarTypeInput;

export interface IGetAllApi {
  getAll(): Promise<ICarTypeData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<ICarTypeData[]>;
}

export interface ICarTypeMutationError {
  __typename: 'CarTypeMutationError';
  errors: CarTypeMutationErrorTypes[];
}

export type CarTypeMutationErrorTypes = IDuplicatedNameError;

export interface IUpdateApi {
  update(data: IUpdateCarTypeData): Promise<UpdateResultTypes>;
}

export type UpdateResultTypes = CarType | ICarTypeMutationError;

export type IUpdateCarTypeData = UpdateCarTypeInput;
