import {
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
  UserSettingsByIdsQuery,
  UserSettingsByIdsQueryVariables,
} from '@/graphql/graphQLLocalState';
import { UserSettingsByIds } from '@/../graphql/local-state/queries/user-setting.gql';
import { UpdateUserSettings } from '@/../graphql/local-state/mutations/user-setting.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IGetApi, userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import { IUpdateApi, userSetting$updateSymbol } from '~/framework/server-api/user-setting/update';
import { IUserSettingData } from '~/framework/server-api/user-setting/userSetting';
import { setCacheDefaultValue } from '~/graphql/local-state/userSetting';

export class GetApi extends GraphqlApiBase implements IGetApi {
  [userSetting$getSymbol] = undefined;
  private readonly userSettingId: string = '1';

  async get(): Promise<IUserSettingData> {
    const result = await this.query<UserSettingsByIdsQuery, UserSettingsByIdsQueryVariables>({
      query: UserSettingsByIds,
      variables: { ids: [this.userSettingId] },
    });

    // デフォルト値が設定されていない場合はデフォルト値を設定して再チェックする
    if (result.userSettingsByIds.length === 0) {
      console.warn('Default values are not set.');
      setCacheDefaultValue();
      const updatedResult = await this.query<UserSettingsByIdsQuery, UserSettingsByIdsQueryVariables>({
        query: UserSettingsByIds,
        variables: { ids: [this.userSettingId] },
      });
      const [data] = updatedResult.userSettingsByIds;
      return data;
    }

    this.validateArrayConsistency([this.userSettingId], result.userSettingsByIds, `UserSettingsByIds`);
    const [data] = result.userSettingsByIds;
    return data;
  }
}

export class UpdateApi extends GraphqlApiBase implements IUpdateApi {
  [userSetting$updateSymbol] = undefined;
  async update(data: IUserSettingData): Promise<string> {
    // ローカルへの更新は __typename が設定されていないとコケる
    const variables = { userSettings: [{ ...data, __typename: 'UserSetting' }] };
    const result = await this.mutate<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>({
      mutation: UpdateUserSettings,
      variables,
    });
    this.validateArrayConsistency([data], result.updateUserSettings, `UpdateUserSettings`);
    const [id] = result.updateUserSettings;
    return id;
  }
}
