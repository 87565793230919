import { ICarTypeContainerTypeData } from '~/framework/server-api/masters/carType';
import { ContainerTypeEntity } from '../../container-type/containerTypeEntity';

export class CarTypeContainerTypeEntity {
  readonly id: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;
  containerType: ContainerTypeEntity;

  constructor(data: ICarTypeContainerTypeData, containerType: ContainerTypeEntity) {
    this.id = data.id;
    this.emptyCount = data.emptyCount;
    this.emptyCountOccupation = data.emptyCountOccupation;
    this.lowerTierFullCount = data.lowerTierFullCount;
    this.upperTierFullCount = data.upperTierFullCount;
    this.fullCountOccupation = data.fullCountOccupation;
    this.isUpperTierAvailable = data.isUpperTierAvailable;
    this.containerType = containerType;
  }

  update(data: ICarTypeContainerTypeData, containerType: ContainerTypeEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of CarTypeContainerTypeEntity');
    }
    this.emptyCount = data.emptyCount;
    this.emptyCountOccupation = data.emptyCountOccupation;
    this.lowerTierFullCount = data.lowerTierFullCount;
    this.upperTierFullCount = data.upperTierFullCount;
    this.fullCountOccupation = data.fullCountOccupation;
    this.isUpperTierAvailable = data.isUpperTierAvailable;
    this.containerType = containerType;
  }
}
