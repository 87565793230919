import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { WasteTypeStore } from '~/framework/domain/masters/waste-type/wasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { JwnetWasteMasterMapper } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterMapper';
import { IWasteTypeData } from '~/framework/server-api/masters/wasteType';

export class WasteTypeMapper extends StoredMapperBase<IWasteTypeData, WasteTypeEntity> {
  protected store: WasteTypeStore;
  private jwnetWasteMasterMapper: JwnetWasteMasterMapper;
  private userMapper: UserMapper;

  constructor(store: WasteTypeStore, jwnetWasteMasterStore: JwnetWasteMasterStore, userStore: UserStore) {
    super();
    this.store = store;
    this.jwnetWasteMasterMapper = new JwnetWasteMasterMapper(jwnetWasteMasterStore);
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IWasteTypeData): WasteTypeEntity {
    const jwnetWasteMaster = data.jwnetWasteMaster
      ? this.jwnetWasteMasterMapper.mapSingle(data.jwnetWasteMaster)
      : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new WasteTypeEntity(data, jwnetWasteMaster, createdBy, updatedBy);
  }

  protected updateWithData(data: IWasteTypeData, entity: WasteTypeEntity) {
    const jwnetWasteMaster = data.jwnetWasteMaster
      ? this.jwnetWasteMasterMapper.mapSingle(data.jwnetWasteMaster)
      : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    entity.update(data, jwnetWasteMaster, createdBy, updatedBy);
  }
}
