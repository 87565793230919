import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { SoftDeleteStatus, PreloadStatus, MarginType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';
import { DriverEntity } from '../driver/driverEntity';
import { DisposalSiteEntity } from '../disposal-site/disposalSiteEntity';
import { AbstractSiteEntity } from '../abstractSiteEntity';

export class GenerationSiteEntity extends AbstractSiteEntity {
  code: Maybe<string>;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  note: Maybe<string>;
  attachments: UploadedFile[];
  isAddressComplete: boolean;
  status: SoftDeleteStatus;

  defaultCollectablePeriodTemplateId: Maybe<string>;
  defaultCollectablePeriodStart: Maybe<number>;
  defaultCollectablePeriodEnd: Maybe<number>;

  defaultRouteCollectionAllowed: Maybe<boolean>;
  defaultPreloadStatus: Maybe<PreloadStatus>;

  defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: Maybe<string>;

  client: ClientEntity;
  bannedDrivers: DriverEntity[];
  defaultAssignedDriver: Maybe<DriverEntity>;
  defaultAssignedDisposalSite: Maybe<DisposalSiteEntity>;
  defaultAssignableCars: CarEntity[];
  defaultAssignableCarTypes: SimpleCarTypeEntity[];
  defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;

  get hasRestPeriod(): boolean {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    return this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }

  get isDeleted(): boolean {
    return this.status === SoftDeleteStatus.Deleted;
  }

  get displayName(): string {
    return `${this.name} [${this.client.name}] `;
  }

  get addressWithoutZipCode() {
    const addressWithoutZipCode = [];
    if (this.address1 !== '') {
      addressWithoutZipCode.push(this.address1);
    }
    if (this.address2 !== '') {
      addressWithoutZipCode.push(this.address2);
    }
    if (this.address3 !== '') {
      addressWithoutZipCode.push(this.address3);
    }
    if (this.address4 !== undefined && this.address4 !== '') {
      addressWithoutZipCode.push(this.address4);
    }
    return addressWithoutZipCode.join(' ');
  }

  constructor(
    data: IGenerationSiteData,
    client: ClientEntity,
    bannedDrivers: DriverEntity[],
    defaultAssignedDriver: Maybe<DriverEntity>,
    defaultAssignedDisposalSite: Maybe<DisposalSiteEntity>,
    defaultAssignableCars: CarEntity[],
    defaultAssignableCarTypes: SimpleCarTypeEntity[],
    createdBy: UserEntity,
    defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>
  ) {
    super(data, createdBy);
    this.code = data.code;
    this.defaultAvoidHighways = data.defaultAvoidHighways;
    this.defaultDurationAtEntrance = data.defaultDurationAtEntrance;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.note = data.note;
    this.attachments = data.attachments ?? [];
    this.isAddressComplete = data.isAddressComplete;
    this.status = data.status;
    this.defaultCollectablePeriodStart = data.defaultCollectablePeriodStart;
    this.defaultCollectablePeriodEnd = data.defaultCollectablePeriodEnd;
    this.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    this.defaultPreloadStatus = data.defaultPreloadStatus;
    this.noteForOffice = data.noteForOffice;
    this.defaultIsFixedArrivalTimeReportNeeded = data.defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = data.defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = data.defaultMarginOfFixedArrivalTime;
    this.client = client;
    this.bannedDrivers = bannedDrivers;
    this.defaultAssignedDriver = defaultAssignedDriver;
    this.defaultAssignedDisposalSite = defaultAssignedDisposalSite;
    this.defaultAssignableCars = defaultAssignableCars;
    this.defaultAssignableCarTypes = defaultAssignableCarTypes;
    this.defaultCollectablePeriodTemplate = defaultCollectablePeriodTemplate;
  }

  update(
    data: IGenerationSiteData,
    client: ClientEntity,
    bannedDrivers: DriverEntity[],
    defaultAssignedDriver: Maybe<DriverEntity>,
    defaultAssignedDisposalSite: Maybe<DisposalSiteEntity>,
    defaultAssignableCars: CarEntity[],
    defaultAssignableCarTypes: SimpleCarTypeEntity[],
    createdBy: UserEntity,
    defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of GenerationSiteEntity');
    }
    this.code = data.code;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.zipCode = data.zipCode;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.address3 = data.address3;
    this.address4 = data.address4;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.defaultAvoidHighways = data.defaultAvoidHighways;
    this.defaultDurationAtEntrance = data.defaultDurationAtEntrance;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.note = data.note;
    this.attachments = data.attachments ?? [];
    this.isAddressComplete = data.isAddressComplete;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.defaultCollectablePeriodStart = data.defaultCollectablePeriodStart;
    this.defaultCollectablePeriodEnd = data.defaultCollectablePeriodEnd;
    this.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    this.defaultPreloadStatus = data.defaultPreloadStatus;
    this.noteForOffice = data.noteForOffice;
    this.defaultIsFixedArrivalTimeReportNeeded = data.defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = data.defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = data.defaultMarginOfFixedArrivalTime;
    this.client = client;
    this.bannedDrivers = bannedDrivers;
    this.defaultAssignedDriver = defaultAssignedDriver;
    this.defaultAssignedDisposalSite = defaultAssignedDisposalSite;
    this.defaultAssignableCars = defaultAssignableCars;
    this.defaultAssignableCarTypes = defaultAssignableCarTypes;
    this.createdBy = createdBy;
    this.defaultCollectablePeriodTemplate = defaultCollectablePeriodTemplate;
  }
}
