import { StoredMapperBase } from '~/framework/core/mapper';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { DriverAttendanceStore } from '~/framework/domain/masters/driver-attendance/driverAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { DriverMapper } from '~/framework/domain/masters/driver/driverMapper';
import { DriverStore } from '~/framework/domain/masters/driver/driverStore';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { CarMapper } from '~/framework/domain/masters/car/carMapper';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IDriverAttendanceData } from '~/framework/server-api/masters/driverAttendance';

export class DriverAttendanceMapper extends StoredMapperBase<IDriverAttendanceData, DriverAttendanceEntity> {
  protected store: DriverAttendanceStore;
  private attendanceMapper: AttendanceMapper;
  private driverMapper: DriverMapper;
  private carMapper: CarMapper;

  constructor(
    store: DriverAttendanceStore,
    attendanceStore: AttendanceStore,
    driverStore: DriverStore,
    carStore: CarStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    baseSiteStore: BaseSiteStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
    this.driverMapper = new DriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.carMapper = new CarMapper(carStore, baseSiteStore, userStore);
  }

  protected instantiateWithData(data: IDriverAttendanceData): DriverAttendanceEntity {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    return new DriverAttendanceEntity(data, attendance, driver, primaryCar);
  }

  protected updateWithData(data: IDriverAttendanceData, entity: DriverAttendanceEntity): void {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    entity.update(data, attendance, driver, primaryCar);
  }
}
