import { StoredMapperBase } from '~/framework/core/mapper';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { ContainerTypeStore } from '~/framework/domain/masters/container-type/containerTypeStore';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { PackingStyleMapper } from '~/framework/domain/masters/packing-style/packingStyleMapper';
import { IContainerTypeData } from '~/framework/server-api/masters/containerType';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';

export class ContainerTypeMapper extends StoredMapperBase<IContainerTypeData, ContainerTypeEntity> {
  protected store: ContainerTypeStore;
  private packingStyleMapper: PackingStyleMapper;

  constructor(store: ContainerTypeStore, packingStyleStore: PackingStyleStore) {
    super();
    this.store = store;
    this.packingStyleMapper = new PackingStyleMapper(packingStyleStore);
  }

  protected instantiateWithData(data: IContainerTypeData): ContainerTypeEntity {
    const transformingCarType = data.transformingCarType
      ? new SimpleCarTypeEntity(data.transformingCarType)
      : undefined;
    const packingStyle = this.packingStyleMapper.mapSingle(data.packingStyle);
    return new ContainerTypeEntity(data, transformingCarType, packingStyle);
  }

  protected updateWithData(data: IContainerTypeData, entity: ContainerTypeEntity): void {
    const transformingCarType = data.transformingCarType
      ? new SimpleCarTypeEntity(data.transformingCarType)
      : undefined;
    const packingStyle = this.packingStyleMapper.mapSingle(data.packingStyle);
    entity.update(data, transformingCarType, packingStyle);
  }
}
