import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { SimpleCarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { ICarData } from '~/framework/server-api/masters/car';
import { Maybe } from '~/framework/typeAliases';

export class CarEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  enabledOn: Date;
  carType: SimpleCarTypeEntity;
  baseSite: BaseSiteEntity;
  code: Maybe<string>;

  get displayName(): string {
    return `${this.name} [${this.carType.name}]`;
  }

  constructor(data: ICarData, carType: SimpleCarTypeEntity, baseSite: BaseSiteEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.enabledOn = data.enabledOn;
    this.carType = carType;
    this.baseSite = baseSite;
    this.code = data.code;
  }

  update(data: ICarData, carType: SimpleCarTypeEntity, baseSite: BaseSiteEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of CarEntity');
    }
    this.name = data.name;
    this.enabledOn = data.enabledOn;
    this.carType = carType;
    this.baseSite = baseSite;
    this.code = data.code;
  }
}
