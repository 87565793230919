import { StoredMapperBase } from '~/framework/core/mapper';
import { PackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/packingPlacementEntity';
import { PackingPlacementStore } from '~/framework/domain/packing-placement/packing-placement/packingPlacementStore';
import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { ContainerTypeMapper } from '../../masters/container-type/containerTypeMapper';
import { ContainerTypeStore } from '../../masters/container-type/containerTypeStore';
import { PackingStyleStore } from '../../masters/packing-style/packingStyleStore';

export class PackingPlacementMapper extends StoredMapperBase<IPackingPlacementData, PackingPlacementEntity> {
  protected store: PackingPlacementStore;
  private containerTypeMapper: ContainerTypeMapper;

  constructor(
    store: PackingPlacementStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new ContainerTypeMapper(containerTypeStore, packingStyleStore);
  }

  protected instantiateWithData(data: IPackingPlacementData): PackingPlacementEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    return new PackingPlacementEntity(data, containerType);
  }

  protected updateWithData(data: IPackingPlacementData, entity: PackingPlacementEntity) {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    entity.update(data, containerType);
  }
}
