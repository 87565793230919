import { StoredMapperBase } from '~/framework/core/mapper';
import { CarTypeContainerTypeEntity } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeEntity';
import { CarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeStore';
import { ICarTypeContainerTypeData } from '~/framework/server-api/masters/carType';
import { ContainerTypeStore } from '../../container-type/containerTypeStore';
import { ContainerTypeMapper } from '../../container-type/containerTypeMapper';
import { PackingStyleStore } from '../../packing-style/packingStyleStore';

export class CarTypeContainerTypeMapper extends StoredMapperBase<
  ICarTypeContainerTypeData,
  CarTypeContainerTypeEntity
> {
  protected store: CarTypeContainerTypeStore;
  private containerTypeMapper: ContainerTypeMapper;

  constructor(
    store: CarTypeContainerTypeStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new ContainerTypeMapper(containerTypeStore, packingStyleStore);
  }

  protected instantiateWithData(data: ICarTypeContainerTypeData): CarTypeContainerTypeEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    return new CarTypeContainerTypeEntity(data, containerType);
  }

  protected updateWithData(data: ICarTypeContainerTypeData, entity: CarTypeContainerTypeEntity): void {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    entity.update(data, containerType);
  }
}
