import { IDuplicatedCodeError } from '~/framework/server-api/errors';

import { CreateWasteTypeInput, UpdateWasteTypeInput, WasteType } from '~/graphql/graphQLServerApi';

export const wasteType$createSymbol = Symbol('wasteType$createSymbol');
export const wasteType$updateSymbol = Symbol('wasteType$updateSymbol');
export const wasteType$getAllSymbol = Symbol('getAll');
export const wasteType$getAllDisplayedSymbol = Symbol('getAllDisplayed');
export const wasteType$getAvailableCodeSymbol = Symbol('wasteType$getAvailableCode');
export const wasteType$getByKeywordsSymbol = Symbol('wasteType$getByKeywords');

export type WasteTypeApiTypes = {
  [wasteType$getAllSymbol]: IGetAllApi;
  [wasteType$getAllDisplayedSymbol]: IGetAllDisplayedApi;
  [wasteType$getByKeywordsSymbol]: IGetByKeywordsApi;
  [wasteType$getAvailableCodeSymbol]: IGetAvailableCodeApi;
  [wasteType$createSymbol]: ICreateApi;
  [wasteType$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: ICreateWasteTypeData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = WasteType | IWasteTypeCreateError;

export type ICreateWasteTypeData = CreateWasteTypeInput;

export interface IWasteTypeCreateError {
  __typename: 'WasteTypeCreateError';
  errors: WasteTypeCreateErrorTypes[];
}

export type WasteTypeCreateErrorTypes = IDuplicatedCodeError;

export interface IGetAllApi {
  getAll(): Promise<IWasteTypeData[]>;
}

export interface IGetAllDisplayedApi {
  getAllDisplayed(): Promise<IWasteTypeData[]>;
}

export interface IGetAvailableCodeApi {
  getAvailableCode(code: string): Promise<string>;
}

export interface IGetByKeywordsApi {
  getByKeywords(keywords: string[]): Promise<IWasteTypeData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateWasteTypeData): Promise<IWasteTypeData>;
}

export type IUpdateWasteTypeData = UpdateWasteTypeInput;

export type IWasteTypeData = WasteType;
