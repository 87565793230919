import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { ContainerTypeEntity } from '../../masters/container-type/containerTypeEntity';

export class PackingPlacementEntity {
  readonly id: string;
  readonly persistentId: string;
  num: number;
  lastAllocatedAt: Date;
  containerType: ContainerTypeEntity;

  constructor(data: IPackingPlacementData, containerType: ContainerTypeEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.num = data.num;
    this.lastAllocatedAt = data.lastAllocatedAt;
    this.containerType = containerType;
  }

  update(data: IPackingPlacementData, containerType: ContainerTypeEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of PackingPlacementEntity');
    }
    this.num = data.num;
    this.lastAllocatedAt = data.lastAllocatedAt;
    this.containerType = containerType;
  }
}
