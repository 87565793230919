
import Vue from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { IllegalStateException } from '~/framework/core/exception';
import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';
import { dateToYymmdd, formatDateForField } from '~/framework/services/date/date';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { fixLength, float, required, maxLength } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { IWasteCategory, wasteCategories } from '~/framework/view-models/wasteCategories';
import { IWasteTypeStatusOption, wasteTypeStatusOptions } from '~/framework/view-models/wasteTypeStatusOption';
import {
  WasteTypeApplicationService,
  wasteTypeSymbol,
} from '~/framework/application/masters/waste-type/wasteTypeApplicationService';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { IWasteTypeCreateError } from '~/framework/server-api/masters/wasteType';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import RWasteTypeIsProminentOption from '~/components/panels/masters/r-waste-type-form/RWasteTypeIsProminentOption.vue';
import RWasteTypeFormWasteFields from '~/components/panels/masters/r-waste-type-form/RWasteTypeFormWasteFields.vue';
import { FormErrors, IFormErrors } from '~/components/panels/masters/r-waste-type-form/errors';
import {
  CreateFormValues,
  IFormValues,
  UpdateFormValues,
} from '~/components/panels/masters/r-waste-type-form/formValues';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

enum FormMode {
  Register,
  Edit,
}

type Masters = {
  wasteCategories: IWasteCategory[];
  wasteTypeStatusOptions: IWasteTypeStatusOption[];
  jwnetWasteMasters: Maybe<JwnetWasteMasterEntity[]>;
};

type DataType = {
  wasteTypeApplicationService: WasteTypeApplicationService;
  isActive: boolean;
  FormMode: typeof FormMode;
  formMode: FormMode;
  WasteCategory: typeof WasteCategory;
  masters: Masters;
  editingWasteType: Maybe<WasteTypeEntity>;
  initialFormValues: IFormValues;
  formValues: IFormValues;
  formErrors: IFormErrors;
  isFormValid: boolean;
  isCloseConfirmDialogActive: boolean;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  createdWasteType: Maybe<WasteTypeEntity>;
  updatedWasteType: Maybe<WasteTypeEntity>;
  isRegistering: boolean;
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RWasteTypeForm',
  components: {
    RWasteTypeIsProminentOption,
    RWasteTypeFormWasteFields,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const wasteTypeApplicationService = this.$context.applications.get(wasteTypeSymbol);
    return {
      wasteTypeApplicationService,
      isActive: false,
      FormMode,
      formMode: FormMode.Register,
      WasteCategory,
      masters: {
        wasteCategories,
        wasteTypeStatusOptions,
        jwnetWasteMasters: undefined,
      },
      editingWasteType: undefined,
      formValues: CreateFormValues.buildEmpty(),
      initialFormValues: CreateFormValues.buildEmpty(),
      formErrors: new FormErrors(),
      isFormValid: false,
      isCloseConfirmDialogActive: false,
      closeConfirmDialogResolver: undefined,
      createdWasteType: undefined,
      updatedWasteType: undefined,
      isRegistering: false,
      listenerDisposer: undefined,
    };
  },
  computed: {
    /**
     * JWNETコードマスタで、使用可能な分類コードかを返す
     *
     * 廃棄物カテゴリが `産業廃棄物` 場合のみ使用する。
     *
     * プルダウンで選択されている、`大分類`、`中分類`、`小分類`の組み合わせが、JWNETコードマスタ上使用可能かを判定する。
     *
     * @returns JWNETコードマスタ上で使用可能な分類コードか
     */
    isUsableWaste(): Maybe<boolean> {
      if (this.formValues.category !== WasteCategory.Waste) return undefined;
      return this.formValues.jwnetWasteMaster?.isUsable;
    },
    isRegisterButtonDisabled(): boolean {
      if (this.formValues.category === WasteCategory.Waste) {
        return !(this.isFormValid && this.isUsableWaste);
      }
      return !this.isFormValid;
    },
    isStatusDisplayed(): boolean {
      return this.formValues.status === WasteTypeStatus.Displayed;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.WASTE_TYPE;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.wasteTypeFormPanel.openFormEvent.on(this.onOpen);
    this.$context.panels.wasteTypeFormPanel.registerCloseHandler(this.onCloseForm);
    const createPresenterDisposer = this.wasteTypeApplicationService.addCreatePresenter({
      create: (wasteType: WasteTypeEntity) => this.onCreate(wasteType),
      errorOnCreate: (error: IWasteTypeCreateError) => this.onCreateError(error),
    });
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
      createPresenterDisposer.dispose();
    };
  },
  beforeDestroy() {
    this.listenerDisposer?.();
  },
  methods: {
    formatDateForField,
    dateToYymmdd,
    required,
    float,
    fixLength,
    maxLength,
    async onOpen(args: IOpenEntityFormArgs<WasteTypeEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async open(wasteType: Maybe<WasteTypeEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      this.formMode = wasteType === undefined ? FormMode.Register : FormMode.Edit;
      this.initializeForm(wasteType, this.formMode);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    initializeForm(wasteType: Maybe<WasteTypeEntity>, formMode: FormMode): void {
      this.editingWasteType = undefined;
      this.masters.jwnetWasteMasters = undefined;
      this.createdWasteType = undefined;
      this.updatedWasteType = undefined;
      // 編集
      if (formMode === FormMode.Edit) {
        this.editingWasteType = wasteType;
        this.formValues = new UpdateFormValues(
          wasteType!.id,
          wasteType!.category,
          wasteType!.jwnetWasteMaster,
          wasteType!.status,
          wasteType!.isProminent,
          wasteType!.name
        );
      } else {
        // 新規登録
        this.editingWasteType = undefined;
        this.formValues = CreateFormValues.buildEmpty();
      }
      this.initialFormValues = this.formValues.clone();
      if (this.formValues.category === WasteCategory.Waste) {
        this.getJwnetWasteMaster();
      }
    },
    onChangeCategory(): void {
      if (this.formValues.category === WasteCategory.Waste) {
        this.getJwnetWasteMaster();
      }
    },
    async onUpdateJwnetWasteMaster(value: Maybe<JwnetWasteMasterEntity>): Promise<void> {
      if (this.formMode !== FormMode.Register) return;
      if (value !== undefined && value.isUsable) {
        const baseCode = `${value.firstCategoryCode}${value.secondCategoryCode}${value.thirdCategoryCode}`;

        const availableCode = await this.wasteTypeApplicationService.getAvailableWasteTypeCode(baseCode);
        this.formValues.fourthCategoryCode = availableCode;
      } else {
        this.formValues.fourthCategoryCode = '';
      }
    },
    async getJwnetWasteMaster(): Promise<void> {
      if (this.masters.jwnetWasteMasters !== undefined) return;
      this.masters.jwnetWasteMasters = await this.wasteTypeApplicationService.getAllJwnetWasteMasters();
    },
    resetFormError() {
      this.formErrors.resetAllErrors();
    },
    async onRegisterButtonClicked(): Promise<void> {
      const formValues = this.formValues;
      this.isRegistering = true;
      this.formErrors.resetAllErrors();
      if (this.formMode === FormMode.Register) {
        this.wasteTypeApplicationService.create(formValues.getCreateData());
      } else if (this.formMode === FormMode.Edit) {
        this.updatedWasteType = await this.wasteTypeApplicationService.update(formValues.getUpdateData());

        this.$context.snackbar.success('廃棄物種別の編集完了');
        this.$nextTick(this.close);
      }
      this.isRegistering = false;
      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.WASTE_TYPE,
        [AdditionalInfoKeys.MODE]: this.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onCreate(wasteType: WasteTypeEntity): void {
      this.createdWasteType = wasteType;
      this.$context.snackbar.success('廃棄物種別の登録完了');
      this.$nextTick(this.close);
    },
    onCreateError(error: IWasteTypeCreateError): void {
      for (const anError of error.errors) {
        if (anError.__typename === 'DuplicatedCodeError') {
          this.formErrors.addError('code', '既に使用されているコードです。');
        }
      }
    },
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: ICloseEntityFormArgs<WasteTypeEntity> = {
        entity: this.editingWasteType,
        createdEntity: this.createdWasteType,
        updatedEntity: this.updatedWasteType,
        removedEntity: undefined,
      };
      this.$context.panels.wasteTypeFormPanel.closeFormEvent.emit(closeArgs);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible`);
      if (this.initialFormValues === undefined) throw new IllegalStateException(`Impossible`);
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.formValues.isDirty(this.initialFormValues)) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.carFormPanel.updateDisplayedWidth(value);
    },
  },
});
