import { MarginType } from '@/graphql/graphQLServerApi';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DriverAssignmentType } from '~/framework/domain/typeAliases';
import { CandidateDate } from '~/framework/domain/reservation/reservation/candidateDate';
import { ReservationGenerationSiteTaskEntity } from '~/framework/domain/reservation/reservation/reservation-generation-site-task/reservationGenerationSiteTaskEntity';
import { IAggregatedOrderAssignedDisposalSitesAndType } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { IReservationData } from '~/framework/server-api/reservation/reservation';
import { GenerationSiteEntity } from '../../masters/generation-site/generationSiteEntity';
import { mapEntity } from '~/framework/core/mapper';
import { SimpleCarTypeEntity } from '../../masters/car-type/carTypeEntity';
import { IAggregatedOrderAssignableDriver } from '../../schedule/order/driver/orderAssignableDriver';

export class ReservationEntity {
  readonly id: PersistentId;
  readonly persistentId: PersistentId;
  candidateDates: CandidateDate[];
  note: Maybe<string>;
  sourceOrderNote: Maybe<string>;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IAggregatedOrderAssignableDriver[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  avoidHighways: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  isCancelled: boolean;

  client: ClientEntity;
  orderGroup: OrderGroupEntity;
  generationSite: GenerationSiteEntity;
  assignedDisposalSites: DisposalSiteEntity[];
  assignableCars: CarEntity[];
  assignableCarTypes: SimpleCarTypeEntity[];
  generationSiteTasks: ReservationGenerationSiteTaskEntity[];

  constructor(
    data: IReservationData,
    client: ClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: GenerationSiteEntity,
    assignedDisposalSites: DisposalSiteEntity[],
    assignableDrivers: IAggregatedOrderAssignableDriver[],
    assignableCars: CarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    generationSiteTasks: ReservationGenerationSiteTaskEntity[],
    isCancelled: boolean
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.candidateDates = data.candidateDates;
    this.note = data.note;
    this.sourceOrderNote = data.sourceOrderNote;
    this.durationAtGenerationSite = data.durationAtGenerationSite;
    this.routeCollectionAllowed = data.routeCollectionAllowed;

    const disposalSiteMap = mapEntity(assignedDisposalSites);
    const orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType = {
      disposalSiteAssignmentType: data.assignedDisposalSitesAndType.disposalSiteAssignmentType,
      orderDisposalSites: data.assignedDisposalSitesAndType.orderDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSite = disposalSiteMap.getOrError(orderAssignedDisposalSite.disposalSite.id);
        return {
          disposalSite,
          durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance || disposalSite.durationAtEntrance,
          priority: orderAssignedDisposalSite.priority,
          disposablePeriodStart: orderAssignedDisposalSite.disposablePeriodStart,
          disposablePeriodEnd: orderAssignedDisposalSite.disposablePeriodEnd,
        };
      }),
    };
    this.orderAssignedDisposalSitesAndType = orderAssignedDisposalSitesAndType;

    this.driverAssignmentType = data.driverAssignmentType;
    this.assignableDrivers = assignableDrivers;
    this.carNum = data.carNum;
    this.minAssignedDriverNum = data.minAssignedDriverNum;
    this.maxAssignedDriverNum = data.maxAssignedDriverNum;
    this.avoidHighways = data.avoidHighways;
    this.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    this.isCancelled = isCancelled;
    this.client = client;
    this.orderGroup = orderGroup;
    this.generationSite = generationSite;
    this.assignedDisposalSites = assignedDisposalSites;
    this.assignableCars = assignableCars;
    this.assignableCarTypes = assignableCarTypes;
    this.generationSiteTasks = generationSiteTasks;
  }

  update(
    data: IReservationData,
    client: ClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: GenerationSiteEntity,
    assignedDisposalSites: DisposalSiteEntity[],
    assignableDrivers: IAggregatedOrderAssignableDriver[],
    assignableCars: CarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    generationSiteTasks: ReservationGenerationSiteTaskEntity[],
    isCancelled: boolean
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of ReservationEntity');
    }
    this.candidateDates = data.candidateDates;
    this.note = data.note;
    this.sourceOrderNote = data.sourceOrderNote;
    this.durationAtGenerationSite = data.durationAtGenerationSite;
    this.routeCollectionAllowed = data.routeCollectionAllowed;

    const disposalSiteMap = mapEntity(assignedDisposalSites);
    const orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType = {
      disposalSiteAssignmentType: data.assignedDisposalSitesAndType.disposalSiteAssignmentType,
      orderDisposalSites: data.assignedDisposalSitesAndType.orderDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSite = disposalSiteMap.getOrError(orderAssignedDisposalSite.disposalSite.id);
        return {
          disposalSite,
          durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance || disposalSite.durationAtEntrance,
          priority: orderAssignedDisposalSite.priority,
          disposablePeriodStart: orderAssignedDisposalSite.disposablePeriodStart,
          disposablePeriodEnd: orderAssignedDisposalSite.disposablePeriodEnd,
        };
      }),
    };
    this.orderAssignedDisposalSitesAndType = orderAssignedDisposalSitesAndType;

    this.driverAssignmentType = data.driverAssignmentType;
    this.assignableDrivers = assignableDrivers;
    this.carNum = data.carNum;
    this.minAssignedDriverNum = data.minAssignedDriverNum;
    this.maxAssignedDriverNum = data.maxAssignedDriverNum;
    this.avoidHighways = data.avoidHighways;
    this.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    this.isCancelled = isCancelled;
    this.client = client;
    this.orderGroup = orderGroup;
    this.generationSite = generationSite;
    this.assignedDisposalSites = assignedDisposalSites;
    this.assignableCars = assignableCars;
    this.assignableCarTypes = assignableCarTypes;
    this.generationSiteTasks = generationSiteTasks;
  }
}
