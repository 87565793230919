import { EmploymentStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';

import employmentStatusMap from '~/assets/settings/employmentStatus.json';
import { IDriverData } from '~/framework/server-api/masters/driver';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';

export class DriverEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  nameRuby: string;
  employmentStatus: EmploymentStatus;
  defaultAttendanceTemplateId: Maybe<string>;
  status: SoftDeleteStatus;
  signInUrl: string;
  code: Maybe<string>;
  defaultPrimaryCar: Maybe<CarEntity>;
  licensedCarTypes: SimpleCarTypeEntity[];
  defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;

  get employmentStatusName(): string {
    return employmentStatusMap[this.employmentStatus];
  }

  constructor(
    data: IDriverData,
    defaultPrimaryCar: Maybe<CarEntity>,
    licensedCarTypes: SimpleCarTypeEntity[],
    defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.employmentStatus = data.employmentStatus;
    this.status = data.status;
    this.signInUrl = data.signInUrl;
    this.code = data.code;
    this.defaultPrimaryCar = defaultPrimaryCar;
    this.licensedCarTypes = licensedCarTypes;
    this.defaultAttendanceTemplate = defaultAttendanceTemplate;
  }

  update(
    data: IDriverData,
    defaultPrimaryCar: Maybe<CarEntity>,
    licensedCarTypes: SimpleCarTypeEntity[],
    defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of DriverEntity');
    }
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.employmentStatus = data.employmentStatus;
    this.status = data.status;
    this.signInUrl = data.signInUrl;
    this.defaultPrimaryCar = defaultPrimaryCar;
    this.licensedCarTypes = licensedCarTypes;
    this.defaultAttendanceTemplate = defaultAttendanceTemplate;
  }
}
