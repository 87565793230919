import { UserEntity } from '../user/userEntity';
import { MapperBase } from '~/framework/core/mapper';
import { RoutingRegulationEntity } from '~/framework/domain/masters/routing-regulation/routingRegulationEntity';
import { IRoutingRegulationGenerationSiteData } from '~/framework/server-api/masters/routingRegulation';
import { GenerationSiteMapper } from '../generation-site/generationSiteMapper';
import { GenerationSiteStore } from '../generation-site/generationSiteStore';
import { ClientStore } from '../client/clientStore';
import { UserStore } from '../user/userStore';
import { DisposalSiteStore } from '../disposal-site/disposalSiteStore';
import { CollectablePeriodTemplateStore } from '../collectable-period-template/collectablePeriodTemplateStore';
import { CarStore } from '../car/carStore';
import { BaseSiteStore } from '../base-site/baseSiteStore';
import { DriverStore } from '../driver/driverStore';
import { DriverAttendanceTemplateStore } from '../driver-attendance-template/driverAttendanceTemplateStore';

export class RoutingRegulationMapper extends MapperBase<IRoutingRegulationGenerationSiteData, RoutingRegulationEntity> {
  private generationSiteMapper: GenerationSiteMapper;

  constructor(
    generationSiteStore: GenerationSiteStore,
    clientStore: ClientStore,
    userStore: UserStore,
    disposalSiteStore: DisposalSiteStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverStore: DriverStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore
  ) {
    super();
    this.generationSiteMapper = new GenerationSiteMapper(
      generationSiteStore,
      clientStore,
      userStore,
      disposalSiteStore,
      collectablePeriodTemplateStore,
      carStore,
      baseSiteStore,
      driverStore,
      driverAttendanceTemplateStore
    );
  }

  protected instantiateWithData(data: IRoutingRegulationGenerationSiteData): RoutingRegulationEntity {
    const generationSites = this.generationSiteMapper.map(data.generationSites);
    const optionalGenerationSites = this.generationSiteMapper.map(data.optionalGenerationSites);
    // 本来はMapperを通すべきだが、簡易実装となっている
    const createdBy = new UserEntity(data.createdBy);
    const updatedBy = new UserEntity(data.updatedBy);

    return new RoutingRegulationEntity(data, generationSites, optionalGenerationSites, createdBy, updatedBy);
  }

  protected updateWithData(data: IRoutingRegulationGenerationSiteData, entity: RoutingRegulationEntity): void {
    const generationSites = this.generationSiteMapper.map(data.generationSites);
    const optionalGenerationSites = this.generationSiteMapper.map(data.optionalGenerationSites);
    // 本来はMapperを通すべきだが、簡易実装となっている
    const createdBy = new UserEntity(data.createdBy);
    const updatedBy = new UserEntity(data.updatedBy);
    entity.update(data, generationSites, optionalGenerationSites, createdBy, updatedBy);
  }
}
