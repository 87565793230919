import { StoredMapperBase } from '~/framework/core/mapper';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { DisposalSiteStore } from '~/framework/domain/masters/disposal-site/disposalSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { IDisposalSiteData } from '~/framework/server-api/masters/disposalSite';
import { ClientMapper } from '../client/clientMapper';
import { ClientStore } from '../client/clientStore';

export class DisposalSiteMapper extends StoredMapperBase<IDisposalSiteData, DisposalSiteEntity> {
  protected store: DisposalSiteStore;
  private clientMapper: ClientMapper;
  private userMapper: UserMapper;

  constructor(store: DisposalSiteStore, clientStore: ClientStore, userStore: UserStore) {
    super();
    this.store = store;
    this.clientMapper = new ClientMapper(clientStore, userStore);
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IDisposalSiteData): DisposalSiteEntity {
    const client = data.client ? this.clientMapper.mapSingle(data.client) : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new DisposalSiteEntity(data, client, createdBy);
  }

  protected updateWithData(data: IDisposalSiteData, entity: DisposalSiteEntity): void {
    const client = data.client ? this.clientMapper.mapSingle(data.client) : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.update(data, client, createdBy);
  }
}
