import { StoredMapperBase } from '~/framework/core/mapper';
import {
  IDisposalSiteAttendanceData,
  DisposalSiteAttendanceEntity,
} from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceEntity';
import { AttendanceMapper } from '../attendance/attendanceMapper';
import { DisposalSiteAttendanceStore } from './disposalSiteAttendanceStore';
import { AttendanceStore } from '../attendance/attendanceStore';
import { DisposalSiteStore } from '../disposal-site/disposalSiteStore';
import { DisposalSiteMapper } from '../disposal-site/disposalSiteMapper';
import { UserStore } from '../user/userStore';
import { ClientStore } from '../client/clientStore';

export class DisposalSiteAttendanceMapper extends StoredMapperBase<
  IDisposalSiteAttendanceData,
  DisposalSiteAttendanceEntity
> {
  protected store: DisposalSiteAttendanceStore;
  private attendanceMapper: AttendanceMapper;
  private disposalSiteMapper: DisposalSiteMapper;

  constructor(
    store: DisposalSiteAttendanceStore,
    attendanceStore: AttendanceStore,
    clientStore: ClientStore,
    disposalSiteStore: DisposalSiteStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
    this.disposalSiteMapper = new DisposalSiteMapper(disposalSiteStore, clientStore, userStore);
  }

  protected instantiateWithData(data: IDisposalSiteAttendanceData): DisposalSiteAttendanceEntity {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);
    const disposalSiteEntity = this.disposalSiteMapper.mapSingle(data.disposalSite);
    return new DisposalSiteAttendanceEntity(data, attendanceEntity, disposalSiteEntity);
  }

  protected updateWithData(data: IDisposalSiteAttendanceData, entity: DisposalSiteAttendanceEntity): void {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);
    const disposalSiteEntity = this.disposalSiteMapper.mapSingle(data.disposalSite);
    entity.update(data, attendanceEntity, disposalSiteEntity);
  }
}
