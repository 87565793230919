import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';

export interface IContainerType {
  readonly entity: ContainerTypeEntity;
  isSamePackingStyle: boolean;
}

export class ContainerType {
  readonly entity: ContainerTypeEntity;
  isSamePackingStyle: boolean;

  constructor(entity: ContainerTypeEntity) {
    this.entity = entity;
    this.isSamePackingStyle = false;
  }
}
