import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe, Seconds } from '~/framework/typeAliases';
import {
  EditAttendanceSolution,
  EditGenerationSiteSolution,
  EditOrderSolution,
  IInfeasibilitySolution,
  InfeasibilitySolutionTypes,
  IPotentialModification,
  RinLinkSolution,
} from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import {
  InfeasibilityCauses,
  InfeasibilityDriverReasons,
  InfeasibilityReasons,
  ScheduleInfeasibilityTypes,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { Path } from '~/framework/constants';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { PostponeOrderStatus } from '~/framework/domain/schedule/order/orderEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { SimpleCarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { OrderPlan, PreloadStatus } from '~/graphql/graphQLServerApi';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';

/**
 * 受注チェックを行った場合は OrderEntity はまだ存在していない可能性があるので、
 * OrderEntity を直接持たず、表示に必要なclient と generationSite の情報を持つ
 */
export class Infeasibility {
  id: string;
  index: number;
  deemedAsSolved: boolean;
  orderId: PseudoId;
  plan: Maybe<OrderPlan>;
  date: Maybe<Date>;
  collectablePeriodStart: Maybe<Seconds>;
  collectablePeriodEnd: Maybe<Seconds>;
  unloadDate: Maybe<Date>;
  client: ClientEntity;
  generationSite: GenerationSiteEntity;
  preloadStatus: PreloadStatus;
  cause: InfeasibilityCauses;
  reasons: Maybe<InfeasibilityReasons[]>;
  driverReasons: Maybe<DriverReason[]>;
  assignedDriver: Maybe<DriverEntity>;
  assignableCars: CarEntity[];
  assignableCarTypes: SimpleCarTypeEntity[];
  acceptableCarTypes: Maybe<SimpleCarTypeEntity[]>;
  possibleSolutions: IInfeasibilitySolution<any>[];
  hasConditions: boolean;
  potentialModifications: Maybe<IPotentialModification[]>;
  type: ScheduleInfeasibilityTypes;
  releaseDriverAssignment: Maybe<boolean>;
  durationAtGenerationSite: Maybe<number>;
  durationAtDisposalSite: Maybe<number>;
  durationOfDriving: Maybe<number>;
  reducibleDurationByHighway: Maybe<number>;
  // postponeOrdersを行ったかどうか
  postponeOrderStatus: PostponeOrderStatus;

  get assignableCarTypeNames(): string[] {
    return this.assignableCarTypes.map((carType) => carType.name);
  }

  get acceptableCarTypeNames(): Maybe<string[]> {
    if (this.acceptableCarTypes === undefined) return undefined;

    return this.acceptableCarTypes.map((carType) => carType.name);
  }

  constructor(
    id: string,
    index: number,
    deemedAsSolved: boolean,
    orderId: PseudoId,
    plan: OrderPlan,
    date: Maybe<Date>,
    collectablePeriodStart: Maybe<Seconds>,
    collectablePeriodEnd: Maybe<Seconds>,
    unloadDate: Maybe<Date>,
    client: ClientEntity,
    generationSite: GenerationSiteEntity,
    preloadStatus: PreloadStatus,
    postponeOrderStatus: PostponeOrderStatus,
    assignableCars: CarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    cause: InfeasibilityCauses,
    reasons: Maybe<InfeasibilityReasons[]>,
    driverReasons: Maybe<DriverReason[]>,
    assignedDriver: Maybe<DriverEntity>,
    acceptableCarTypes: Maybe<SimpleCarTypeEntity[]>,
    potentialModifications: Maybe<IPotentialModification[]>,
    type: ScheduleInfeasibilityTypes,
    releaseDriverAssignment: Maybe<boolean>,
    durationAtGenerationSite: Maybe<number>,
    durationAtDisposalSite: Maybe<number>,
    durationOfDriving: Maybe<number>,
    reducibleDurationByHighway: Maybe<number>
  ) {
    this.id = id;
    this.index = index;
    this.deemedAsSolved = deemedAsSolved;
    this.orderId = orderId;
    this.plan = plan;
    this.date = date;
    this.collectablePeriodEnd = collectablePeriodEnd;
    this.collectablePeriodStart = collectablePeriodStart;
    this.unloadDate = unloadDate;
    this.client = client;
    this.generationSite = generationSite;
    this.preloadStatus = preloadStatus;
    this.postponeOrderStatus = postponeOrderStatus;
    this.cause = cause;
    this.reasons = reasons;
    this.driverReasons = driverReasons;
    this.assignedDriver = assignedDriver;
    this.assignableCars = assignableCars;
    this.acceptableCarTypes = acceptableCarTypes;
    this.assignableCarTypes = assignableCarTypes;
    this.potentialModifications = potentialModifications;
    this.type = type;
    this.releaseDriverAssignment = releaseDriverAssignment;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.durationAtDisposalSite = durationAtDisposalSite;
    this.durationOfDriving = durationOfDriving;
    this.reducibleDurationByHighway = reducibleDurationByHighway;

    this.hasConditions =
      this.assignableCars.length > 0 || this.assignedDriver !== undefined || this.acceptableCarTypes !== undefined;
    this.potentialModifications = potentialModifications;
    this.postponeOrderStatus = PostponeOrderStatus.Default;
    this.possibleSolutions = [];
    this.setPossibleSolutions();
  }

  hasSolution(type: InfeasibilitySolutionTypes): boolean {
    return this.possibleSolutions.some((solution) => solution.type === type);
  }

  private setPossibleSolutions(): void {
    if (this.cause === InfeasibilityCauses.Order) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.OrderOrMaster) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      if (this.reasons?.includes(InfeasibilityReasons.GenerationSiteRestPeriod)) {
        this.addSolution(new EditGenerationSiteSolution());
      } else {
        this.addSolution(new RinLinkSolution(Path.masters.index, `マスターを編集`));
      }
    } else if (this.cause === InfeasibilityCauses.AssignedDriverAttendance) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.AssignedDriverCondition) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.PrimaryCarAttendance) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.PrimaryCarCondition) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.Optimization) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.DisposalSiteRestPeriod) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new RinLinkSolution(Path.masters['disposal-site'], `処分場を編集`));
    }
  }

  private addSolution(solution: IInfeasibilitySolution<any>): void {
    this.possibleSolutions.push(solution);
  }
}

export class DriverReason {
  driver: DriverEntity;
  reasons: InfeasibilityDriverReasons[];

  constructor(driver: DriverEntity, reasons: InfeasibilityDriverReasons[]) {
    this.driver = driver;
    this.reasons = reasons;
  }
}
