import { StoredMapperBase } from '~/framework/core/mapper';
import { ReservationStore } from '~/framework/domain/reservation/reservation/reservationStore';
import { ReservationEntity } from '~/framework/domain/reservation/reservation/reservationEntity';
import { ClientStore } from '~/framework/domain/masters/client/clientStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { DisposalSiteStore } from '~/framework/domain/masters/disposal-site/disposalSiteStore';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { ClientMapper } from '~/framework/domain/masters/client/clientMapper';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { DisposalSiteMapper } from '~/framework/domain/masters/disposal-site/disposalSiteMapper';
import { CarMapper } from '~/framework/domain/masters/car/carMapper';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { WasteTypeStore } from '~/framework/domain/masters/waste-type/wasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { IReservationData } from '~/framework/server-api/reservation/reservation';
import { GenerationSiteMapper } from '../../masters/generation-site/generationSiteMapper';
import { DriverMapper } from '../../masters/driver/driverMapper';
import { PackingStyleStore } from '../../masters/packing-style/packingStyleStore';
import { ContainerTypeStore } from '../../masters/container-type/containerTypeStore';
import { DriverAttendanceTemplateStore } from '../../masters/driver-attendance-template/driverAttendanceTemplateStore';
import { DriverStore } from '../../masters/driver/driverStore';
import { CollectablePeriodTemplateStore } from '../../masters/collectable-period-template/collectablePeriodTemplateStore';
import { GenerationSiteStore } from '../../masters/generation-site/generationSiteStore';
import { SimpleCarTypeEntity } from '../../masters/car-type/carTypeEntity';
import { ReservationGenerationSiteTaskMapper } from './reservation-generation-site-task/reservationGenerationSiteTaskMapper';

export class ReservationMapper extends StoredMapperBase<IReservationData, ReservationEntity> {
  protected store: ReservationStore;
  private clientMapper: ClientMapper;
  private orderGroupMapper: OrderGroupMapper;
  private generationSiteMapper: GenerationSiteMapper;
  private disposalSiteMapper: DisposalSiteMapper;
  private driverMapper: DriverMapper;
  private carMapper: CarMapper;
  private generationSiteTaskMapper: ReservationGenerationSiteTaskMapper;

  constructor(
    store: ReservationStore,
    generationSiteStore: GenerationSiteStore,
    clientStore: ClientStore,
    userStore: UserStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    orderGroupStore: OrderGroupStore,
    disposalSiteStore: DisposalSiteStore,
    wasteTypeStore: WasteTypeStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverStore: DriverStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new ClientMapper(clientStore, userStore);
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.generationSiteMapper = new GenerationSiteMapper(
      generationSiteStore,
      clientStore,
      userStore,
      disposalSiteStore,
      collectablePeriodTemplateStore,
      carStore,
      baseSiteStore,
      driverStore,
      driverAttendanceTemplateStore
    );
    this.disposalSiteMapper = new DisposalSiteMapper(disposalSiteStore, clientStore, userStore);
    this.driverMapper = new DriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.carMapper = new CarMapper(carStore, baseSiteStore, userStore);
    this.generationSiteTaskMapper = new ReservationGenerationSiteTaskMapper(
      wasteTypeStore,
      jwnetWasteMasterStore,
      userStore,
      containerTypeStore,
      packingStyleStore,
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore
    );
  }

  protected instantiateWithData(data: IReservationData): ReservationEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSiteEntity = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = data.assignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => orderDisposalSite.disposalSite
    );
    const assignedDisposalSiteEntities = this.disposalSiteMapper.map(assignedDisposalSites);
    const orderAssignableDrivers = data.assignableDrivers.map((orderAssignableDriver) => {
      return {
        driverType: orderAssignableDriver.driverType,
        driver: this.driverMapper.mapSingle(orderAssignableDriver.driver),
      };
    });
    const assignableCarTypes = data.assignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const assignableCars = this.carMapper.map(data.assignableCars);
    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);

    return new ReservationEntity(
      data,
      client,
      orderGroup,
      generationSiteEntity,
      assignedDisposalSiteEntities,
      orderAssignableDrivers,
      assignableCars,
      assignableCarTypes,
      generationSiteTasks,
      data.isCancelled
    );
  }

  protected updateWithData(data: IReservationData, entity: ReservationEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSiteEntity = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = data.assignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => orderDisposalSite.disposalSite
    );
    const assignedDisposalSiteEntities = this.disposalSiteMapper.map(assignedDisposalSites);
    const orderAssignableDrivers = data.assignableDrivers.map((orderAssignableDriver) => {
      return {
        driverType: orderAssignableDriver.driverType,
        driver: this.driverMapper.mapSingle(orderAssignableDriver.driver),
      };
    });
    const assignableCarTypes = data.assignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const assignableCars = this.carMapper.map(data.assignableCars);
    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);

    entity.update(
      data,
      client,
      orderGroup,
      generationSiteEntity,
      assignedDisposalSiteEntities,
      orderAssignableDrivers,
      assignableCars,
      assignableCarTypes,
      generationSiteTasks,
      data.isCancelled
    );
  }
}
