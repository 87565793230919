
import _ from 'lodash';
import Vue, { PropType } from 'vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { DriverType, DriverAssignmentType, EmploymentStatus } from '~/framework/domain/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { required } from '~/framework/view-models/rules';
import {
  RinEventDialogComponentParam,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { driverSymbol } from '~/framework/application/masters/driver/driverApplicationService';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import {
  getAssignableDriverIdsByDriverType,
  buildAssignableDriversByIds,
} from '~/framework/domain/schedule/order/driver/orderAssignableDriver';
import { CreateOrderAssignableDriverInput } from '~/graphql/graphQLServerApi';

type DataType = {
  driverNum: number;
  driverAssignmentType: DriverAssignmentType;
  isHelperEnabled: boolean;
  selectedDriverIds: string[];
  selectedOperatorIds: string[];
  selectedHelperIds: string[];
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

export default Vue.extend({
  name: 'RDriverAssignmentMultipleDialog',
  components: {
    RDialog,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    initialDriverNum: {
      type: Number,
      required: true,
    },
    carNum: {
      type: Number,
      required: true,
    },
    driverNumItems: {
      type: Array as PropType<number[]>,
      required: true,
    },
    initialDriverAssignmentType: {
      type: String as PropType<DriverAssignmentType>,
      required: false,
      default: DriverAssignmentType.NotDistinguished,
    },
    initialAssignableDrivers: {
      type: Array as PropType<CreateOrderAssignableDriverInput[]>,
      required: false,
      default: () => [],
    },
    driverItems: {
      type: Array as PropType<DriverEntity[]>,
      required: false,
      default: () => [],
    },
    extraValidationRules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data(): DataType {
    return {
      driverNum: this.initialDriverNum,
      driverAssignmentType: this.initialDriverAssignmentType,
      isHelperEnabled: this.initialDriverAssignmentType === DriverAssignmentType.Distinguished,
      selectedDriverIds: getAssignableDriverIdsByDriverType(DriverType.Driver, this.initialAssignableDrivers),
      selectedOperatorIds: getAssignableDriverIdsByDriverType(DriverType.Operator, this.initialAssignableDrivers),
      selectedHelperIds: getAssignableDriverIdsByDriverType(DriverType.Helper, this.initialAssignableDrivers),
      listenerDisposer: undefined,
    };
  },
  computed: {
    selectableOperatorItems(): DriverEntity[] {
      return this.driverItems.filter((driverItem) => !this.selectedHelperIds.includes(driverItem.id));
    },
    selectableHelperItems(): DriverEntity[] {
      return this.driverItems.filter((driverItem) => !this.selectedOperatorIds.includes(driverItem.id));
    },
    isFormValid(): boolean {
      if (!this.isHelperEnabled && this.selectedDriverIds.length > 0) {
        return true;
      }
      if (this.isHelperEnabled && this.selectedHelperIds.length > 0) {
        return true;
      }
      return false;
    },
    isCompleteButtonDisabled(): boolean {
      return !this.isFormValid;
    },
    validationRules(): ValidationRule[] {
      return [required].concat(this.extraValidationRules);
    },
  },
  watch: {
    driverNum(value: number) {
      // NOTE: driverNum === carNum の場合は補助員は存在しないはずなので、指定が消えるようにして普通の乗務員指定に戻す
      if (value === this.carNum && this.isHelperEnabled) {
        this.isHelperEnabled = false;
      }
    },
    isHelperEnabled(value: boolean) {
      // NOTE: 補助指定に切り替わる際に乗務員を運転者に、運転者を乗務員に移す。補助員は空になる
      if (value && this.selectedDriverIds.length !== 0) {
        this.selectedOperatorIds = this.selectedDriverIds;
        this.selectedDriverIds = [];
      } else if (!value && this.selectedOperatorIds.length !== 0) {
        this.selectedDriverIds = this.selectedOperatorIds;
        this.selectedOperatorIds = [];
        this.selectedHelperIds = [];
      } else {
        // NOTE: 運転者が残っている状態で補助指定をオフにしたとき、指定されていた補助員をクリアする
        this.selectedDriverIds = [];
        this.selectedOperatorIds = [];
        this.selectedHelperIds = [];
      }
    },
  },
  mounted() {
    if (this.isDialogActive) this.createKeyboardEventListener();
  },
  methods: {
    getAssignableDriverIdsByDriverType,
    async onClickSetAllFieldWorkersToHelper() {
      this.$rinGtm.push(RinEventNames.SET_ALL_FIELD_WORKERS_TO_HELPER);

      // 補助員指定がない場合は、自動で指定にし最初のドライバーを運転手として設定しておく
      if (!this.isHelperEnabled) {
        const previouslySelectedDriverIds = this.selectedDriverIds;
        this.isHelperEnabled = true;

        // watchの変更を待った後に運転手を設定する
        await Vue.nextTick();
        if (this.selectedDriverIds.length > 0) {
          this.selectedOperatorIds = previouslySelectedDriverIds;
          this.selectedDriverIds = [];
        }
      }

      const driverApplicationService = this.$context.applications.get(driverSymbol);
      const drivers = await driverApplicationService.getAll();
      const fieldWorkers = drivers
        .filter((driver) => driver.employmentStatus === EmploymentStatus.FieldWorker)
        .map((driver) => driver.id);
      const uniqueHelpers = _.uniq([...this.selectedHelperIds, ...fieldWorkers]).filter((driverId) => {
        // すでに設定されているidは除外する
        return !new Set([...this.selectedOperatorIds]).has(driverId);
      });
      this.selectedHelperIds = uniqueHelpers;
    },
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.disposeKeyboardEventListener();
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      const assignableDrivers = buildAssignableDriversByIds(
        DriverType.Driver,
        this.selectedDriverIds,
        this.driverItems
      );
      const assignableOperators = buildAssignableDriversByIds(
        DriverType.Operator,
        this.selectedOperatorIds,
        this.driverItems
      );
      const assignableHelpers = buildAssignableDriversByIds(
        DriverType.Helper,
        this.selectedHelperIds,
        this.driverItems
      );

      // NOTE: 補助員が指定されていないのに完了が押された場合、運転者指定を乗務員指定に戻して反映する
      if (this.isHelperEnabled && assignableHelpers.length === 0) {
        this.$emit(EventTypes.Update, {
          driverNum: this.driverNum,
          isHelperEnabled: false,
          assignableDrivers: buildAssignableDriversByIds(DriverType.Driver, this.selectedOperatorIds, this.driverItems),
        });
        this.onClickClose();
        return;
      }

      this.$emit(EventTypes.Update, {
        driverNum: this.driverNum,
        isHelperEnabled: this.isHelperEnabled,
        assignableDrivers: this.isHelperEnabled ? [...assignableOperators, ...assignableHelpers] : assignableDrivers,
      });
      this.onClickClose();
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
  },
});
