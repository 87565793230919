import { StoredMapperBase } from '~/framework/core/mapper';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { DriverStore } from '~/framework/domain/masters/driver/driverStore';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { CarMapper } from '~/framework/domain/masters/car/carMapper';
import { DriverAttendanceTemplateMapper } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateMapper';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IDriverData } from '~/framework/server-api/masters/driver';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';

export class DriverMapper extends StoredMapperBase<IDriverData, DriverEntity> {
  protected store: DriverStore;
  private carMapper: CarMapper;
  private driverAttendanceTemplateMapper: DriverAttendanceTemplateMapper;

  constructor(
    store: DriverStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.carMapper = new CarMapper(carStore, baseSiteStore, userStore);
    this.driverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(driverAttendanceTemplateStore);
  }

  protected instantiateWithData(data: IDriverData): DriverEntity {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = data.licensedCarTypes.map((carTypeData) => new SimpleCarTypeEntity(carTypeData));
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;

    return new DriverEntity(data, defaultPrimaryCar, licensedCarTypes, defaultAttendanceTemplate);
  }

  protected updateWithData(data: IDriverData, entity: DriverEntity): void {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = data.licensedCarTypes.map((carTypeData) => new SimpleCarTypeEntity(carTypeData));
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;
    entity.update(data, defaultPrimaryCar, licensedCarTypes, defaultAttendanceTemplate);
  }
}
