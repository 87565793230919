import { OrderPlan, OrderCandidateDateInput, OrderPlanInput } from '~/framework/server-api/typeAliases';
import { Maybe } from '~/framework/typeAliases';

/**
 * Rin側で扱うOrderPlanと、API側で扱うOrderPlanInputを変換する
 * Rin側ではplanはfixedとcandidateDatesが両方ある
 * API側ではplanはfixedとcandidateDatesのどちらか一方しかない
 * どちらも空である場合は既存のorderの5項目をfixedに入れる
 * @param {OrderPlan}
 */
export function convertOrderPlanToOrderPlanInput(orderPlan: Maybe<OrderPlan>): OrderPlanInput {
  if (orderPlan?.fixed !== undefined) {
    return {
      candidateDates: [orderPlan.fixed],
    };
  }
  if (orderPlan?.candidateDates !== undefined) {
    return {
      candidateDates: orderPlan.candidateDates,
    };
  }
  throw new Error('orderPlan must have fixed or candidateDates');
}

/**
 * OrderPlanInputからOrderPlanへの逆変換
 * 基本API側から返ってきたものがRin側データになるが、一部送信データをそのまま使う場合(※ScheduleFactory)に使う
 * @param {OrderPlanInput}
 */
export function convertOrderPlanInputToOrderPlan(orderPlanInput: OrderPlanInput): Maybe<OrderPlan> {
  if (isFixedPlan(orderPlanInput)) {
    return {
      fixed: orderPlanInput.candidateDates[0],
      candidateDates: undefined,
    };
  }
  if (isCandidateDatesPlan(orderPlanInput)) {
    return {
      fixed: undefined,
      candidateDates: orderPlanInput.candidateDates,
    };
  }
  return undefined;
}

export const isFixedPlan = (plan: OrderPlanInput) => {
  return plan.candidateDates.length === 1;
};

export const isCandidateDatesPlan = (plan: OrderPlanInput) => {
  return plan.candidateDates.length > 1;
};

/**
 * OrderPlanInputから基準日を取得する
 * @param plan
 */
export function getBasePlanFromOrderPlanInput(plan: OrderPlanInput): OrderCandidateDateInput {
  return plan.candidateDates[0];
}
