import { AbstractEntityFormPanel, ICacheablePanelOption } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { DisposalSiteEntity as IDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { FunctionLike } from '~/framework/typeAliases';

export interface IDisposalSiteFormPanelOption extends IOpenPanelOption, ICacheablePanelOption {
  /**
   * 登録が完了した時に呼ばれて欲しいコールバック
   */
  closeCallback?: FunctionLike<void>;

  /**
   * 登録完了ボタンのラベル
   */
  registerButtonLabel?: string;
}

/**
 * DisposalSite を編集するためのもの
 */
export interface IDisposalSiteFormPanel extends IEntityFormPanel<IDisposalSiteEntity, IDisposalSiteFormPanelOption> {}

export class DisposalSiteFormPanel
  extends AbstractEntityFormPanel<IDisposalSiteEntity, IDisposalSiteFormPanelOption>
  implements IDisposalSiteFormPanel {}
