import { DisposalSiteAttendanceEntity } from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceEntity';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { disposalSiteAttendanceSymbol } from '~/framework/port.adapter/server-api/masters/disposalSiteAttendance';
import { DisposalSiteAttendanceMapper } from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceMapper';
import { Store } from '~/framework/domain/store';
import { UpdateDisposalSiteAttendanceInput } from '~/graphql/graphQLServerApi';

export type IUpdateDisposalSiteAttendanceData = UpdateDisposalSiteAttendanceInput;

export const disposalSiteAttendanceServiceSymbol = Symbol('disposalSiteAttendanceService');

export class DisposalSiteAttendanceApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly disposalSiteAttendanceMapper: DisposalSiteAttendanceMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.disposalSiteAttendanceMapper = new DisposalSiteAttendanceMapper(
      store.masters.disposalSiteAttendance,
      store.masters.attendance,
      store.masters.client,
      store.masters.disposalSite,
      store.masters.user
    );
  }

  async getByDateRange(disposalSiteIds: string[], start: Date, end: Date): Promise<DisposalSiteAttendanceEntity[]> {
    const disposalSiteAttendanceApi = this.serverApis.get(disposalSiteAttendanceSymbol);
    const data = await disposalSiteAttendanceApi.getByDateRange(disposalSiteIds, start, end);
    return data.map((disposalSiteAttendance) => this.disposalSiteAttendanceMapper.map([disposalSiteAttendance])[0]);
  }

  async updateDisposalSiteAttendance(
    data: IUpdateDisposalSiteAttendanceData
  ): Promise<DisposalSiteAttendanceEntity | string> {
    const disposalSiteAttendanceApi = this.serverApis.get(disposalSiteAttendanceSymbol);
    const result = await disposalSiteAttendanceApi.updateDisposalSiteAttendance(data);

    return result;
  }
}
