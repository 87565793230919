import { PackingStyleTaskTypeDefault, UpdatePackingStyleTaskTypeDefaultInput } from '~/graphql/graphQLServerApi';
export const packingStyleTaskTypeDefault$getAllSymbol = Symbol('getAll');
export const packingStyleTaskTypeDefault$updateSymbol = Symbol('update');

export type PackingStyleTaskTypeDefaultApiTypes = {
  [packingStyleTaskTypeDefault$getAllSymbol]: IGetAllApi;
  [packingStyleTaskTypeDefault$updateSymbol]: IUpdateApi;
};

export interface IGetAllApi {
  getAll(): Promise<IPackingStyleTaskTypeDefaultData[]>;
}

export type IPackingStyleTaskTypeDefaultData = PackingStyleTaskTypeDefault;

export type IUpdatePackingStyleTaskTypeDefaultData = UpdatePackingStyleTaskTypeDefaultInput;

export interface IUpdateApi {
  update(data: IUpdatePackingStyleTaskTypeDefaultData[]): Promise<IPackingStyleTaskTypeDefaultData[]>;
}
