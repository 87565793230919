
import Vue, { PropType } from 'vue';
import RDisposalSitesDisposablePeriod from './RDisposalSitesDisposablePeriod.vue';
import RDisposalSitesDurationTimeEditor from './RDisposalSitesDurationTimeEditor.vue';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';
import { PersistentId, ValidationRule } from '~/framework/typeAliases';
import RVInput from '~/components/common/r-v-input/RVInput.vue';
import RDisposalSiteAssignmentTypeInput from '~/components/panels/schedule/r-order-form/RDisposalSiteAssignmentTypeInput.vue';
import RDisposalSiteSequentialMultipleAssignment from '~/components/panels/schedule/r-order-form/RDisposalSiteSequentialMultipleAssignment.vue';
import RDisposalSitePrioritySingleAssignment from '~/components/panels/schedule/r-order-form/RDisposalSitePrioritySingleAssignment.vue';
import RDisposalSiteSequenceDialog from '~/components/panels/schedule/r-order-form/RDisposalSiteSequenceDialog.vue';
import RDisposalSitePriorityDialog from '~/components/panels/schedule/r-order-form/RDisposalSitePriorityDialog.vue';
import DisabledReason from '~/components/panels/schedule/r-order-form/disabledReasonEnum';
import { RVInputInstance } from '~/components/panels/schedule/r-order-form/rDisposalSites';
import { DisposalSiteDuration } from '~/components/panels/schedule/r-order-form/RDisposalSitesDurationTimeEditor.vue';
import { DisposalSiteDisposablePeriod } from '~/components/panels/schedule/r-order-form/RDisposalSitesDisposablePeriod.vue';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

enum EventTypes {
  ChangeAssignmentType = 'change-disposal-site-assignment-type',
  ChangeDisposalSiteIds = 'change-disposal-site-ids',
  UpdateSelectedDisposalSite = 'update-selected-disposal-site',
  UpdateSelectedDisposalSiteWithPriority = 'update-selected-disposal-site-with-priority',
  UpdateDurations = 'update-durations',
  UpdateDisposablePeriods = 'update-disposable-periods',
  EditDisposalSite = 'edit-disposal-site',
  Mounted = 'mounted',
  BeforeDestroy = 'beforeDestroy',
}

export default Vue.extend({
  name: 'RDisposalSites',
  components: {
    RVInput,
    RDisposalSiteAssignmentTypeInput,
    RDisposalSiteSequentialMultipleAssignment,
    RDisposalSitePrioritySingleAssignment,
    RDisposalSitePriorityDialog,
    RDisposalSiteSequenceDialog,
    RDisposalSitesDisposablePeriod,
    RDisposalSitesDurationTimeEditor,
  },
  model: {
    prop: 'selectedDisposalSiteIds',
    event: EventTypes.ChangeDisposalSiteIds,
  },
  props: {
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disabledReason: {
      type: Set as PropType<Set<DisabledReason>>,
      required: true,
    },
    selectedDisposalSiteIds: {
      type: Array as PropType<Array<PersistentId>>,
      required: true,
    },
    selectedDisposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
    isIrregularTaskSelected: {
      type: Boolean,
      required: true,
    },
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSitesDisposableDatesText: {
      type: String,
      required: true,
    },
    totalDurationOfTasks: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default: () => {
        return [];
      },
    },
    errorMessages: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => {
        return [];
      },
    },
    maxErrorCount: {
      type: Number,
      required: true,
    },
    disposablePeriodErrorMessages: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data(): {
    OrderDisposalSiteAssignmentType: typeof OrderDisposalSiteAssignmentType;
    DisabledReason: typeof DisabledReason;
    isSequentialMultipleDialogActive: boolean;
    isPrioritySingleDialogActive: boolean;
  } {
    return {
      OrderDisposalSiteAssignmentType,
      DisabledReason,
      isSequentialMultipleDialogActive: false,
      isPrioritySingleDialogActive: false,
    };
  },
  mounted() {
    this.$emit(EventTypes.Mounted, this);
  },
  beforeDestroy() {
    this.$emit(EventTypes.BeforeDestroy);
  },
  methods: {
    onChangeSelectedDisposalSiteIds(value: Array<PersistentId>) {
      this.$emit(EventTypes.ChangeDisposalSiteIds, value);
      this.$emit(EventTypes.UpdateSelectedDisposalSite);
    },
    // FIXME: ダイアログの確定ボタンを押した時に呼ばれるようにしたい
    // 現状はダイアログを開いた時点で設定されるようになっている
    onChangeAssignmentType(value: OrderDisposalSiteAssignmentType) {
      switch (value) {
        case OrderDisposalSiteAssignmentType.SequentialMultiple:
          this.toggleSequentialMultipleEditDialog(true);
          break;

        case OrderDisposalSiteAssignmentType.PrioritySingle:
          this.togglePrioritySingleEditDialog(true);
          break;
        case OrderDisposalSiteAssignmentType.NonSequentialMultiple:
          this.$emit(EventTypes.ChangeAssignmentType, OrderDisposalSiteAssignmentType.NonSequentialMultiple);
          break;
      }
    },
    onClickEditDisposalSite(value: DisposalSiteEntity) {
      this.$emit(EventTypes.EditDisposalSite, value);
    },
    onChangeSelectedDisposalSiteIdsWithSequence(value: Array<PersistentId>): void {
      this.$emit(EventTypes.ChangeAssignmentType, OrderDisposalSiteAssignmentType.SequentialMultiple);
      this.$emit(EventTypes.ChangeDisposalSiteIds, value);
      this.$emit(EventTypes.UpdateSelectedDisposalSite);
    },
    onChangeSelectedDisposalSiteIdsWithPriority(
      highPriorityDisposalSiteIds: string[],
      lowPriorityDisposalSiteIds: string[]
    ): void {
      this.$emit(EventTypes.ChangeAssignmentType, OrderDisposalSiteAssignmentType.PrioritySingle);
      this.$emit(EventTypes.ChangeDisposalSiteIds, [...highPriorityDisposalSiteIds, ...lowPriorityDisposalSiteIds]);
      this.$emit(
        EventTypes.UpdateSelectedDisposalSiteWithPriority,
        highPriorityDisposalSiteIds,
        lowPriorityDisposalSiteIds
      );
    },
    onDisposalSiteDurationTimesChange(values: DisposalSiteDuration[]) {
      this.$emit(EventTypes.UpdateDurations, values);
    },
    onDisposalSiteDisposablePeriodsChange(values: DisposalSiteDisposablePeriod[]) {
      this.$emit(EventTypes.UpdateDisposablePeriods, values);
    },
    toggleSequentialMultipleEditDialog(value: boolean) {
      this.isSequentialMultipleDialogActive = value;
    },
    togglePrioritySingleEditDialog(value: boolean) {
      this.isPrioritySingleDialogActive = value;
    },
    resetInput() {
      (this.$refs.input as RVInputInstance).reset();
    },
  },
});
