import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { CarAttendanceType } from '~/framework/domain/typeAliases';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';
import { ICarAttendanceData } from '~/framework/server-api/masters/carAttendance';

export class CarAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  attendance: AttendanceEntity;
  carId: PersistentId;
  carAttendanceType: CarAttendanceType;
  periodStart: Maybe<Seconds>;
  periodEnd: Maybe<Seconds>;
  note: Maybe<string>;
  unavailableReason: Maybe<string>;

  constructor(data: ICarAttendanceData, attendance: AttendanceEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.carId = data.carId;
    this.carAttendanceType = data.carAttendanceType;
    this.periodStart = data.periodStart;
    this.periodEnd = data.periodEnd;
    this.note = data.note;
    this.unavailableReason = data.unavailableReason;
    this.attendance = attendance;
  }

  update(data: ICarAttendanceData, attendance: AttendanceEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of CarAttendanceEntity');
    }
    this.carId = data.carId;
    this.carAttendanceType = data.carAttendanceType;
    this.periodStart = data.periodStart;
    this.periodEnd = data.periodEnd;
    this.note = data.note;
    this.unavailableReason = data.unavailableReason;
    this.attendance = attendance;
  }
}
