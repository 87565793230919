
import Vue, { PropType } from 'vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  getHoursOf,
  getMinutesOf,
  getSecondsFromHours,
  getSecondsFromMinutes,
} from '~/framework/services/date-time/date-time';
import { sanitizeNaturalNumber } from '~/framework/view-models/ruleLogics';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';
import { DisposalSiteDuration } from '~/components/panels/schedule/r-order-form/RDisposalSitesDurationTimeEditor.vue';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

type DataType = {
  durationTimeItems: DisposalSiteDurationTimesItem[];
  listenerDisposer: Maybe<() => void>;
};

type DisposalSiteDurationTimesItem = {
  orderAssignedDisposalSite: CreateOrderDisposalSiteInput;
  name: string;
  durationOfTask: number;
  durationAtEntranceHours: number;
  durationAtEntranceMinutes: number;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

export default Vue.extend({
  name: 'RDisposalSiteDurationTimesMultipleDialog',
  components: {
    RDialog,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<DisposalSiteEntity[]>,
      required: true,
    },
    totalDurationOfTasks: {
      type: Number,
      required: true,
    },
  },
  data(): DataType {
    return {
      // orderAssignedDisposalSites の配列に要素を足す
      // 処分場の名前、 タスクにかかる時間、入場時間（時）、入場時間（分）
      durationTimeItems: this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
        const name = this.disposalSiteEntities.find(
          (disposalSiteEntity) => disposalSiteEntity.id === orderAssignedDisposalSite.disposalSiteId
        )?.name;
        if (name === undefined) {
          throw new Error(
            `Could not find it in the disposalSites. disposalSiteId: ${orderAssignedDisposalSite.disposalSiteId}`
          );
        }

        return {
          orderAssignedDisposalSite,
          name,
          durationOfTask: this.totalDurationOfTasks,
          durationAtEntranceHours: getHoursOf(orderAssignedDisposalSite.durationAtEntrance),
          durationAtEntranceMinutes: getMinutesOf(orderAssignedDisposalSite.durationAtEntrance),
        };
      }),
      listenerDisposer: undefined,
    };
  },
  mounted() {
    if (this.isDialogActive) this.createKeyboardEventListener();
  },
  beforeDestroy() {
    this.disposeKeyboardEventListener();
  },
  methods: {
    getHoursOf,
    getMinutesOf,
    getSecondsFromHours,
    getSecondsFromMinutes,
    // // ひとつの処分場辺りでかかる作業時間は荷姿の作業時間は処分場の数で割る
    durationTimePerDisposalSite(item: DisposalSiteDurationTimesItem): number {
      return item.durationOfTask / this.durationTimeItems.length;
    },
    isDurationAtEntranceEdited(durationAtEntrance: number, defaultDurationAtEntrance: number) {
      return durationAtEntrance !== defaultDurationAtEntrance;
    },
    // 処分場の入退場時間＋荷姿の作業の合計時間（時）を計算
    calculateTotalDurationHour(durationOfTasks: number, durationAtEntrance: number): number {
      return getHoursOf(durationAtEntrance + durationOfTasks);
    },
    // 合計作業時間（分）を計算
    calculateTotalDurationMinutes(durationOfTasks: number, durationAtEntrance: number): number {
      return getMinutesOf(durationAtEntrance + durationOfTasks);
    },
    resetDurationAtEntrance(disposalSiteId: PersistentId): void {
      this.durationTimeItems = this.durationTimeItems.map((durationTimeItem) => {
        if (durationTimeItem.orderAssignedDisposalSite.disposalSiteId !== disposalSiteId) {
          return durationTimeItem;
        }
        const disposalSite = this.disposalSiteEntity(durationTimeItem.orderAssignedDisposalSite);
        return {
          ...durationTimeItem,
          durationAtEntranceHours: getHoursOf(disposalSite.durationAtEntrance),
          durationAtEntranceMinutes: getMinutesOf(disposalSite.durationAtEntrance),
          orderAssignedDisposalSite: {
            ...durationTimeItem.orderAssignedDisposalSite,
            durationAtEntrance: disposalSite.durationAtEntrance,
          },
        };
      });
    },
    // 入退場時間の項目が変更されたとき、作業時間を計算しなおす
    onDurationTimeItemChange(hours: number, minutes: number, disposalSiteId: PersistentId): void {
      this.durationTimeItems = this.durationTimeItems.map((durationTimeItem) => {
        if (durationTimeItem.orderAssignedDisposalSite.disposalSiteId !== disposalSiteId) {
          return durationTimeItem;
        }

        const durationAtEntrance =
          getSecondsFromHours(sanitizeNaturalNumber(hours)) + getSecondsFromMinutes(sanitizeNaturalNumber(minutes));

        return {
          ...durationTimeItem,
          durationAtEntranceHours: getHoursOf(durationAtEntrance),
          durationAtEntranceMinutes: getMinutesOf(durationAtEntrance),
          orderAssignedDisposalSite: {
            ...durationTimeItem.orderAssignedDisposalSite,
            durationAtEntrance,
          },
        };
      });
    },
    disposalSiteEntity(orderAssignedDisposalSite: CreateOrderDisposalSiteInput): DisposalSiteEntity {
      return this.disposalSiteEntities.find((entity) => entity.id === orderAssignedDisposalSite.disposalSiteId)!;
    },
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      const durationAtEntrances: DisposalSiteDuration[] = this.durationTimeItems.map((durationTimeItem) => {
        return {
          disposalSiteId: durationTimeItem.orderAssignedDisposalSite.disposalSiteId,
          durationAtEntrance: durationTimeItem.orderAssignedDisposalSite.durationAtEntrance,
        };
      });
      this.$emit(EventTypes.Update, durationAtEntrances);
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
  },
});
