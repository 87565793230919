import { v4 } from 'uuid';
import { TaskType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { IWasteTypeData } from '~/framework/server-api/masters/wasteType';
import { IContainerTypeData } from '~/framework/server-api/masters/containerType';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

export interface IReservationGenerationSiteTaskData {
  taskType: TaskType;
  containerNum: number;
  wasteType: Maybe<IWasteTypeData>;
  containerType: IContainerTypeData;
}

export class ReservationGenerationSiteTaskEntity {
  id: string;
  taskType: TaskTypeEntity;
  containerNum: number;
  wasteType: Maybe<WasteTypeEntity>;
  containerType: ContainerTypeEntity;

  constructor(
    data: IReservationGenerationSiteTaskData,
    wasteType: Maybe<WasteTypeEntity>,
    containerType: ContainerTypeEntity,
    taskType: TaskTypeEntity
  ) {
    // id がないので、表示の都合上適当な値を生成して入れる
    this.id = v4();
    this.taskType = taskType;
    this.containerNum = data.containerNum;
    this.wasteType = wasteType;
    this.containerType = containerType;
  }
}
