import {
  IScheduleDataEntity,
  ScheduleDataEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDataEntity';
import { Maybe } from '~/framework/typeAliases';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';
import { PseudoOrderEntity } from '~/framework/domain/schedule/order/orderEntity';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { Infeasibility } from './infeasibility';

export interface IScheduleData extends IScheduleDataEntity<InconsistentRouteInfo> {
  /**
   * この配車表を読み込んだ段階で判明していた全乗務員
   * 配車表に関係ないデータも全部含んでいるのは人をまたいだ移動で最初に配車表に存在していなかった乗務員を
   * 割り当てる可能性があるため
   */
  readonly drivers: DriverEntity[];

  /**
   * 全ての車
   */
  readonly cars: CarEntity[];

  /**
   * ドライバー勤怠
   */
  readonly driverAttendances: DriverAttendanceEntity[];

  /**
   * 全ての処分場
   */
  readonly disposals: DisposalSiteEntity[];

  /**
   * 全ての受注
   */
  readonly orders: PseudoOrderEntity[];

  /**
   * エラーの数
   * 固定のエラーがあれば "?"
   * 作成不可のエラーがあれば "x"
   * なければ undefined
   */
  readonly errorNum: Maybe<string>;
}

export class ScheduleData extends ScheduleDataEntity<InconsistentRouteInfo> implements IScheduleData {
  readonly driverAttendances: DriverAttendanceEntity[];
  readonly drivers: DriverEntity[];
  readonly cars: CarEntity[];
  readonly disposals: DisposalSiteEntity[];
  readonly orders: PseudoOrderEntity[] = [];
  errorNum: Maybe<string> = undefined;

  constructor(
    id: string,
    inconsistencies: Maybe<Inconsistency[]>,
    infeasibilities: Maybe<Infeasibility[]>,
    drivers: DriverEntity[],
    driverAttendances: DriverAttendanceEntity[],
    cars: CarEntity[],
    disposals: DisposalSiteEntity[],
    orders: PseudoOrderEntity[]
  ) {
    super(id, inconsistencies, infeasibilities);
    this.drivers = drivers;
    this.driverAttendances = driverAttendances;
    this.cars = cars;
    this.disposals = disposals;
    this.orders = orders;
    this.updateErrorNum();
  }

  private updateErrorNum(): void {
    if (this.inconsistencies !== undefined) this.errorNum = '?';
    else if (this.infeasibilities !== undefined) this.errorNum = `${this.infeasibilities.length}`;
    else this.errorNum = undefined;
  }
}
