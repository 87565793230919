import { IDuplicatedNameError } from '~/framework/server-api/errors';
import { BaseSite, CreateBaseSiteInput, UpdateBaseSiteInput } from '~/graphql/graphQLServerApi';

export const baseSite$createSymbol = Symbol('baseSite$createSymbol');

export type CreateResultTypes = BaseSite | IBaseSiteMutationError;

export const baseSite$getAllSymbol = Symbol('baseSite$getAll');

export const baseSite$updateSymbol = Symbol('baseSite$updateSymbol');

export type UpdateResultTypes = BaseSite | IBaseSiteMutationError;

export type BaseSiteApiTypes = {
  [baseSite$getAllSymbol]: IGetAllApi;
  [baseSite$createSymbol]: ICreateApi;
  [baseSite$updateSymbol]: IUpdateApi;
};

export type IBaseSiteData = BaseSite;

export interface ICreateApi {
  create(data: ICreateBaseSiteData): Promise<CreateResultTypes>;
}

export type ICreateBaseSiteData = CreateBaseSiteInput;

export interface IGetAllApi {
  getAll(): Promise<IBaseSiteData[]>;
}

export interface IBaseSiteMutationError {
  __typename: 'BaseSiteMutationError';
  errors: BaseSiteMutationErrorTypes[];
}

export interface IHasCarsMustBeTrueError {
  __typename: 'HasCarsMustBeTrueError';
}

export interface IHasCarsOrHasContainersMustBeTrueError {
  __typename: 'HasCarsOrHasContainersMustBeTrueError';
}

export type BaseSiteMutationErrorTypes =
  | IDuplicatedNameError
  | IHasCarsMustBeTrueError
  | IHasCarsOrHasContainersMustBeTrueError;

export interface IUpdateApi {
  update(data: IUpdateBaseSiteData): Promise<UpdateResultTypes>;
}

export type IUpdateBaseSiteData = UpdateBaseSiteInput;
