import { WithoutId } from '~/framework/typeAliases';
import {
  CreateDriverAttendanceTemplateInput,
  DriverAttendanceTemplate,
  UpdateDriverAttendanceTemplateInput,
} from '~/graphql/graphQLServerApi';

export const driverAttendanceTemplate$createSymbol = Symbol('create');
export const driverAttendanceTemplate$getAllSymbol = Symbol('getAll');
export const driverAttendanceTemplate$getByIdsSymbol = Symbol('getByIds');
export const driverAttendanceTemplate$updateSymbol = Symbol('update');

export type DriverAttendanceTemplateApiTypes = {
  [driverAttendanceTemplate$getAllSymbol]: IGetAllApi;
  [driverAttendanceTemplate$getByIdsSymbol]: IGetByIdsApi;
  [driverAttendanceTemplate$updateSymbol]: IUpdateApi;
  [driverAttendanceTemplate$createSymbol]: ICreateApi;
};

export interface ICreateApi {
  create(data: WithoutId<IDriverAttendanceTemplateData>[]): Promise<string[]>;
}

export type IDriverAttendanceTemplateData = DriverAttendanceTemplate;

export type ICreateDriverAttendanceTemplateData = CreateDriverAttendanceTemplateInput;

export type IUpdateDriverAttendanceTemplateData = UpdateDriverAttendanceTemplateInput;

export interface IGetAllApi {
  getAll(): Promise<IDriverAttendanceTemplateData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IDriverAttendanceTemplateData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateDriverAttendanceTemplateData[]): Promise<string[]>;
}
