import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { WasteTypeStore } from '~/framework/domain/masters/waste-type/wasteTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { DriverStore } from '~/framework/domain/masters/driver/driverStore';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { DisposalSiteStore } from '~/framework/domain/masters/disposal-site/disposalSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { ClientStore } from '~/framework/domain/masters/client/clientStore';
import { ContainerTypeTaskTypeStore } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeStore';
import { DriverAttendanceStore } from '~/framework/domain/masters/driver-attendance/driverAttendanceStore';
import { ContainerTypeStore } from '~/framework/domain/masters/container-type/containerTypeStore';
import { OfficeSettingStore } from '~/framework/domain/masters/office-setting/officeSettingStore';
import { GenerationSiteStore } from '~/framework/domain/masters/generation-site/generationSiteStore';
import { HolidayRuleStore } from '~/framework/domain/masters/holiday-rule/holidayRuleStore';
import { CarTypeStore } from '~/framework/domain/masters/car-type/carTypeStore';
import { CarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeStore';
import { CarAttendanceStore } from '~/framework/domain/masters/car-attendance/carAttendanceStore';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { PackingStyleTaskTypeDefaultStore } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { DisposalSiteAttendanceStore } from './disposal-site-attendance/disposalSiteAttendanceStore';

export class MastersStore {
  // normalized
  readonly collectablePeriodTemplate: CollectablePeriodTemplateStore;
  readonly orderGroup: OrderGroupStore;
  readonly driverAttendanceTemplate: DriverAttendanceTemplateStore;
  readonly attendance: AttendanceStore;
  readonly disposalSiteAttendance: DisposalSiteAttendanceStore;
  readonly user: UserStore;
  readonly containerTypeTaskType: ContainerTypeTaskTypeStore;
  readonly officeSetting: OfficeSettingStore;
  readonly holidayRule: HolidayRuleStore;
  readonly packingStyle: PackingStyleStore;
  readonly packingStyleTaskTypeDefault: PackingStyleTaskTypeDefaultStore;
  readonly jwnetWasteMaster: JwnetWasteMasterStore;
  readonly baseSite: BaseSiteStore;
  readonly car: CarStore;
  readonly carType: CarTypeStore;
  readonly carTypeContainerType: CarTypeContainerTypeStore;
  readonly driver: DriverStore;
  readonly containerType: ContainerTypeStore;
  readonly disposalSite: DisposalSiteStore;
  readonly client: ClientStore;
  readonly driverAttendance: DriverAttendanceStore;
  readonly generationSite: GenerationSiteStore;
  readonly carAttendance: CarAttendanceStore;
  readonly wasteType: WasteTypeStore;

  constructor() {
    this.collectablePeriodTemplate = new CollectablePeriodTemplateStore();
    this.orderGroup = new OrderGroupStore();
    this.driverAttendanceTemplate = new DriverAttendanceTemplateStore();
    this.attendance = new AttendanceStore();
    this.disposalSiteAttendance = new DisposalSiteAttendanceStore();
    this.user = new UserStore();
    this.containerTypeTaskType = new ContainerTypeTaskTypeStore();
    this.officeSetting = new OfficeSettingStore();
    this.holidayRule = new HolidayRuleStore();
    this.packingStyle = new PackingStyleStore();
    this.packingStyleTaskTypeDefault = new PackingStyleTaskTypeDefaultStore();
    this.jwnetWasteMaster = new JwnetWasteMasterStore();
    this.baseSite = new BaseSiteStore();
    this.car = new CarStore();
    this.carType = new CarTypeStore();
    this.carTypeContainerType = new CarTypeContainerTypeStore();
    this.driver = new DriverStore();
    this.containerType = new ContainerTypeStore();
    this.disposalSite = new DisposalSiteStore();
    this.client = new ClientStore();
    this.driverAttendance = new DriverAttendanceStore();
    this.generationSite = new GenerationSiteStore();
    this.carAttendance = new CarAttendanceStore();
    this.wasteType = new WasteTypeStore();
  }
}
