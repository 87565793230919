import { isBefore, isSameDay } from 'date-fns';
import last from 'lodash/last';
import { MarginType, DriverAssignmentType } from '~/framework/domain/typeAliases';
import {
  ICacheablePanelOption,
  IInitializablePanelOption,
  IEntitlablePanelOption,
} from '~/framework/view-models/panels/abstractEntityFormPanel';
import { CloseHandler } from '~/framework/view-models/panels/entityFormPanel';
import { IOpenPanelOption, IPanel, IUpdateDisplayedWidthEvent } from '~/framework/view-models/panels/panel';
import { Maybe, PersistentId, Pixel } from '~/framework/typeAliases';
import { GenerationSiteTaskCategory } from '~/framework/view-models/generationSiteTaskCategory';
import { ReservationEntity } from '~/framework/domain/reservation/reservation/reservationEntity';
import {
  IIrregularTaskItem,
  IrregularTaskItemFactory,
} from '~/components/panels/schedule/r-order-form/irregularTaskItem';
import {
  GenerationSiteTaskItem,
  IGenerationSiteTaskItem,
} from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { IPanelState } from '~/framework/view-models/panels/panelState';
import { ITypedAsyncEvent, TypedAsyncEvent } from '~/framework/events/typedAsyncEvent';

import {
  CandidateDateCollectablePeriodItem,
  ICandidateDateCollectablePeriodItem,
} from '~/components/panels/reservation/r-reservation-from/candidateDateCollectablePeriod';
import { ICollectablePeriodTemplateOption } from '~/framework/view-models/collectablePeriodTemplateOption';
import {
  isDriversCandidate,
  isHelpersCandidate,
  isOperatorsCandidate,
} from '~/framework/domain/schedule/order/driver/orderAssignableDriver';
import {
  CreateOrderAssignableDriverInput,
  CreateOrderDisposalSiteInput,
  OrderDisposalSiteAssignmentType,
} from '~/graphql/graphQLServerApi';
import { ids } from '~/framework/core/entity';

export interface IOpenReservationFormArgs {
  /**
   * ご覧したいエンティティ
   */
  entity: ReservationEntity;

  /**
   * オプション
   */
  option?: IReservationFormPanelOption;
}

export interface ICloseReservationFormArgs {
  /**
   * パネルを開いた時のエンティティ
   */
  entity: ReservationEntity;

  /**
   * 受注確定をした際に、確定によって新規登録された受注の Id。
   * 確定しないまま閉じた場合は undefined。
   */
  createdOrderId?: Maybe<PersistentId>;

  /**
   * 辞退した場合はtrue, それ以外の場合はfalse
   */
  isCancelled: boolean;
}

export interface IReservationFormPanelOption
  extends IOpenPanelOption,
    ICacheablePanelOption,
    // TODO: InitialFormValues を過不足なく指定しなくてはならないが、
    // 一部省略可能にし、省略した場合はデフォルトで上書きされるようにする
    IInitializablePanelOption<IFormValues>,
    IEntitlablePanelOption {
  /**
   * schedule のご覧中の日付
   */
  scheduleDate?: Maybe<Date>;
}

/**
 * Composite な ReservationEntity を確認し、受注確定・拒否するためのもの
 */
export interface IReservationFormPanel extends IPanel {
  /**
   * フォームを開きたい時のイベント
   * Vue のコンポーネント側が listen している
   */
  readonly openFormEvent: ITypedAsyncEvent<IOpenReservationFormArgs>;

  /**
   * フォームが閉じられた時のイベント
   * Vue のコンポーネント側から呼ばれる
   * ページ側でこれを listen する時は beforeDestroy で dispose するのを忘れない事
   */
  readonly closeFormEvent: ITypedEvent<ICloseReservationFormArgs>;

  /**
   * 現在ご覧中のエンティティ
   * パネルを開いていない状態では undefined
   */
  readonly viewingEntity: Maybe<ReservationEntity>;

  /**
   * クローズしたいという要求をハンドルするファンクタを登録する
   * vue 側のコンポーネントのクローズを登録するイメージ
   * イベントとしてもよかったが、イベントは返り値を返す様なものを想定していないのでこの様な形にした
   *
   * @param closeHandler
   */
  registerCloseHandler(closeHandler: CloseHandler): void;

  /**
   * パネルを開こうとするが、失敗する可能性もある
   * 他にパネルを開いていて閉じる事にユーザーが同意しなければ失敗する可能性がある
   * 全く同じデータを編集しようとした場合はそのまま true を返す
   *
   * @param entity ご覧したい予約のデータ
   * @param option オプション
   * @returns 成功したかどうか
   */
  open(entity: ReservationEntity, option?: IReservationFormPanelOption): Promise<boolean>;
}

export class ReservationFormPanel implements IReservationFormPanel {
  private readonly panelState: IPanelState;
  private closeHandler: Maybe<CloseHandler>;

  readonly openFormEvent: ITypedAsyncEvent<IOpenReservationFormArgs>;
  readonly closeFormEvent: ITypedEvent<ICloseReservationFormArgs>;
  readonly updateDisplayedWidthEvent: ITypedEvent<IUpdateDisplayedWidthEvent>;
  viewingEntity: Maybe<ReservationEntity>;

  constructor(panelState: IPanelState) {
    this.viewingEntity = undefined;
    this.panelState = panelState;
    this.openFormEvent = new TypedAsyncEvent();
    this.closeFormEvent = new TypedEvent();
    this.updateDisplayedWidthEvent = new TypedEvent();
    const closeListener = this.onClose.bind(this);
    this.closeFormEvent.on(closeListener);
  }

  registerCloseHandler(closeHandler: CloseHandler) {
    this.closeHandler = closeHandler;
  }

  async open(entity: ReservationEntity, option?: IReservationFormPanelOption): Promise<boolean> {
    // 全く同じものをそもそも編集中な場合は開いた事にする
    if (
      this.viewingEntity !== undefined &&
      entity !== undefined &&
      this.viewingEntity.persistentId === entity.persistentId
    ) {
      return true;
    }

    if (this.panelState.isPanelOpen) {
      const closeResult = await this.panelState.closeCurrentPanel(option?.forceClose ?? false);
      if (closeResult === false) return false;
    }
    const registerResult = this.panelState.registerCurrentPanel(this);
    if (registerResult === false) return false;
    this.viewingEntity = entity;
    await this.openFormEvent.emit({ entity, option });
    return true;
  }

  async close(forceClose: boolean): Promise<boolean> {
    if (this.closeHandler === undefined) throw new Error(`Close handler has not been registered!`);
    return await this.closeHandler(forceClose);
  }

  updateDisplayedWidth(width: Pixel) {
    this.updateDisplayedWidthEvent.emit({ panel: this, width });
  }

  /**
   * パネルから close が呼ばれた時か、手動で閉じられた時にも呼ばれる
   * どちらから呼ばれても openEntity を空にしたいため
   *
   * @param _args
   * @private
   */
  private onClose(_args: ICloseReservationFormArgs): void {
    this.viewingEntity = undefined;
    this.panelState.unregisterCurrentPanel(this);
  }
}

/**
 * フォームの値
 */
export interface IFormValues {
  reservationId: PersistentId;
  candidateDateCollectablePeriodItems: ICandidateDateCollectablePeriodItem[];
  selectedCandidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem;
  reservationNote: Maybe<string>;
  orderGroupId: Maybe<PersistentId>;
  clientId: Maybe<PersistentId>;
  generationSiteId: Maybe<PersistentId>;
  generationSiteTaskCategory: GenerationSiteTaskCategory;
  irregularTask: IIrregularTaskItem;
  carNum: number;
  disposalSiteIds: PersistentId[];
  orderAssignedDisposalSites: Array<CreateOrderDisposalSiteInput>;
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  generationSiteTasks: IGenerationSiteTaskItem[];
  durationAtGenerationSite: Maybe<number>;
  orderNote: Maybe<string>;
  driverNum: number;
  avoidHighways: boolean;
  driverAssignmentType: DriverAssignmentType;
  isAssignableDriversCandidate: boolean;
  assignableDrivers: Array<CreateOrderAssignableDriverInput>;
  assignableCarIds: PersistentId[];
  assignableCarTypeIds: PersistentId[];
  routeCollectionAllowed: boolean;
  fixedArrivalTime: Maybe<number>;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
}

export type GetInitialFormValuesByReservationOptions = {
  scheduleDate?: Maybe<Date>;
  collectablePeriodTemplates?: Maybe<ICollectablePeriodTemplateOption[]>;
};

/**
 * 予約情報を与えて、フォームに初期値として表示するためのデータを取得する。
 * @param reservation 予約の情報
 * @param options GetInitialFormValuesByReservationOptions を参照
 * @returns フォームの値
 */
export const getInitialFormValuesByReservation = (
  reservation: ReservationEntity,
  options?: GetInitialFormValuesByReservationOptions
): IFormValues => {
  // 現状サポートしているのはコンテナを利用したタスクのみ
  // インターフェースが微妙に違うので共通化は難しいと判断
  const generationSiteTasks: IGenerationSiteTaskItem[] = [];
  for (const task of reservation.generationSiteTasks) {
    generationSiteTasks.push(
      new GenerationSiteTaskItem(
        task.taskType,
        task.wasteType?.id,
        task.containerType?.id,
        task.containerNum,
        undefined, // 予約の販売管理システム連携の時に実装予定
        undefined // 予約の販売管理システム連携の時に実装予定
      )
    );
  }
  const irregularTaskItem = IrregularTaskItemFactory.instantiateDefault();
  const generationSiteTaskCategory = GenerationSiteTaskCategory.TaskWithContainer;

  const candidateDateCollectablePeriodItems: ICandidateDateCollectablePeriodItem[] = reservation.candidateDates.map(
    (candidateDate) => {
      const candidateDateCollectablePeriodItem = new CandidateDateCollectablePeriodItem(candidateDate);

      if (options?.collectablePeriodTemplates) {
        const collectablePeriodTemplate = options.collectablePeriodTemplates.find(
          (collectablePeriodTemplate) => collectablePeriodTemplate.name === candidateDate.collectablePeriodTemplateName
        );
        candidateDateCollectablePeriodItem.collectablePeriodTemplateId = collectablePeriodTemplate?.persistentId;
      }

      if (candidateDateCollectablePeriodItem.isCollectableTimeDistinct) {
        candidateDateCollectablePeriodItem.setCollectableDistinctTime();
      }

      return candidateDateCollectablePeriodItem;
    }
  );

  const formValues: IFormValues = {
    reservationId: reservation.persistentId,
    candidateDateCollectablePeriodItems,
    selectedCandidateDateCollectablePeriodItem: defaultSelectionForCandidateDate(
      candidateDateCollectablePeriodItems,
      options?.scheduleDate
    ),
    reservationNote: reservation.note,
    orderGroupId: reservation.orderGroup.id,
    clientId: reservation.client.id,
    generationSiteId: reservation.generationSite.id,
    generationSiteTaskCategory,
    generationSiteTasks,
    irregularTask: irregularTaskItem,
    carNum: reservation.carNum,
    disposalSiteIds: reservation.orderAssignedDisposalSitesAndType.orderDisposalSites.map(
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSite.id
    ),
    disposalSiteAssignmentType: reservation.orderAssignedDisposalSitesAndType.disposalSiteAssignmentType,
    orderAssignedDisposalSites: reservation.orderAssignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => {
        return { ...orderDisposalSite, disposalSiteId: orderDisposalSite.disposalSite.id };
      }
    ),
    durationAtGenerationSite: reservation.durationAtGenerationSite,
    orderNote: '',
    driverNum: reservation.minAssignedDriverNum,
    avoidHighways: reservation.avoidHighways,
    driverAssignmentType: reservation.driverAssignmentType,
    isAssignableDriversCandidate:
      reservation.driverAssignmentType === DriverAssignmentType.NotDistinguished
        ? isDriversCandidate(reservation.minAssignedDriverNum, reservation.assignableDrivers)
        : isOperatorsCandidate(reservation.carNum, reservation.assignableDrivers) ||
          isHelpersCandidate(reservation.carNum, reservation.minAssignedDriverNum, reservation.assignableDrivers),
    assignableDrivers: reservation.assignableDrivers.map((assignableDriver) => {
      return { driverId: assignableDriver.driver.id, driverType: assignableDriver.driverType };
    }),
    assignableCarIds: ids(reservation.assignableCars),
    assignableCarTypeIds: ids(reservation.assignableCarTypes),
    routeCollectionAllowed: reservation.routeCollectionAllowed,
    fixedArrivalTime: undefined,
    isFixedArrivalTimeReportNeeded: reservation.isFixedArrivalTimeReportNeeded,
    marginTypeOfFixedArrivalTime: reservation.marginTypeOfFixedArrivalTime,
    marginOfFixedArrivalTime: reservation.marginOfFixedArrivalTime,
  };

  return formValues;
};

const defaultSelectionForCandidateDate = (
  candidateDateCollectablePeriodItems: ICandidateDateCollectablePeriodItem[],
  scheduleDate?: Maybe<Date>
): ICandidateDateCollectablePeriodItem => {
  let defaultSelectionForCandidateDate: Maybe<ICandidateDateCollectablePeriodItem>;

  // ご覧中の配車表の日付があれば、その日付の最初の候補を選択。 candidateDate にその日付がなければ次へ。
  if (scheduleDate) {
    defaultSelectionForCandidateDate = candidateDateCollectablePeriodItems.find((candidateDateCollectablePeriodItem) =>
      isSameDay(candidateDateCollectablePeriodItem.date, scheduleDate)
    );
  }

  // 現在日以降の最初の候補を選択。
  if (defaultSelectionForCandidateDate === undefined) {
    const presentDate = new Date();

    for (const candidateDateCollectablePeriodItem of candidateDateCollectablePeriodItems) {
      if (isBefore(candidateDateCollectablePeriodItem.date, presentDate)) {
        continue;
      } else {
        defaultSelectionForCandidateDate = candidateDateCollectablePeriodItem;
      }
    }
  }

  // 現在日以降の候補がない場合は、最後の候補を選択。
  if (defaultSelectionForCandidateDate === undefined) {
    defaultSelectionForCandidateDate = last(candidateDateCollectablePeriodItems);
  }

  // このケースは実質 candidateDateCollectablePeriodItems が空の配列の場合以外は起きないはず。
  if (defaultSelectionForCandidateDate === undefined) {
    throw new Error(
      `Couldn't find defaultSelectionForCandidateDate. candidateDateCollectablePeriodItems: ${candidateDateCollectablePeriodItems}`
    );
  }

  return defaultSelectionForCandidateDate;
};
