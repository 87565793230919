
import Vue, { PropType } from 'vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';

type DataType = {
  localOrderAssignedDisposalSites: CreateOrderDisposalSiteInput[];
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

export default Vue.extend({
  name: 'RDisposalSiteDisposablePeriodMultipleDialog',
  components: {
    RDialog,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<DisposalSiteEntity[]>,
      required: true,
    },
    disposalSitesDisposableDatesText: {
      type: String,
      required: true,
    },
  },
  data(): DataType {
    return {
      localOrderAssignedDisposalSites: this.orderAssignedDisposalSites,
      listenerDisposer: undefined,
    };
  },
  mounted() {
    if (this.isDialogActive) this.createKeyboardEventListener();
  },
  beforeDestroy() {
    this.disposeKeyboardEventListener();
  },
  methods: {
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      this.$emit(
        'update',
        this.localOrderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
            disposablePeriodStart: orderAssignedDisposalSite.disposablePeriodStart,
            disposablePeriodEnd: orderAssignedDisposalSite.disposablePeriodEnd,
          };
        })
      );
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
    disposalSiteName(disposalSiteId: string): string {
      const disposalSite = this.disposalSiteEntities.find((disposalSite) => disposalSite.id === disposalSiteId);
      // NOTE: store に存在しない処分場が出てくることはほぼありえないが、 type guard が必要
      return disposalSite ? disposalSite.name : '不明な処分場';
    },
  },
});
