
import Vue from 'vue';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe, PersistentId, ValidationRule } from '~/framework/typeAliases';
import { required, float, greaterThanZero, maxLength } from '~/framework/view-models/rules';

import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import {
  hasTransformingCarTypeOptions,
  IHasTransformingCarTypeOption,
} from '~/framework/view-models/hasTransformingCarTypeOption';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import {
  containerTypeSymbol,
  ContainerTypeApplicationService,
} from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { mapEntity } from '~/framework/core/mapper';
import {
  IReservationSiteEnabledOption,
  reservationSiteEnabledOptions,
} from '~/framework/view-models/reservationSiteEnabled';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { officeSettingSymbol } from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { packingStyleSymbol } from '~/framework/application/masters/packingStyleApplicationService';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';

enum FormMode {
  Register,
  Edit,
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  containerTypeApplicationService: ContainerTypeApplicationService;
};

type AsyncDataType = {
  viewModel: Maybe<ContainerTypeForm>;
};

type InitialFormValues = {
  packingStyleId: Maybe<string>;
  name: string;
  unitName: string;
  transformingCarTypeId: Maybe<PersistentId>;
  reservationSiteEnabled: boolean;
};

class ContainerTypeForm {
  title: string;
  formMode: FormMode;
  containerType: Maybe<ContainerTypeEntity>;
  createdContainerType: Maybe<ContainerTypeEntity>;
  updatedContainerType: Maybe<ContainerTypeEntity>;
  rules: { [key: string]: ValidationRule };
  officeSetting: OfficeSettingEntity;
  packingStyles: PackingStyleEntity[];
  carTypes: CarTypeEntity[];
  packingStyleId: Maybe<string> = undefined;
  name: string = '';
  unitName: string = '';
  hasTransformingCarType: boolean = false;
  transformingCarTypeId: Maybe<PersistentId> = undefined;
  hasTransformingCarTypeOptions: IHasTransformingCarTypeOption[];
  reservationSiteEnabled: boolean;
  isReservationSiteEnabledOptions: IReservationSiteEnabledOption[];
  isRegistering: boolean = false;
  _isFormValid: boolean = false;
  isDeletable: boolean = false;
  isRegisterButtonDisabled: boolean = true;
  private initialFormValues: InitialFormValues;
  private packingStyleMap: Map<string, PackingStyleEntity>;

  get isFormValid() {
    return this._isFormValid;
  }

  set isFormValid(value) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  get isDirty(): boolean {
    return (
      this.packingStyleId !== this.initialFormValues.packingStyleId ||
      this.name !== this.initialFormValues.name ||
      this.unitName !== this.initialFormValues.unitName ||
      this.transformingCarTypeId !== this.initialFormValues.transformingCarTypeId ||
      this.reservationSiteEnabled !== this.initialFormValues.reservationSiteEnabled
    );
  }

  constructor(
    containerType: Maybe<ContainerTypeEntity>,
    officeSetting: OfficeSettingEntity,
    packingStyles: PackingStyleEntity[],
    carTypes: CarTypeEntity[]
  ) {
    this.containerType = containerType;
    this.officeSetting = officeSetting;
    this.packingStyles = packingStyles;
    this.packingStyleMap = mapEntity(this.packingStyles);
    this.carTypes = carTypes;
    this.rules = { required, float, greaterThanZero };
    this.hasTransformingCarTypeOptions = hasTransformingCarTypeOptions;
    this.reservationSiteEnabled = containerType !== undefined ? containerType.reservationSiteEnabled : true;
    this.isReservationSiteEnabledOptions = reservationSiteEnabledOptions;
    this.formMode = this.containerType === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? '荷姿の登録' : '荷姿の編集';
    this.initialFormValues = this.getInitialFormValues(this.containerType);
    this.packingStyleId = this.initialFormValues.packingStyleId;
    this.name = this.initialFormValues.name;
    this.unitName = this.initialFormValues.unitName;
    this.transformingCarTypeId = this.initialFormValues.transformingCarTypeId;
    this.hasTransformingCarType = this.transformingCarTypeId !== undefined;
    this.isDeletable = this.formMode === FormMode.Edit;
  }

  onChangePackingStyle(): void {
    if (this.packingStyleId) {
      this.unitName = this.packingStyleMap.getOrError(this.packingStyleId).defaultUnitName;
    }
  }

  onDeleteContainerType(): void {}

  onChangeHasTransformingCarType(): void {
    if (this.hasTransformingCarType === false) {
      this.transformingCarTypeId = undefined;
    }
  }

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isFormValid;
  }

  private getInitialFormValues(containerType: Maybe<ContainerTypeEntity>): InitialFormValues {
    return containerType === undefined
      ? this.getDefaultInitialFormValues()
      : this.getInitialFormValuesByContainerType(containerType);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      packingStyleId: undefined,
      name: '',
      unitName: '',
      transformingCarTypeId: undefined,
      reservationSiteEnabled: true,
    };
  }

  private getInitialFormValuesByContainerType(containerType: ContainerTypeEntity): InitialFormValues {
    return {
      packingStyleId: containerType.packingStyle.id,
      name: containerType.name,
      unitName: containerType.unitName,
      transformingCarTypeId: containerType.transformingCarType?.id,
      reservationSiteEnabled: containerType.reservationSiteEnabled,
    };
  }
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RContainerTypeForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const containerTypeApplicationService = this.$context.applications.get(containerTypeSymbol);
    return {
      isActive: false,
      isConfirmDialogActive: false,
      viewModel: undefined,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      FormMode,
      containerTypeApplicationService,
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CONTAINER_TYPE;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.containerTypeFormPanel.openFormEvent.on(this.onOpen);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.$context.panels.containerTypeFormPanel.registerCloseHandler(this.onCloseForm);
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    maxLength,
    /**
     * @public
     */
    async open(containerType: Maybe<ContainerTypeEntity>): Promise<void> {
      if (this.isActive) return;

      const officeSettingApplicationService = this.$context.applications.get(officeSettingSymbol);
      const carTypeApplicationService = this.$context.applications.get(carTypeSymbol);
      const packingStylesApplicationService = this.$context.applications.get(packingStyleSymbol);

      const [officeSetting, carTypes, packingStyles] = await Promise.all([
        officeSettingApplicationService.get(),
        carTypeApplicationService.getAll(),
        packingStylesApplicationService.getAll(),
      ]);
      this.viewModel = new ContainerTypeForm(containerType, officeSetting, packingStyles, carTypes);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: ICloseEntityFormArgs<ContainerTypeEntity> = {
        entity: viewModel.containerType,
        createdEntity: viewModel.createdContainerType,
        updatedEntity: viewModel.updatedContainerType,
        removedEntity: undefined,
      };
      this.$context.panels.containerTypeFormPanel.closeFormEvent.emit(closeArgs);
    },
    async onOpen(args: IOpenEntityFormArgs<ContainerTypeEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    async onRegisterButtonClicked(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;
      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.containerTypeApplicationService.create({
          name: viewModel.name,
          packingStyleId: viewModel.packingStyleId!,
          unitName: viewModel.unitName,
          transformingCarTypeId: viewModel.transformingCarTypeId,
          status: SoftDeleteStatus.Active,
          reservationSiteEnabled: viewModel.reservationSiteEnabled,
        });

        this.viewModel.createdContainerType = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        if (viewModel.containerType === undefined) throw new Error('Impossible!');
        const containerType = viewModel.containerType;
        const entity = await this.containerTypeApplicationService.update({
          id: containerType.persistentId,
          name: viewModel.name,
          packingStyleId: viewModel.packingStyleId!,
          unitName: viewModel.unitName,
          transformingCarTypeId: viewModel.transformingCarTypeId,
          status: containerType.status,
          reservationSiteEnabled: viewModel.reservationSiteEnabled,
        });

        this.viewModel.updatedContainerType = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CONTAINER_TYPE,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('荷姿の登録完了');
      await this.close();
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.containerTypeFormPanel.updateDisplayedWidth(value);
    },
  },
});
