import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { UpdatePackingPlacementInput } from '~/graphql/graphQLServerApi';

export const packingPlacement$updateSymbol = Symbol('packingPlacement$updateSymbol');

export interface IUpdateApi {
  [packingPlacement$updateSymbol]: void;
  update(generationSiteId: string, packingPlacements: IUpdatePackingPlacementData[]): Promise<IPackingPlacementData[]>;
}

export type IUpdatePackingPlacementData = UpdatePackingPlacementInput;
