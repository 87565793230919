import { Car, CreateCarInput, UpdateCarInput } from '@/graphql/graphQLServerApi';

import { IDuplicatedNameError, IDuplicatedCodeError } from '~/framework/server-api/errors';

export const car$createSymbol = Symbol('car$createSymbol');

export const car$getAllSymbol = Symbol('getAll');

export const car$getByIdsSymbol = Symbol('getByIds');

export const car$updateSymbol = Symbol('car$updateSymbol');

export type CarApiTypes = {
  [car$getAllSymbol]: IGetAllApi;
  [car$getByIdsSymbol]: IGetByIdsApi;
  [car$createSymbol]: ICreateApi;
  [car$updateSymbol]: IUpdateApi;
};

export type ICarData = Car;

export interface ICreateApi {
  create(data: ICreateCarData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = Car | ICarMutationError;

export type ICreateCarData = CreateCarInput;

export interface IGetAllApi {
  getAll(): Promise<ICarData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<ICarData[]>;
}

export interface ICarMutationError {
  __typename: 'CarMutationError';
  errors: CarMutationErrorTypes[];
}

export type CarMutationErrorTypes = IDuplicatedNameError | IDuplicatedCodeError;

export interface IUpdateApi {
  update(data: IUpdateCarData): Promise<UpdateResultTypes>;
}

export type UpdateResultTypes = Car | ICarMutationError;

export type IUpdateCarData = UpdateCarInput;
