import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { IDriverAttendanceMap } from '~/framework/view-models/driverAttendanceMap';
import { ImpossiblePersistentId, UndefinedPersistentId } from '~/framework/constants';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { IDriverAttendanceError } from '~/components/common/r-driver-monthly-attendances-dialog/driverAttendanceError';

/**
 * 休みの情報含めて扱える様にするためのラッパー
 */
export interface IDriverAttendanceWrapper {
  /**
   * 対象の日
   */
  date: Date;

  /**
   * 休日かどうか
   */
  isHoliday: boolean;

  /**
   * 選択された月の日かどうか
   */
  isDateOfSelectedMonth: boolean;

  /**
   * 選択された日かどうか
   */
  isSelected: boolean;

  /**
   * 車がコンフリクトしているかどうか
   */
  isPrimaryCarConflicted: boolean;

  /**
   * その時点で認識しているその日の全ての勤怠を 車 ID→乗務員で引ける様にしたマップ
   * ただし自分自身の勤怠は含まない
   */
  carDriverMap: Map<PersistentId, DriverEntity[]>;

  /**
   * デフォルトの勤怠が生成されなかったかどうか
   */
  isDefaultDriverAttendanceNotGenerated: boolean;

  /**
   * 車がコンフリクトしているか、デフォルトの勤怠が生成されなかったで警告されるべきかどうか
   */
  isWarned: boolean;

  /**
   * 乗務員
   */
  driver: DriverEntity;

  /**
   * 勤怠テンプレ
   */
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[];

  /**
   * 全ての車
   */
  cars: CarEntity[];

  /**
   * 勤怠情報
   * 休みなら存在しない
   */
  driverAttendance: Maybe<DriverAttendanceEntity>;

  /**
   * 現状の driverAttendance から選択されているべきテンプレの ID を取得する
   * テンプレ名から復元するのが微妙なのだが。。。
   */
  driverAttendanceTemplateId: PersistentId;

  primaryCarId: Maybe<PersistentId>;
  forceRidePrimaryCar: boolean;
  regularWorkPeriodStartString: Maybe<string>;
  regularWorkPeriodEndString: Maybe<string>;
  restPeriodStartString: Maybe<string>;
  restPeriodEndString: Maybe<string>;
  overtimeWorkType: Maybe<OvertimeWorkType>;
  overtimeWorkableDuration: Maybe<Seconds>;

  /**
   * 勤怠マップが更新された時に呼ばれる
   * イベント経由にするとリークするので
   */
  onUpdateAllDriverAttendanceMap(): void;
}

export class DriverAttendanceWrapper implements IDriverAttendanceWrapper {
  private readonly allDriverAttendanceMap: IDriverAttendanceMap;
  date: Date;
  isHoliday: boolean;
  isSelected: boolean;
  error: Maybe<IDriverAttendanceError>;
  isDateOfSelectedMonth: boolean;
  isPrimaryCarConflicted!: boolean;
  carDriverMap!: Map<PersistentId, DriverEntity[]>;
  _isDefaultDriverAttendanceNotGenerated!: boolean;
  isWarned!: boolean;
  driver: DriverEntity;
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[];
  cars: CarEntity[];
  _driverAttendance: Maybe<DriverAttendanceEntity>;
  driverAttendanceTemplateId!: PersistentId;
  primaryCarId: Maybe<PersistentId>;
  forceRidePrimaryCar: boolean;
  regularWorkPeriodStartString: Maybe<string>;
  regularWorkPeriodEndString: Maybe<string>;
  restPeriodStartString: Maybe<string>;
  restPeriodEndString: Maybe<string>;
  overtimeWorkType: Maybe<OvertimeWorkType>;
  overtimeWorkableDuration: Maybe<Seconds>;

  get driverAttendance(): Maybe<DriverAttendanceEntity> {
    return this._driverAttendance;
  }

  set driverAttendance(value: Maybe<DriverAttendanceEntity>) {
    this._driverAttendance = value;
    this._isDefaultDriverAttendanceNotGenerated = false;
    this.updateWarningStatus();
    this.updateDriverAttendanceProperties();
  }

  get isDefaultDriverAttendanceNotGenerated(): boolean {
    return this._isDefaultDriverAttendanceNotGenerated;
  }

  set isDefaultDriverAttendanceNotGenerated(value: boolean) {
    this._isDefaultDriverAttendanceNotGenerated = value;
    this.updateWarningStatus();
  }

  constructor(
    date: Date,
    isDateOfSelectedMonth: boolean,
    isHoliday: boolean,
    isSelected: boolean,
    driver: DriverEntity,
    driverAttendanceTemplates: DriverAttendanceTemplateEntity[],
    cars: CarEntity[],
    allDriverAttendanceMap: IDriverAttendanceMap,
    error: Maybe<IDriverAttendanceError>
  ) {
    this.date = date;
    this.isDateOfSelectedMonth = isDateOfSelectedMonth;
    this.isHoliday = isHoliday;
    this.isSelected = isSelected;
    this.error = error;
    this.driver = driver;
    this.driverAttendanceTemplates = driverAttendanceTemplates;
    this.cars = cars;
    this.allDriverAttendanceMap = allDriverAttendanceMap;
    this.carDriverMap = new Map();
    this.driverAttendance = undefined;
    this.forceRidePrimaryCar = false;
  }

  onUpdateAllDriverAttendanceMap(): void {
    this.updateWarningStatus();
  }

  private updateWarningStatus(): void {
    this.isPrimaryCarConflicted =
      this.driverAttendance !== undefined
        ? this.allDriverAttendanceMap.isPrimaryCarConflicted(this.driverAttendance)
        : false;
    this.carDriverMap.clear();
    for (const driverAttendance of this.allDriverAttendanceMap.getAllDriverAttendanceOf(this.date)) {
      const carId = driverAttendance.primaryCar.persistentId;
      if (this.driver.persistentId !== driverAttendance.driver.persistentId) {
        if (!this.carDriverMap.has(carId)) this.carDriverMap.set(carId, []);
        this.carDriverMap.getOrError(carId).push(driverAttendance.driver);
      }
    }
    const hasError = this.error !== undefined;
    this.isWarned = this.isPrimaryCarConflicted || this.isDefaultDriverAttendanceNotGenerated || hasError;
  }

  /**
   * NOTE テンプレ名からテンプレマスターが引けないと「その他」として扱われる
   * @private
   */
  private updateDriverAttendanceProperties(): void {
    this.driverAttendanceTemplateId = UndefinedPersistentId;
    if (this.driverAttendance !== undefined) {
      for (const template of this.driverAttendanceTemplates) {
        if (this.driverAttendance.templateName === template.name) {
          this.driverAttendanceTemplateId = template.persistentId;
        }
      }
      // 勤怠が存在するのに ID が不定の時はテンプレが見つからなかった時
      if (this.driverAttendanceTemplateId === UndefinedPersistentId) {
        this.driverAttendanceTemplateId = ImpossiblePersistentId;
      }
    }
    this.primaryCarId = this.driverAttendance?.primaryCar.id;
    this.forceRidePrimaryCar = this.driverAttendance?.forceRidePrimaryCar ?? false;
    this.regularWorkPeriodStartString = this.driverAttendance?.regularWorkPeriodStart.toHhMm();
    this.regularWorkPeriodEndString = this.driverAttendance?.regularWorkPeriodEnd.toHhMm();
    this.restPeriodStartString = this.driverAttendance?.restPeriodStart?.toHhMm();
    this.restPeriodEndString = this.driverAttendance?.restPeriodEnd?.toHhMm();
    this.overtimeWorkType = this.driverAttendance?.overtimeWorkType;
    this.overtimeWorkableDuration = this.driverAttendance?.overtimeWorkableDuration;
  }
}
