import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  checkItem$getAllSymbol,
  ICreateCheckItemData,
  IUpdateCheckItemData,
  checkItem$createSymbol,
  checkItem$updateSymbol,
  checkItem$deleteSymbol,
  checkItem$updateSequenceSymbol,
  ICheckItemMutationError,
} from '~/framework/server-api/masters/checkItem';
import { CheckItemMapper } from '~/framework/domain/masters/check-item/checkItemMapper';
import { PersistentId } from '~/framework/typeAliases';
import { IDisposable } from '~/framework/core/disposable';
import { Ports } from '~/framework/core/ports';
export const checkItemServiceSymbol = Symbol('checkItemService');

export interface ICreatePresenter {
  create(entity: CheckItemEntity): void;
  errorOnCreate(error: ICheckItemMutationError): void;
}

export interface IUpdatePresenter {
  update(entity: CheckItemEntity): void;
  errorOnUpdate(error: ICheckItemMutationError): void;
}

export class CheckItemApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private readonly updatePort: Ports<IUpdatePresenter>;
  private readonly checkItemMapper: CheckItemMapper;

  constructor(serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.createPort = new Ports();
    this.updatePort = new Ports();
    this.checkItemMapper = new CheckItemMapper();
  }

  async getAll(): Promise<CheckItemEntity[]> {
    const checkItem$getAllApi = this.serverApis.get(checkItem$getAllSymbol);
    const data = await checkItem$getAllApi.getAll();
    return this.checkItemMapper.map(data);
  }

  async create(data: ICreateCheckItemData): Promise<void> {
    const checkItem$createApi = this.serverApis.get(checkItem$createSymbol);
    const result = await checkItem$createApi.create(data);

    if (result.__typename === 'CheckItem') {
      const createdCheckItemEntity = this.checkItemMapper.mapSingle(result);
      this.createPort.output('create', createdCheckItemEntity);
    } else if (result.__typename === 'CheckItemMutationError') {
      this.createPort.output('errorOnCreate', result);
    } else {
      throw new Error('AbnormalResultException to create check-item mutation');
    }
  }

  async update(id: string, data: IUpdateCheckItemData): Promise<void> {
    const checkItem$updateApi = this.serverApis.get(checkItem$updateSymbol);
    const result = await checkItem$updateApi.update(id, data);

    if (result.__typename === 'CheckItem') {
      const updatedCheckItemEntity = this.checkItemMapper.mapSingle(result);
      this.updatePort.output('update', updatedCheckItemEntity);
    } else if (result.__typename === 'CheckItemMutationError') {
      this.updatePort.output('errorOnUpdate', result);
    } else {
      throw new Error('AbnormalResultException to update check-item mutation');
    }
  }

  async delete(id: PersistentId): Promise<CheckItemEntity> {
    const checkItem$deleteApi = this.serverApis.get(checkItem$deleteSymbol);
    const result = await checkItem$deleteApi.delete(id);
    const deletedCheckItemEntity = this.checkItemMapper.mapSingle(result);
    this.updatePort.output('update', deletedCheckItemEntity);
    return deletedCheckItemEntity;
  }

  async updateSequence(data: string[]): Promise<string[]> {
    const checkItem$updateApi = this.serverApis.get(checkItem$updateSequenceSymbol);
    const result = await checkItem$updateApi.updateSequence(data);

    return result;
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  addUpdatePresenter(presenter: IUpdatePresenter): IDisposable {
    return this.updatePort.add(presenter);
  }
}
