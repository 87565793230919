import { CreateDefaultMultipleDriversAttendancesPayload } from '~/framework/server-api/typeAliases';
import { CreateDriverAttendanceInput, DriverAttendance, UpdateDriverAttendanceInput } from '~/graphql/graphQLServerApi';

export const driverAttendance$createSymbol = Symbol('driverAttendance$createSymbol');
export const driverAttendance$createDefaultSymbol = Symbol('driverAttendance$createDefaultSymbol');
export const driverAttendance$deleteSymbol = Symbol('driverAttendance$deleteSymbol');
export const driverAttendance$getByDateRangeSymbol = Symbol('driverAttendance$getByDateRangeSymbol');
export const driverAttendance$getByDateRangeOfAllDriversSymbol = Symbol(
  'driverAttendance$getByDateRangeOfAllDriversSymbol'
);
export const driverAttendance$updateSymbol = Symbol('driverAttendance$updateSymbol');

export type DriverAttendanceApiTypes = {
  [driverAttendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [driverAttendance$getByDateRangeOfAllDriversSymbol]: IGetByDateRangeOfAllDriversApi;
  [driverAttendance$createSymbol]: ICreateDriverAttendanceApi;
  [driverAttendance$updateSymbol]: IUpdateApi;
  [driverAttendance$deleteSymbol]: IDeleteApi;
  [driverAttendance$createDefaultSymbol]: ICreateDefaultDriverAttendanceApi;
};

export interface ICreateDriverAttendanceApi {
  create(data: ICreateDriverAttendanceData[]): Promise<string[]>;
}

export interface ICreateDefaultDriverAttendanceApi {
  createDefault(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
  createDefaultMultiple(
    driverIds: string[],
    start: Date,
    end: Date
  ): Promise<CreateDefaultMultipleDriversAttendancesPayload>;
}

export interface IDeleteApi {
  delete(ids: string[]): Promise<string[]>;
}

export type IDriverAttendanceData = DriverAttendance;

export type ICreateDriverAttendanceData = CreateDriverAttendanceInput;

export type IUpdateDriverAttendanceData = UpdateDriverAttendanceInput;

export interface IGetByDateRangeApi {
  getByDateRange(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IGetByDateRangeOfAllDriversApi {
  getByDateRangeOfAllDrivers(start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateDriverAttendanceData[]): Promise<string[]>;
}
