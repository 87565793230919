import { PersistentId } from '~/framework/typeAliases';
import { UserSettingEntity } from '~/framework/domain/user-setting/userSettingEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { CarAttendanceEntity } from '~/framework/domain/masters/car-attendance/carAttendanceEntity';
import {
  carAttendance$getByDateRangeSymbol,
  carAttendance$updateSymbol,
  IUpdateCarAttendanceData,
} from '~/framework/server-api/masters/carAttendance';

import { CarAttendanceMapper } from '~/framework/domain/masters/car-attendance/carAttendanceMapper';

export const carAttendanceSymbol = Symbol('carAttendance');

export interface IPageData {
  userSetting: UserSettingEntity;
  holidayRule: HolidayRuleEntity;
}

export class CarAttendanceApplicationService {
  private readonly serverApis: ServerApiManager;
  private carAttendanceMapper: CarAttendanceMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.carAttendanceMapper = new CarAttendanceMapper(store.masters.carAttendance, store.masters.attendance);
  }

  async getCarsAttendanceByDateRange(start: Date, end: Date, carIds?: Array<PersistentId>) {
    const getByDateRangeApi = this.serverApis.get(carAttendance$getByDateRangeSymbol);
    const carAttendanceData = await getByDateRangeApi.getByDateRange(start, end, carIds);
    return this.carAttendanceMapper.map(carAttendanceData);
  }

  async updateCarAttendance(updateData: IUpdateCarAttendanceData): Promise<CarAttendanceEntity> {
    const updateCarAttendanceApi = this.serverApis.get(carAttendance$updateSymbol);
    const carAttendanceData = await updateCarAttendanceApi.update(updateData);
    return this.carAttendanceMapper.mapSingle(carAttendanceData);
  }
}
