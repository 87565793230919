import { Middleware } from '@nuxt/types';
import { redirectToLogin } from '~/nuxt';
import { authenticationSymbol } from '~/framework/application/authentication/authenticationApplicationService';
import { RinEventNames } from '~/framework/services/rin-events/rinEventParams';
import { UnauthenticatedException } from '~/framework/server-api/authentication/session';

const loginMiddleware: Middleware = async (context) => {
  const systemContext = context.app.$context;
  if (systemContext.hasLoggedIn === false) {
    // NOTE: ログアウト状態など、 currentSession が取得できない場合は throw せずログイン画面にリダイレクトさせる
    try {
      const service = systemContext.applications.get(authenticationSymbol);
      const session = await service.getCurrentSession();
      systemContext.hasLoggedIn = true;
      systemContext.currentSession = session;
      context.app.$rinGtm.push(RinEventNames.LOGIN);
    } catch (e: unknown) {
      if (e instanceof UnauthenticatedException) {
        redirectToLogin(context);
      } else {
        throw e;
      }
    }
  }
};

export default loginMiddleware;
