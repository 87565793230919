import _ from 'lodash';
import {
  BooleanValue,
  ContainerTypeTaskTypeStatus,
  ContainerTypeTaskTypeStatusValue,
  IntegerValue,
} from '~/framework/domain/typeAliases';

import { zip } from '~/framework/core/array';
import { Maybe, Minutes, PersistentId } from '~/framework/typeAliases';
import { PackingStyleTaskTypeDefaultEntity } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { getOrInit } from '~/framework/core/map';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { IUpdatePackingStyleTaskTypeDefaultData } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';
import { IUpdateContainerTypeTaskTypeData } from '~/framework/server-api/masters/containerTypeTaskType';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

/**
 * デフォルト値を示すもの
 */
export const defaultValue: unique symbol = Symbol('means the value is default value');

/**
 * 固定値を示すもの
 */
export const constValue: unique symbol = Symbol('means the value is const value');

/**
 * デフォルト値があり得る型
 */
export type MaybeDefault<T> = T | typeof defaultValue;

/**
 * デフォルト値なのかどうかを返す
 * @param value
 */
export const isDefaultValue = <T>(value: MaybeDefault<T>): value is typeof defaultValue => {
  return value === defaultValue;
};

/**
 * デフォルト値以外を別の値にマップする
 * @param value
 * @param func
 */
export const mapExceptForDefault = <Input, Output>(value: MaybeDefault<Input>, func: (value: Input) => Output) => {
  return isDefaultValue(value) ? value : func(value);
};

/**
 * デフォルト値を undefined にマップする
 * @param value
 */
export const mapDefaultToUndefined = <Input>(value: MaybeDefault<Input>): Maybe<Input> => {
  return isDefaultValue(value) ? undefined : value;
};

export interface IPackingStyle {
  /**
   * 荷姿コードの ID
   */
  id: string;

  /**
   * タスクのタイプ
   */
  tasks: TaskTypeEntity[];

  /**
   * 荷姿コード名
   */
  name: string;

  /**
   * 利用状態
   */
  status: ContainerTypeTaskTypeStatus;

  /**
   * 作業時間
   */
  durations: Minutes[];

  /**
   * 個数による時間の変動
   */
  isProportionalToCount: boolean;

  /**
   * この荷姿に紐づくコンテナがある場合
   * コンテナという言い方は本来的には正しくないがひとまず
   * 本当は荷姿コードに紐づく実際の荷姿という意味
   */
  containers: IContainer[];

  isEqualTo(another: IPackingStyle): boolean;
}

/**
 * duration は type と value をクラスにして表現するかは迷ったのだが、
 * 処理が複雑になりそうだったのでやめた
 */
export interface IContainer {
  /**
   * コンテナタイプの ID
   */
  id: string;

  /**
   * タスクのタイプ
   */
  tasks: TaskTypeEntity[];

  /**
   * コンテナ名
   */
  name: string;

  /**
   * 利用状態
   */
  status: MaybeDefault<ContainerTypeTaskTypeStatus>;

  /**
   * 作業時間
   */
  durations: MaybeDefault<Minutes>[];

  /**
   * 個数による時間の変動
   */
  isProportionalToCount: MaybeDefault<boolean>;

  isEqualTo(another: IContainer): boolean;
}

export interface IFormValues {
  packingStyles: IPackingStyle[];

  isEqualTo(another: IFormValues): boolean;
}

export class FormValuesFactory {
  private readonly containerTypes: ContainerTypeEntity[];
  private readonly containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  private readonly packingStyles: PackingStyleEntity[];
  private readonly packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[];

  constructor(
    containerTypes: ContainerTypeEntity[],
    containerTypeTaskTypes: ContainerTypeTaskTypeEntity[],
    packingStyles: PackingStyleEntity[],
    packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[]
  ) {
    this.containerTypes = containerTypes;
    this.containerTypeTaskTypes = containerTypeTaskTypes;
    this.packingStyles = packingStyles;
    this.packingStyleTaskTypeDefaults = packingStyleTaskTypeDefaults;
  }

  build(taskType: TaskTypeEntity): IFormValues {
    return new FormValues(this.buildPackingStyles([taskType]));
  }

  private buildPackingStyles(taskTypes: TaskTypeEntity[]): IPackingStyle[] {
    // packingStyleId:taskType でデフォルト設定を引けるマップを作る
    type PackingStyleId = PersistentId;
    type DefaultValueMapKeyType = string;
    const defaultValueMap: Map<DefaultValueMapKeyType, PackingStyleTaskTypeDefaultEntity> = new Map();
    for (const packingStyleTaskTypeDefault of this.packingStyleTaskTypeDefaults) {
      const taskTypeKey = packingStyleTaskTypeDefault.taskType.id;
      const key = `${packingStyleTaskTypeDefault.packingStyleId}:${taskTypeKey}` as const;
      defaultValueMap.set(key, packingStyleTaskTypeDefault);
    }

    // 荷姿に紐付いたコンテナのリスト
    const containerTypeMap: Map<PackingStyleId, ContainerTypeEntity[]> = new Map();
    for (const containerType of this.containerTypes) {
      const containerTypes = getOrInit(containerTypeMap, containerType.packingStyle.id, []);
      containerTypes.push(containerType);
    }

    const packingStyles: IPackingStyle[] = [];
    for (const packingStyle of this.packingStyles.sort((a, b) => a.code.localeCompare(b.code))) {
      const containerTypes = containerTypeMap.get(packingStyle.id);
      if (containerTypes === undefined) continue;
      const containers = this.buildContainers(taskTypes, containerTypes);
      const defaults: [ContainerTypeTaskTypeStatus, number, boolean][] = taskTypes.map((taskType) => {
        const taskTypeKey = taskType.id;
        const taskKey = `${packingStyle.id}:${taskTypeKey}` as const;
        const defaultValue = defaultValueMap.getOrError(taskKey);
        const duration: Minutes = Math.round((defaultValue.duration * 100) / 60) / 100; // こういうの値クラスだともっと楽にできそう
        return [defaultValue.status, duration, defaultValue.isProportionalToCount];
      });
      packingStyles.push(
        new PackingStyle(
          packingStyle.id,
          taskTypes,
          packingStyle.name,
          defaults[0][0], // 先頭のものを代表値として扱う
          defaults.map((item) => item[1]),
          defaults[0][2], // 先頭のものを代表値として扱う
          containers
        )
      );
    }
    return packingStyles;
  }

  private buildContainers(taskTypes: TaskTypeEntity[], containerTypes: ContainerTypeEntity[]): IContainer[] {
    // containerTypeId:taskType で作業を引けるマップを作る
    type ContainerTypeTaskTypeMapKeyType = string;
    const containerTypeTaskTypeMap: Map<ContainerTypeTaskTypeMapKeyType, ContainerTypeTaskTypeEntity> = new Map();
    for (const containerTypeTaskType of this.containerTypeTaskTypes) {
      const taskTypeKey = containerTypeTaskType.taskType.id;
      const key = `${containerTypeTaskType.containerType.id}:${taskTypeKey}` as const;
      containerTypeTaskTypeMap.set(key, containerTypeTaskType);
    }

    const containers: IContainer[] = [];
    for (const containerType of containerTypes.sort((a, b) => a.name.localeCompare(b.name))) {
      const containerValues: [
        status: MaybeDefault<ContainerTypeTaskTypeStatus>,
        durations: MaybeDefault<number>,
        isProportionalToCount: MaybeDefault<boolean>
      ][] = taskTypes.map((taskType) => {
        const taskTypeKey = taskType.id;
        const taskKey = `${containerType.id}:${taskTypeKey}` as const;
        const task = containerTypeTaskTypeMap.getOrError(taskKey);
        const status = this.getContainerTypeTaskTypeStatusOrDefaultValue(task.status);
        const isProportionalToCount = this.getBooleanOrDefaultValue(task.isProportionalToCount);
        const duration: MaybeDefault<Minutes> = mapExceptForDefault(
          this.getIntegerOrDefaultValue(task.duration),
          (value) => Math.round((value * 100) / 60) / 100
        );
        return [status, duration, isProportionalToCount];
      });

      containers.push(
        new Container(
          containerType.id,
          taskTypes,
          containerType.name,
          containerValues[0][0], // 先頭のものを代表値として扱う
          containerValues.map((item) => item[1]),
          containerValues[0][2] // 先頭のものを代表値として扱う
        )
      );
    }
    return containers;
  }

  private getIntegerOrDefaultValue(value: IntegerValue): MaybeDefault<number> {
    return value.__typename === 'IntegerConstValue' ? value.value : defaultValue;
  }

  private getBooleanOrDefaultValue(value: BooleanValue): MaybeDefault<boolean> {
    return value.__typename === 'BooleanConstValue' ? value.value : defaultValue;
  }

  private getContainerTypeTaskTypeStatusOrDefaultValue(
    value: ContainerTypeTaskTypeStatusValue
  ): MaybeDefault<ContainerTypeTaskTypeStatus> {
    return value.__typename === 'ContainerTypeTaskTypeStatusConstValue' ? value.value : defaultValue;
  }
}

export class ApiDataFactory {
  private readonly containerTypes: ContainerTypeEntity[];
  private readonly containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  private readonly packingStyles: PackingStyleEntity[];
  private readonly packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[];

  constructor(
    containerTypes: ContainerTypeEntity[],
    containerTypeTaskTypes: ContainerTypeTaskTypeEntity[],
    packingStyles: PackingStyleEntity[],
    packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[]
  ) {
    this.containerTypes = containerTypes;
    this.containerTypeTaskTypes = containerTypeTaskTypes;
    this.packingStyles = packingStyles;
    this.packingStyleTaskTypeDefaults = packingStyleTaskTypeDefaults;
  }

  buildUpdateData(
    packingStyles: IPackingStyle[]
  ): [IUpdatePackingStyleTaskTypeDefaultData[], IUpdateContainerTypeTaskTypeData[]] {
    // packingStyleId:taskType でデフォルト設定を引けるマップを作る
    type DefaultValueMapKeyType = string;
    const defaultValueMap: Map<DefaultValueMapKeyType, PackingStyleTaskTypeDefaultEntity> = new Map();
    for (const packingStyleTaskTypeDefault of this.packingStyleTaskTypeDefaults) {
      const taskTypeKey = packingStyleTaskTypeDefault.taskType.id;
      const key = `${packingStyleTaskTypeDefault.packingStyleId}:${taskTypeKey}` as const;
      defaultValueMap.set(key, packingStyleTaskTypeDefault);
    }

    // containerTypeId:taskType で作業を引けるマップを作る
    type ContainerTypeTaskTypeMapKeyType = string;
    const containerTypeTaskTypeMap: Map<ContainerTypeTaskTypeMapKeyType, ContainerTypeTaskTypeEntity> = new Map();
    for (const containerTypeTaskType of this.containerTypeTaskTypes) {
      const taskTypeKey = containerTypeTaskType.taskType.id;
      const key = `${containerTypeTaskType.containerType.id}:${taskTypeKey}` as const;
      containerTypeTaskTypeMap.set(key, containerTypeTaskType);
    }

    const packingStyleTaskTypeDefaults: IUpdatePackingStyleTaskTypeDefaultData[] = [];
    const containerTypeTaskTypes: IUpdateContainerTypeTaskTypeData[] = [];
    for (const packingStyle of packingStyles) {
      for (const [index, task] of packingStyle.tasks.entries()) {
        const taskTypeKey = task.id;
        const key = `${packingStyle.id}:${taskTypeKey}` as const;
        const packingStyleTaskTypeDefault = defaultValueMap.getOrError(key);
        packingStyleTaskTypeDefaults.push({
          id: packingStyleTaskTypeDefault.id,
          status: packingStyle.status,
          isProportionalToCount: packingStyle.isProportionalToCount,
          duration: Math.round(packingStyle.durations[index] * 60),
        });
      }

      for (const container of packingStyle.containers) {
        for (const [index, task] of container.tasks.entries()) {
          const taskTypeKey = task.id;
          const key = `${container.id}:${taskTypeKey}` as const;
          const containerTypeTaskType = containerTypeTaskTypeMap.getOrError(key);
          containerTypeTaskTypes.push({
            id: containerTypeTaskType.id,
            values: {
              status: mapDefaultToUndefined(container.status),
              isProportionalToCount: mapDefaultToUndefined(container.isProportionalToCount),
              duration: mapDefaultToUndefined(
                mapExceptForDefault(container.durations[index], (value) => Math.round(value * 60))
              ),
            },
          });
        }
      }
    }
    return [packingStyleTaskTypeDefaults, containerTypeTaskTypes];
  }
}

export class FormValues implements IFormValues {
  packingStyles: IPackingStyle[];

  constructor(packingStyleTaskTypeDefaults: IPackingStyle[]) {
    this.packingStyles = packingStyleTaskTypeDefaults;
  }

  static clone(original: IFormValues): IFormValues {
    const packingStyleTaskTypeDefaults = original.packingStyles.map((item) => PackingStyle.clone(item));
    return new FormValues(packingStyleTaskTypeDefaults);
  }

  isEqualTo(another: IFormValues): boolean {
    // 後でデバッグしやすい様にやや冗長なやり方をしている
    const equalityMap: Map<keyof this, boolean> = new Map();
    const isPackingStyleTaskTypeDefaultsEqual =
      this.packingStyles.length === another.packingStyles.length &&
      zip(this.packingStyles, another.packingStyles).every(
        (pair) => pair[0] !== undefined && pair[1] !== undefined && pair[0].isEqualTo(pair[1])
      );
    equalityMap.set('packingStyles', isPackingStyleTaskTypeDefaultsEqual);

    return Array.from(equalityMap.values()).every((equality) => equality === true);
  }
}

export class PackingStyle implements IPackingStyle {
  id: string;
  tasks: TaskTypeEntity[];
  name: string;
  status: ContainerTypeTaskTypeStatus;
  durations: number[];
  isProportionalToCount: boolean;
  readonly containers: IContainer[];

  constructor(
    id: string,
    tasks: TaskTypeEntity[],
    name: string,
    status: ContainerTypeTaskTypeStatus,
    durations: number[],
    isProportionalToCount: boolean,
    containers: IContainer[]
  ) {
    this.id = id;
    this.tasks = tasks;
    this.name = name;
    this.status = status;
    this.durations = durations;
    this.isProportionalToCount = isProportionalToCount;
    this.containers = containers;
  }

  static clone(original: IPackingStyle): IPackingStyle {
    const containers = original.containers.map((item) => Container.clone(item));
    return new PackingStyle(
      original.id,
      [...original.tasks],
      original.name,
      original.status,
      [...original.durations],
      original.isProportionalToCount,
      containers
    );
  }

  isEqualTo(another: IPackingStyle): boolean {
    const equalityMap: Map<keyof this, boolean> = new Map();
    const isContainersEqual =
      this.containers.length === another.containers.length &&
      zip(this.containers, another.containers).every(
        (pair) => pair[0] !== undefined && pair[1] !== undefined && pair[0].isEqualTo(pair[1])
      );
    equalityMap.set('status', this.status === another.status);
    equalityMap.set('durations', _.isEqual(this.durations, another.durations));
    equalityMap.set('isProportionalToCount', this.isProportionalToCount === another.isProportionalToCount);
    equalityMap.set('containers', isContainersEqual);
    return Array.from(equalityMap.values()).every((equality) => equality === true);
  }
}

export class Container implements IContainer {
  id: string;
  tasks: TaskTypeEntity[];
  name: string;
  status: MaybeDefault<ContainerTypeTaskTypeStatus>;
  durations: MaybeDefault<number>[];
  isProportionalToCount: MaybeDefault<boolean>;

  constructor(
    id: string,
    tasks: TaskTypeEntity[],
    name: string,
    status: MaybeDefault<ContainerTypeTaskTypeStatus>,
    durations: MaybeDefault<number>[],
    isProportionalToCount: MaybeDefault<boolean>
  ) {
    this.id = id;
    this.tasks = tasks;
    this.name = name;
    this.status = status;
    this.durations = durations;
    this.isProportionalToCount = isProportionalToCount;
  }

  static clone(original: IContainer): IContainer {
    return new Container(
      original.id,
      [...original.tasks],
      original.name,
      original.status,
      [...original.durations],
      original.isProportionalToCount
    );
  }

  isEqualTo(another: IContainer): boolean {
    // 厳密に全てをチェックしている訳ではないが可変なもののみチェックしておけば基本的にはいいはずなので
    const equalityMap: Map<keyof this, boolean> = new Map();
    equalityMap.set('id', this.id === another.id);
    equalityMap.set('status', this.status === another.status);
    equalityMap.set('durations', _.isEqual(this.durations, another.durations));
    equalityMap.set('isProportionalToCount', _.isEqual(this.isProportionalToCount, another.isProportionalToCount));
    return Array.from(equalityMap.values()).every((equality) => equality === true);
  }
}
