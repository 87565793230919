import { ContainerTypeTaskTypeApiTypes } from '~/framework/server-api/masters/containerTypeTaskType';
import { ContainerType, CreateContainerTypeInput, UpdateContainerTypeInput } from '~/graphql/graphQLServerApi';

export const containerType$createSymbol = Symbol('containerType$createSymbol');

export const containerType$getAllSymbol = Symbol('containerType$getAllSymbol');

export const containerType$getByIdsSymbol = Symbol('containerType$getByIdsSymbol');

export const containerType$updateSymbol = Symbol('containerType$updateSymbol');

export type ContainerTypeApiTypes = {
  [containerType$getAllSymbol]: IGetAllApi;
  [containerType$getByIdsSymbol]: IGetByIdsApi;
  [containerType$createSymbol]: ICreateApi;
  [containerType$updateSymbol]: IUpdateApi;
} & ContainerTypeTaskTypeApiTypes;

export interface ICreateApi {
  create(data: ICreateContainerTypeData): Promise<IContainerTypeData>;
}

export type ICreateContainerTypeData = CreateContainerTypeInput;

export type IContainerTypeData = ContainerType;

export interface IGetAllApi {
  getAll(): Promise<IContainerTypeData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IContainerTypeData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateContainerTypeData): Promise<IContainerTypeData>;
}

export type IUpdateContainerTypeData = UpdateContainerTypeInput;
