
import Vue from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { IllegalStateException } from '~/framework/core/exception';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import {
  PackingPlacementApplicationService,
  packingPlacementSymbol,
} from '~/framework/application/packing-placement/packingPlacementApplicationService';
import {
  ICloseData,
  IOpenData,
  PackingPlacementsAtGenerationSite,
} from '~/framework/view-models/panels/packingPlacementsFormPanel';
import {
  ApiDataFactory,
  FormValues,
  FormValuesFactory,
  IFormValues,
} from '~/components/panels/packing-placement/r-packing-placements-form/formValues';
import { CloseGenericObjectArgs, OpenGenericObjectArgs } from '~/framework/view-models/panels/genericObjectPanel';
import { unwrap } from '~/framework/core/value';
import { dateToYyMdDaysOfWeek } from '~/framework/services/date/date';
import RPackingPlacementsInput from '~/components/panels/packing-placement/r-packing-placements-form/RPackingPlacementsInput.vue';
import RDescriptionOfGenerationSite from '~/components/panels/packing-placement/r-packing-placements-form/RDescriptionOfGenerationSite.vue';
import { IRRadioGroupItem } from '~/components/common/r-radio-group/rRadioGroup';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { containerTypeSymbol } from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { packingStyleSymbol } from '~/framework/application/masters/packingStyleApplicationService';

enum FormMode {
  Register, // 使われてない
  Edit,
}

type Masters = {
  containerTypes: ContainerTypeEntity[];
  packingStyles: PackingStyleEntity[];
};

type DataType = {
  packingPlacementApplicationService: PackingPlacementApplicationService;
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  title: string;
  isFormValid: boolean;
  formValues: Maybe<IFormValues>;
  masters: Maybe<Masters>;
  formMode: FormMode;
  initialFormValues: Maybe<IFormValues>;
  editingData: Maybe<IOpenData>;
  updatedData: Maybe<IOpenData>;
  isRegistering: boolean;
  isRegisterButtonDisabled: boolean;
  requireLastAllocatedAt: boolean;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RPackingPlacementsForm',
  components: {
    RPackingPlacementsInput,
    RDescriptionOfGenerationSite,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const packingPlacementApplicationService = this.$context.applications.get(packingPlacementSymbol);
    return {
      packingPlacementApplicationService,
      isActive: false,
      isCloseConfirmDialogActive: false,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      FormMode,
      formValues: undefined,
      masters: undefined,
      initialFormValues: undefined,
      title: '',
      formMode: FormMode.Edit,
      editingData: undefined,
      updatedData: undefined,
      isFormValid: true,
      isRegistering: false,
      isRegisterButtonDisabled: false,
      requireLastAllocatedAt: false,
    };
  },
  computed: {
    hasPlacedPackings(): boolean {
      return this.editingData?.data.lastAllocatedAt !== undefined;
    },
    lastAllocatedAt(): Maybe<Date> {
      return this.editingData?.data.lastAllocatedAt;
    },
    descriptionComponent(): Maybe<string> {
      return this.editingData?.data.acceptComponentProcessor(this);
    },
    updateLastAllocatedAtSelectionItems(): IRRadioGroupItem<boolean>[] {
      if (this.hasPlacedPackings) {
        return [
          { label: '更新しない', value: false },
          { label: '更新する', value: true },
        ];
      } else {
        return [
          { label: '指定しない', value: false },
          { label: '指定する', value: true },
        ];
      }
    },
    updateLastAllocatedAtSelectionLabel(): string {
      return `${this.hasPlacedPackings ? '最終設置日の更新' : '最終設置日の指定'}*`;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.PACKING_PLACEMENT;
    },
  },
  watch: {
    isFormValid(value: boolean): void {
      this.isRegisterButtonDisabled = !value;
    },
    formValues: {
      deep: true,
      handler(value: Maybe<IFormValues>) {
        this.requireLastAllocatedAt = value?.requireLastAllocatedAt() || false;
      },
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.packingPlacementsFormPanel.openEvent.on(this.onOpen);
    this.$context.panels.packingPlacementsFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    dateToYyMdDaysOfWeek,
    async open(openData: IOpenData): Promise<void> {
      if (this.isActive) return;
      await this.initializeForm(openData);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: CloseGenericObjectArgs<IOpenData, ICloseData> = {
        openData: this.editingData,
        closeData: this.updatedData,
      };
      this.$context.panels.packingPlacementsFormPanel.closeEvent.emit(closeArgs);
    },
    async onOpen(args: OpenGenericObjectArgs<IOpenData>): Promise<void> {
      if (args.openData === undefined) throw new IllegalStateException(`data is required`);
      await this.open(args.openData);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible`);
      if (this.initialFormValues === undefined) throw new IllegalStateException(`Impossible`);
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.formValues.isEqualTo(this.initialFormValues) === false) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onChangeSetLastAllocatedAt(value: boolean): void {
      if (this.formValues === undefined) return;
      if (value === false) {
        this.formValues.lastAllocatedAt = undefined;
      } else {
        this.formValues.lastAllocatedAt = this.lastAllocatedAt || new Date();
      }
    },
    async initializeForm(openData: IOpenData): Promise<void> {
      const containerTypeApplicationService = this.$context.applications.get(containerTypeSymbol);
      const packingStyleApplicationService = this.$context.applications.get(packingStyleSymbol);
      const [containerTypes, packingStyles] = await Promise.all([
        containerTypeApplicationService.getAll(),
        packingStyleApplicationService.getAll(),
      ]);
      this.masters = { containerTypes, packingStyles };
      this.editingData = openData;
      this.updatedData = undefined;
      this.initialFormValues = new FormValuesFactory(this.masters.packingStyles, this.masters.containerTypes).build(
        openData.data
      );
      this.formValues = FormValues.clone(this.initialFormValues);
      this.formMode = FormMode.Edit;
      this.title = '排出場から未返却の荷姿の編集';
    },
    async onRegisterButtonClicked(): Promise<void> {
      if (this.formMode === FormMode.Edit) {
        this.isRegistering = true;
        const editingData = unwrap(this.editingData);
        await editingData.data.acceptUpdateProcessor(this);
        this.updatedData = editingData;

        this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
          [AdditionalInfoKeys.TARGET]: FormTargetParams.PACKING_PLACEMENT,
          [AdditionalInfoKeys.MODE]: FormModeParams.EDIT,
        });

        this.$context.snackbar.success('排出場から未返却の荷姿の編集完了');

        this.isRegistering = false;
      } else {
        throw new IllegalStateException(`Impossible formMode: ${this.formMode}`);
      }
      await this.close();
    },
    /**
     * IUpdateDataProcessor を実装している
     * 排出場以外にも対応する可能性があるので visitor パターンで実装している
     * @param packingPlacements
     */
    async updatePackingPlacementsAtGenerationSite(packingPlacements: PackingPlacementsAtGenerationSite): Promise<void> {
      const formValues = unwrap(this.formValues);
      await this.packingPlacementApplicationService.update(
        packingPlacements.generationSite.id,
        new ApiDataFactory().buildUpdateData(formValues, this.lastAllocatedAt || new Date(), formValues.lastAllocatedAt)
      );
    },
    /**
     * IComponentProcessor を実装している
     * 排出場以外にも対応する可能性があるので visitor パターンで実装している
     */
    getComponentNameOfGenerationSite(): string {
      return 'r-description-of-generation-site';
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.carTypeFormPanel.updateDisplayedWidth(value);
    },
  },
});
