import { StoredMapperBase } from '~/framework/core/mapper';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { BaseSiteMapper } from '~/framework/domain/masters/base-site/baseSiteMapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { ICarData } from '~/framework/server-api/masters/car';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';

export class CarMapper extends StoredMapperBase<ICarData, CarEntity> {
  protected store: CarStore;
  private baseSiteMapper: BaseSiteMapper;

  constructor(store: CarStore, baseSiteStore: BaseSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.baseSiteMapper = new BaseSiteMapper(baseSiteStore, userStore);
  }

  protected instantiateWithData(data: ICarData): CarEntity {
    const carTypeEntity = new SimpleCarTypeEntity(data.simpleCarType);
    const baseSiteEntity = this.baseSiteMapper.mapSingle(data.baseSite);

    return new CarEntity(data, carTypeEntity, baseSiteEntity);
  }

  protected updateWithData(data: ICarData, entity: CarEntity): void {
    const carTypeEntity = new SimpleCarTypeEntity(data.simpleCarType);
    const baseSiteEntity = this.baseSiteMapper.mapSingle(data.baseSite);
    entity.update(data, carTypeEntity, baseSiteEntity);
  }
}
