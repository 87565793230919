import { CancelReservationInput, CancelReservationMutationResult } from '~/graphql/graphQLServerApi';

export const reservation$cancelReservationSymbol = Symbol('reservation$cancelReservationSymbol');

export interface ICancelReservationApi {
  [reservation$cancelReservationSymbol]: void;
  cancelReservation(data: ICancelReservationData): Promise<ICancelReservationMutationResultData>;
}

export type ICancelReservationData = CancelReservationInput;

export type ICancelReservationMutationResultData = CancelReservationMutationResult;
