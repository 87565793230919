import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { ClientStore } from '~/framework/domain/masters/client/clientStore';
import { IClientData } from '~/framework/server-api/masters/client';

export class ClientMapper extends StoredMapperBase<IClientData, ClientEntity> {
  protected store: ClientStore;
  private userMapper: UserMapper;

  constructor(store: ClientStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IClientData): ClientEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new ClientEntity(data, createdBy, updatedBy);
  }

  protected updateWithData(data: IClientData, entity: ClientEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    entity.update(data, createdBy, updatedBy);
  }
}
