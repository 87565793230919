import { Order, PostponeOrderInput } from '~/graphql/graphQLServerApi';
export const order$postponeOrdersSymbol = Symbol('order$postponeOrdersSymbol');

export interface IPostponeOrdersApi {
  [order$postponeOrdersSymbol]: void;
  postponeOrders(data: IPostponeData[]): Promise<PostponeOrderResultTypes[]>;
}

export type IPostponeData = PostponeOrderInput;

export type PostponeOrderResultTypes = Order | IPostponeOrderMutationError;

export interface IPostponeOrderMutationError {
  __typename: 'PostponeOrderMutationError';
  errors: PostponeOrderMutationErrorTypes[];
}

export interface INoCandidateDatesError {
  __typename: 'NoCandidateDatesError';
}

// 今の所1個だがエラーの種類が増えたらここに足す
export type PostponeOrderMutationErrorTypes = INoCandidateDatesError;
