import { IResultOrderData } from '~/framework/server-api/schedule/order/order';
import { CreateOrderInput } from '~/graphql/graphQLServerApi';

export const order$createOrdersSymbol = Symbol('order$createOrdersSymbol');

export interface ICreateOrdersApi {
  [order$createOrdersSymbol]: void;
  createOrders(data: ICreateOrderData[]): Promise<IResultOrderData[]>;
}

export type ICreateOrderData = CreateOrderInput;
