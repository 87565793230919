import { CollectablePeriodTemplateMapper } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateMapper';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { GenerationSiteStore } from '~/framework/domain/masters/generation-site/generationSiteStore';
import { ClientMapper } from '~/framework/domain/masters/client/clientMapper';
import { ClientStore } from '~/framework/domain/masters/client/clientStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { DisposalSiteMapper } from '~/framework/domain/masters/disposal-site/disposalSiteMapper';
import { DisposalSiteStore } from '~/framework/domain/masters/disposal-site/disposalSiteStore';
import { CarMapper } from '~/framework/domain/masters/car/carMapper';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';
import { DriverMapper } from '../driver/driverMapper';
import { DriverStore } from '../driver/driverStore';
import { DriverAttendanceTemplateStore } from '../driver-attendance-template/driverAttendanceTemplateStore';
import { SimpleCarTypeEntity } from '../car-type/carTypeEntity';

export class GenerationSiteMapper extends StoredMapperBase<IGenerationSiteData, GenerationSiteEntity> {
  protected store: GenerationSiteStore;
  private clientMapper: ClientMapper;
  private driverMapper: DriverMapper;
  private disposalSiteMapper: DisposalSiteMapper;
  private carMapper: CarMapper;
  private userMapper: UserMapper;
  private collectablePeriodTemplateMapper: CollectablePeriodTemplateMapper;

  constructor(
    store: GenerationSiteStore,
    clientStore: ClientStore,
    userStore: UserStore,
    disposalSiteStore: DisposalSiteStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverStore: DriverStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new ClientMapper(clientStore, userStore);
    this.driverMapper = new DriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.disposalSiteMapper = new DisposalSiteMapper(disposalSiteStore, clientStore, userStore);
    this.carMapper = new CarMapper(carStore, baseSiteStore, userStore);
    this.userMapper = new UserMapper(userStore);
    this.collectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(collectablePeriodTemplateStore);
  }

  protected instantiateWithData(data: IGenerationSiteData): GenerationSiteEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    // 単数の場合のみ実装されている
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;
    const defaultAssignableCars = this.carMapper.map(data.defaultAssignableCars);
    const defaultAssignableCarTypes = data.defaultAssignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    return new GenerationSiteEntity(
      data,
      client,
      bannedDrivers,
      defaultAssignedDriver,
      defaultAssignedDisposalSite,
      defaultAssignableCars,
      defaultAssignableCarTypes,
      createdBy,
      defaultCollectablePeriodTemplate
    );
  }

  protected updateWithData(data: IGenerationSiteData, entity: GenerationSiteEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    // 単数の場合のみ実装されている
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;
    const defaultAssignableCars = this.carMapper.map(data.defaultAssignableCars);
    const defaultAssignableCarTypes = data.defaultAssignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    entity.update(
      data,
      client,
      bannedDrivers,
      defaultAssignedDriver,
      defaultAssignedDisposalSite,
      defaultAssignableCars,
      defaultAssignableCarTypes,
      createdBy,
      defaultCollectablePeriodTemplate
    );
  }
}
