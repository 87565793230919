import _ from 'lodash';
import { IGenerationSiteTaskItem } from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import {
  GenerationSiteTaskDurationBase,
  IGenerationSiteTaskDuration,
} from '~/components/panels/schedule/r-order-form/generationSiteTaskDuration';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';

export interface IContainerTypeTaskDuration extends IGenerationSiteTaskDuration<IGenerationSiteTaskItem> {}

export class ContainerTypeTaskDuration
  extends GenerationSiteTaskDurationBase<IGenerationSiteTaskItem>
  implements IContainerTypeTaskDuration
{
  private readonly containerTypes: ContainerTypeEntity[];
  private readonly containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];

  constructor(containerTypes: ContainerTypeEntity[], containerTypeTaskTypes: ContainerTypeTaskTypeEntity[]) {
    super();
    this.containerTypes = containerTypes;
    this.containerTypeTaskTypes = containerTypeTaskTypes;
  }

  protected getDurationOfTasks(tasks: IGenerationSiteTaskItem[]): number {
    const cumulatedContainerTypeIdTaskTypeIds = new Set<string>();

    return tasks.reduce((acc: number, task: IGenerationSiteTaskItem) => {
      const taskTypeId = task.taskType?.id;
      const containerTypeId = task.containerTypeId;

      if (task.containerNum === undefined || taskTypeId === undefined || containerTypeId === undefined) return acc;

      const containerTypeTaskType = _.first(
        this.containerTypeTaskTypes.filter(
          (entity) => entity.taskType.id === taskTypeId && entity.containerType.id === containerTypeId
        )
      );
      if (containerTypeTaskType === undefined) return acc;

      // set で一致判定ができるように string に変換している
      const containerTypeIdtaskTypeId = `containerType$${containerTypeId}taskTypeId$${taskTypeId}`;

      // 荷姿種別 と 作業種別が同じで、数で増加しない設定になっているものは廃棄物種別が違っても1つの作業時間として計算する (すでに時間が加算されていれば追加しない)
      if (
        cumulatedContainerTypeIdTaskTypeIds.has(containerTypeIdtaskTypeId) &&
        containerTypeTaskType.isProportionalToCount.value === false
      )
        return acc;

      cumulatedContainerTypeIdTaskTypeIds.add(containerTypeIdtaskTypeId);

      const durationCoefficient = containerTypeTaskType.isProportionalToCount.value ? task.containerNum : 1;
      return acc + containerTypeTaskType.duration.value * durationCoefficient;
    }, 0);
  }
}
