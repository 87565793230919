import { ContainerTypeTaskType, UpdateContainerTypeTaskTypeInput } from '~/graphql/graphQLServerApi';
export const containerTypeTaskType$getAllSymbol = Symbol('containerTypeTaskType$getAllSymbol');
export const containerTypeTaskType$updateSymbol = Symbol('containerTypeTaskType$updateSymbol');

export type ContainerTypeTaskTypeApiTypes = {
  [containerTypeTaskType$getAllSymbol]: IGetAll;
  [containerTypeTaskType$updateSymbol]: IUpdate;
};

export type IContainerTypeTaskTypeData = ContainerTypeTaskType;

export interface IGetAll {
  getAll(): Promise<IContainerTypeTaskTypeData[]>;
}

export interface IUpdate {
  update(data: IUpdateContainerTypeTaskTypeData[]): Promise<string[]>;
}

export type IUpdateContainerTypeTaskTypeData = UpdateContainerTypeTaskTypeInput;
