
import Vue, { PropType } from 'vue';
import { OrderDisposalSitePriority } from '~/framework/domain/typeAliases';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

enum EventTypes {
  OpenEditDialog = 'open:edit-dialog',
}

export default Vue.extend({
  name: 'RDisposalSitePrioritySingleAssignment',
  props: {
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
  },
  computed: {
    selectedDisposalSites(): DisposalSiteEntity[] {
      return this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSiteEntity = this.disposalSiteEntities.find(
          (disposalSiteEntity) => disposalSiteEntity.id === orderAssignedDisposalSite.disposalSiteId
        );
        if (disposalSiteEntity === undefined) {
          throw new Error(`disposalSiteEntity is not found!: ${orderAssignedDisposalSite.disposalSiteId}`);
        }
        return disposalSiteEntity;
      });
    },
    displayEntities(): DisposalSiteEntity[] {
      return this.selectedDisposalSites.slice(0, 2);
    },
    highPriorityDisposalSiteNames(): string[] {
      return this.orderAssignedDisposalSites
        .filter(
          (orderAssignedDisposalSite) =>
            orderAssignedDisposalSite.priority === OrderDisposalSitePriority.High ||
            orderAssignedDisposalSite.priority === undefined
        )
        .map(
          (orderAssignedDisposalSite) =>
            this.selectedDisposalSites.find(
              (disposalSite) => disposalSite.id === orderAssignedDisposalSite.disposalSiteId
            )!.name
        );
    },
    lowPriorityDisposalSiteNames(): string[] {
      return this.orderAssignedDisposalSites
        .filter((orderAssignedDisposalSite) => orderAssignedDisposalSite.priority === OrderDisposalSitePriority.Low)
        .map(
          (orderAssignedDisposalSite) =>
            this.selectedDisposalSites.find(
              (disposalSite) => disposalSite.id === orderAssignedDisposalSite.disposalSiteId
            )!.name
        );
    },
  },
  methods: {
    openEditDialog() {
      this.$emit(EventTypes.OpenEditDialog);
    },
  },
});
