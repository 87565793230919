import {
  BaseTaskType,
  CreateTaskTypeInput,
  CreateTaskTypeMutation,
  TaskType,
  TaskTypeMutationError,
  UpdateTaskTypeMutation,
} from '@/graphql/graphQLServerApi';

export const taskType$getByIdsSymbol = Symbol('getByIds');
export const taskType$getAllSymbol = Symbol('getAll');
export const taskType$createSymbol = Symbol('create');
export const taskType$updateSymbol = Symbol('update');

export type ITaskTypeData = TaskType;

export type ICreateTaskTypeData = CreateTaskTypeInput;

export type IUpdateTaskTypeData = Omit<ICreateTaskTypeData, 'baseTaskTypeId'> & { id: string };

export type ICreateResultTypes = CreateTaskTypeMutation['createTaskType'];
export type IUpdateResultTypes = UpdateTaskTypeMutation['updateTaskType'];

export interface TaskTypeApiTypes {
  [taskType$getAllSymbol]: { getAll(): Promise<ITaskTypeData[]> };
  [taskType$getByIdsSymbol]: {
    getByIds(ids: string[]): Promise<ITaskTypeData[]>;
  };
  [taskType$createSymbol]: {
    create(data: ICreateTaskTypeData): Promise<ICreateResultTypes>;
  };
  [taskType$updateSymbol]: {
    update(data: IUpdateTaskTypeData): Promise<IUpdateResultTypes>;
  };
}
export interface ITaskTypeMutationError extends TaskTypeMutationError {}

export const baseTaskType$getAllSymbol = Symbol('getAll');
export interface BaseTaskTypeApiTypes {
  [baseTaskType$getAllSymbol]: { getAll(): Promise<IBaseTaskTypeData[]> };
}

export type IBaseTaskTypeData = BaseTaskType;
