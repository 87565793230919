import {
  CreateOrderAcceptanceCheckInput,
  CreateOrderAcceptanceCheckMutation,
  CreateOrderAcceptanceCheckMutationVariables,
  OrderAcceptanceChecksByIdsQuery,
  OrderAcceptanceChecksByIdsQueryVariables,
} from '@/graphql/graphQLServerApi';
import { CreateOrderAcceptanceCheck } from '@/../graphql/server-api/mutations/order-acceptance-check.gql';
import { OrderAcceptanceChecksByIds } from '@/../graphql/server-api/queries/order-acceptance-check.gql';
import {
  IGetByIdsApi,
  orderAcceptanceCheck$getByIdsSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/getByIds';
import {
  ICreateApi,
  ICreateResult,
  orderAcceptanceCheck$createSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import { IOrderAcceptanceCheckData } from '~/framework/server-api/schedule/order/order-acceptance-check/orderAcceptanceCheck';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class CreateApi extends GraphqlApiBase implements ICreateApi {
  [orderAcceptanceCheck$createSymbol] = undefined;
  async create(createData: CreateOrderAcceptanceCheckInput): Promise<ICreateResult> {
    const result = await this.mutate<CreateOrderAcceptanceCheckMutation, CreateOrderAcceptanceCheckMutationVariables>({
      mutation: CreateOrderAcceptanceCheck,
      variables: { orderAcceptanceCheck: createData },
    });
    return result.createOrderAcceptanceCheck;
  }
}

export class GetByIdsApi extends GraphqlApiBase implements IGetByIdsApi {
  [orderAcceptanceCheck$getByIdsSymbol] = undefined;
  async getByIds(ids: string[]): Promise<IOrderAcceptanceCheckData[]> {
    const result = await this.query<OrderAcceptanceChecksByIdsQuery, OrderAcceptanceChecksByIdsQueryVariables>({
      query: OrderAcceptanceChecksByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.orderAcceptanceChecksByIds, `OrderAcceptanceChecksByIds`);
    return result.orderAcceptanceChecksByIds;
  }
}
