import { OrderStore } from '~/framework/domain/schedule/order/orderStore';
import { OrderAcceptanceCheckStore } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckStore';
import { ScheduleStore } from '~/framework/domain/schedule/schedule/scheduleStore';

export class ScheduleNamespace {
  readonly schedule: ScheduleStore;
  readonly orderAcceptanceCheck: OrderAcceptanceCheckStore;
  readonly order: OrderStore;

  constructor() {
    this.schedule = new ScheduleStore();
    this.orderAcceptanceCheck = new OrderAcceptanceCheckStore();
    this.order = new OrderStore();
  }
}
