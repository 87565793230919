
import Vue, { PropType } from 'vue';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { CssClasses, Maybe, PersistentId, Seconds, ValidationRule } from '~/framework/typeAliases';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import {
  ICarItem,
  IDriverAttendanceTemplateItem,
  PseudoDriverAttendanceTemplateItem,
} from '~/components/common/r-driver-monthly-attendances-dialog/r-driver-attendance/selectionItems';

import { ImpossiblePersistentId, UndefinedPersistentId, DaySeconds } from '~/framework/constants';
import { IHasRestPeriodItem, IOvertimeWorkTypeItem } from '~/framework/view-models/driverAttendance';
import { hankanize } from '~/framework/services/string/string';
import { required } from '~/framework/view-models/rules';
import { DriverEntity as IDriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import RDurationInput from '~/components/common/r-driver-monthly-attendances-dialog/r-driver-attendance/RDurationInput.vue';
import { Time } from '~/framework/domain/common/date-time/time';
import { hhMmToSecs, isNextDay, validateHardLimitTime, isInTimeRange } from '~/framework/services/date-time/date-time';
import { DriverAttendanceService } from '~/framework/domain/masters/driver-attendance/driverAttendanceService';
import { v4 as uuidv4 } from 'uuid';

type DataType = {
  regularWorkPeriodStartStringKey: string;
  regularWorkPeriodEndStringKey: string;
  restPeriodStartStringKey: string;
  restPeriodEndStringKey: string;
  hasRestPeriod: Maybe<boolean>;
  regularWorkPeriodEndErrorMessage: Maybe<string>;
  restPeriodStartStringValue: Maybe<string>;
  restPeriodEndStringValue: Maybe<string>;
  rules: { [key: string]: ValidationRule };
  driverAttendanceService: DriverAttendanceService;
  forceRidePrimaryCar: boolean;
};

enum EventTypes {
  ChangePrimaryCarId = 'change:primary-car-id',
  ChangeForceRidePrimaryCar = 'change:force-ride-primary-car',
  ChangeRegularWorkPeriodString = 'change:regular-work-period-string',
  ChangeRestPeriod = 'change:rest-period',
  ChangeOvertimeWorkType = 'change:overtime-work-type',
  ChangeOvertimeWorkableDuration = 'change:overtime-workable-duration',
  ChangeAttendanceTemplateId = 'change:driver-attendance-template-id',
  CreateDriverAttendance = 'create:driver-attendance',
  RemoveDriverAttendance = 'remove:driver-attendance',
}

export default Vue.extend({
  name: 'RDriverAttendance',
  components: {
    RDurationInput,
  },
  props: {
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    isHoliday: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isDateOfSelectedMonth: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isWarned: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isPrimaryCarConflicted: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    carDriverMap: {
      type: Map as PropType<Map<PersistentId, DriverAttendanceEntity[]>>,
      required: true,
    },
    driver: {
      type: Object as PropType<Maybe<IDriverEntity>>,
      required: false,
      default: undefined,
    },
    workDayDriverAttendanceTemplateItems: {
      type: Array as PropType<IDriverAttendanceTemplateItem[]>,
      required: true,
    },
    holidayDriverAttendanceTemplateItems: {
      type: Array as PropType<IDriverAttendanceTemplateItem[]>,
      required: true,
    },
    driverAttendanceTemplateId: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    primaryCarId: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    carItems: {
      type: Array as PropType<ICarItem[]>,
      required: true,
    },
    driverAttendance: {
      type: Object as PropType<Maybe<DriverAttendanceEntity>>,
      required: false,
      default: undefined,
    },
    regularWorkPeriodStartString: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    regularWorkPeriodEndString: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    restPeriodStartString: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    restPeriodEndString: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    overtimeWorkType: {
      type: String as PropType<Maybe<OvertimeWorkType>>,
      required: false,
      default: undefined,
    },
    overtimeWorkTypeItems: {
      type: Array as PropType<IOvertimeWorkTypeItem[]>,
      required: true,
    },
    overtimeWorkableDuration: {
      type: Number as PropType<Seconds>,
      required: false,
      default: 0,
    },
    hasRestPeriodItems: {
      type: Array as PropType<IHasRestPeriodItem[]>,
      required: true,
    },
  },
  data(): DataType {
    const hasRestPeriod =
      this.driverAttendance === undefined
        ? undefined
        : this.restPeriodStartString !== undefined && this.restPeriodEndString !== undefined;
    return {
      regularWorkPeriodStartStringKey: uuidv4(),
      regularWorkPeriodEndStringKey: uuidv4(),
      restPeriodStartStringKey: uuidv4(),
      restPeriodEndStringKey: uuidv4(),
      restPeriodStartStringValue: this.restPeriodStartString,
      restPeriodEndStringValue: this.restPeriodEndString,
      regularWorkPeriodEndErrorMessage: undefined,
      hasRestPeriod,
      rules: { required },
      driverAttendanceService: new DriverAttendanceService(),
      forceRidePrimaryCar: this.driverAttendance?.forceRidePrimaryCar ?? false,
    };
  },
  computed: {
    classes(): CssClasses {
      return {
        'r-driver-attendance': true,
        'r-driver-attendance--selected': !this.isPrimaryCarConflicted && this.isSelected,
        'r-driver-attendance--warned': this.isWarned,
      };
    },
    dateClasses(): CssClasses {
      return {
        'r-driver-attendance__date': true,
        'r-driver-attendance__date--holiday': this.isHoliday,
        'r-driver-attendance__date--other-month': !this.isDateOfSelectedMonth,
      };
    },
    templateClasses(): CssClasses {
      return {
        'r-driver-attendance__content__template-time__template': true,
        'r-driver-attendance__content__template-time__template--no-attendance': !this.hasAttendance,
      };
    },
    regularWorkPeriodStartTimeClasses(): CssClasses {
      return {
        'r-driver-attendance__content__template-time__time__start-time': true,
        'r-driver-attendance__content__template-time__time__start-time--no-attendance': !this.hasAttendance,
      };
    },
    regularWorkPeriodEndTimeClasses(): CssClasses {
      return {
        'r-driver-attendance__content__template-time__time__end-time': true,
        'r-driver-attendance__content__template-time__time__end-time--no-attendance': !this.hasAttendance,
      };
    },
    carClasses(): CssClasses {
      return {
        'r-driver-attendance__content__car__car': true,
        'r-driver-attendance__content__car__car--conflicted': this.isPrimaryCarConflicted,
        'r-driver-attendance__content__car__car--no-attendance': !this.hasAttendance,
      };
    },
    overtimeWorkTypeClasses(): CssClasses {
      return {
        'r-driver-attendance__content__overtime-work-type-time__type': true,
        'r-driver-attendance__content__overtime-work-type-time__type--no-attendance': !this.hasAttendance,
      };
    },
    overtimeWorkableDurationClasses(): CssClasses {
      return {
        'r-driver-attendance__content__overtime-work-type-time__time': true,
      };
    },
    hasRestClasses(): CssClasses {
      return {
        'r-driver-attendance__content__rest-time__rest': true,
        'r-driver-attendance__content__rest-time__rest--no-attendance': !this.hasAttendance,
      };
    },
    restPeriodClasses(): CssClasses {
      return {
        'r-driver-attendance__content__rest-time__time': true,
        'r-driver-attendance__content__rest-time__time--no-attendance': !this.hasAttendance,
      };
    },
    restPeriodStartTimeClasses(): CssClasses {
      return {
        'r-driver-attendance__content__rest-time__time__start-time': true,
        'r-driver-attendance__content__rest-time__time__start-time--no-attendance': !this.hasAttendance,
      };
    },
    restPeriodEndTimeClasses(): CssClasses {
      return {
        'r-driver-attendance__content__rest-time__time__end-time': true,
        'r-driver-attendance__content__rest-time__time__end-time--no-attendance': !this.hasAttendance,
      };
    },
    dayOfMonth(): string {
      return format(this.date, 'd', { locale: ja });
    },
    hasAttendance(): boolean {
      return this.driverAttendance !== undefined;
    },
    regularWorkPeriodStartPlaceholder(): Maybe<string> {
      return this.regularWorkPeriodStartString === undefined ? undefined : '00:00';
    },
    regularWorkPeriodEndPlaceholder(): Maybe<string> {
      return this.regularWorkPeriodEndString === undefined ? undefined : '00:00';
    },
    restPeriodStartPlaceholder(): Maybe<string> {
      return this.restPeriodStartStringValue === undefined ? undefined : '00:00';
    },
    restPeriodEndPlaceholder(): Maybe<string> {
      return this.restPeriodEndStringValue === undefined ? undefined : '00:00';
    },
    driverAttendanceTemplateItems(): IDriverAttendanceTemplateItem[] {
      // 若干分かりにくいのだが、休日は「勤務無」ではなく「休み」という表示にするためにあえてしている
      // また、テンプレが見つからなかった場合は「その他」という選択肢で表示する様にしている
      // その他は表示はされるが、選択する事はできない
      const items = this.isHoliday
        ? [...this.holidayDriverAttendanceTemplateItems]
        : [...this.workDayDriverAttendanceTemplateItems];
      if (this.driverAttendanceTemplateId === ImpossiblePersistentId) {
        items.push(PseudoDriverAttendanceTemplateItem);
      }
      return items;
    },
    isOvertimeWorkable(): boolean {
      return this.overtimeWorkType !== undefined && this.overtimeWorkType !== OvertimeWorkType.None;
    },
  },
  watch: {
    driverAttendance(_value: Maybe<DriverAttendanceEntity>): void {
      this.hasRestPeriod = this.getHasRestPeriod();
      this.forceRidePrimaryCar = this.driverAttendance?.forceRidePrimaryCar ?? false;
    },
    hasRestPeriod(value: Maybe<boolean>): void {
      if (value === undefined) return;
      if (!value) {
        this.$emit(EventTypes.ChangeRestPeriod, this.driverAttendance, undefined, undefined);
      }
    },
    restPeriodStartString(value: Maybe<string>): void {
      this.hasRestPeriod = this.getHasRestPeriod();
      this.restPeriodStartStringValue = value;
      // NOTE これが変わる時は driverAttendance を差し替えた時だけで、その時に休憩時間が変更されたものと
      //   してしまうとイベント経由で更新が走ってしまうので無駄な循環が起きる。そのため、ここでは
      //   onChangeRestPeriod は呼ばなくてよい
    },
    restPeriodEndString(value: Maybe<string>): void {
      this.hasRestPeriod = this.getHasRestPeriod();
      this.restPeriodEndStringValue = value;
      // NOTE これが変わる時は driverAttendance を差し替えた時だけで、その時に休憩時間が変更されたものと
      //   してしまうとイベント経由で更新が走ってしまうので無駄な循環が起きる。そのため、ここでは
      //   onChangeRestPeriod は呼ばなくてよい
    },
  },
  methods: {
    hankanize,
    hhMmToSecs,
    isInTimeRange,
    getHasRestPeriod(): Maybe<boolean> {
      if (this.driverAttendance === undefined) return undefined;
      return this.restPeriodStartString !== undefined && this.restPeriodEndString !== undefined;
    },
    validateRestPeriod(): void {
      if (this.$refs.restPeriodStartString) (this.$refs.restPeriodStartString as any).validate(true);
      if (this.$refs.restPeriodEndString) (this.$refs.restPeriodEndString as any).validate(true);
    },
    isRestPeriodApplicable(): boolean {
      const allSet = this.restPeriodStartStringValue !== undefined && this.restPeriodEndStringValue !== undefined;
      const allNone = this.restPeriodStartStringValue === undefined && this.restPeriodEndStringValue === undefined;
      return this.hasAttendance && (allSet || allNone);
    },
    isCarConflicted(carId: PersistentId): boolean {
      const driverAttendances = this.carDriverMap.get(carId);
      return driverAttendances !== undefined && 1 <= driverAttendances.length;
    },
    onChangeDriverAttendanceTemplateId(value: PersistentId): void {
      if (value === UndefinedPersistentId) {
        // 削除

        this.$emit(EventTypes.RemoveDriverAttendance, this.driverAttendance);
      } else if (this.hasAttendance) {
        // テンプレを変えただけ

        this.$emit(EventTypes.ChangeAttendanceTemplateId, this.driverAttendance, value);
      } else {
        // 作成

        this.$emit(EventTypes.CreateDriverAttendance, this.date, this.driver, value);
      }
    },
    onChangePrimaryCarId(value: PersistentId): void {
      this.$emit(EventTypes.ChangePrimaryCarId, this.driverAttendance, value);
    },
    onChangeForceRidePrimaryCar(value: boolean): void {
      this.$emit(EventTypes.ChangeForceRidePrimaryCar, this.driverAttendance, value);
    },
    onChangeOvertimeWorkType(value: OvertimeWorkType): void {
      this.$emit(EventTypes.ChangeOvertimeWorkType, this.driverAttendance, value);
    },
    onChangeOvertimeWorkableDuration(value: Seconds): void {
      this.$emit(EventTypes.ChangeOvertimeWorkableDuration, this.driverAttendance, value);
    },
    onChangeRegularWorkPeriodStartString(value: Maybe<string>): void {
      if (value === undefined) return;

      const regularWorkPeriodStart = this.correctRegularWorkPeriodStart(Time.buildByString(value));

      this.$emit(
        EventTypes.ChangeRegularWorkPeriodString,
        this.driverAttendance,
        regularWorkPeriodStart.toHhMm(),
        this.regularWorkPeriodEndString
      );

      this.regularWorkPeriodStartStringKey = uuidv4();
    },
    onChangeRegularWorkPeriodEndString(value: Maybe<string>): void {
      if (value === undefined) return;

      const regularWorkPeriodEnd = this.correctRegularWorkPeriodEnd(Time.buildByString(value));

      // NOTE: startTime が設定されていない場合はそのまま endTime を更新する
      if (this.regularWorkPeriodStartString === undefined) {
        this.$emit(
          EventTypes.ChangeRegularWorkPeriodString,
          this.driverAttendance,
          undefined,
          regularWorkPeriodEnd.toHhMm()
        );
        return;
      }

      this.$emit(
        EventTypes.ChangeRegularWorkPeriodString,
        this.driverAttendance,
        this.regularWorkPeriodStartString,
        regularWorkPeriodEnd.toHhMm()
      );

      this.regularWorkPeriodEndStringKey = uuidv4();
    },
    onChangeRestPeriodStart(value: Maybe<string>): void {
      // NOTE: 勤務時間が設定されていなければ休憩時間は設定できない
      if (value === undefined) return;

      const restPeriodStart = this.correctRestPeriodStart(Time.buildByString(value));

      // NOTE: endTime、勤務時間が設定されていない場合はそのまま更新する
      if (
        this.restPeriodEndStringValue === undefined ||
        this.regularWorkPeriodStartString === undefined ||
        this.regularWorkPeriodEndString === undefined
      ) {
        this.restPeriodStartStringValue = restPeriodStart.toHhMm();
        return;
      }

      this.$emit(
        EventTypes.ChangeRestPeriod,
        this.driverAttendance,
        restPeriodStart.toHhMm(),
        this.restPeriodEndStringValue
      );

      this.restPeriodStartStringKey = uuidv4();
    },
    onChangeRestPeriodEnd(value: string): void {
      if (value === undefined) return;

      const restPeriodEnd = this.correctRestPeriodEnd(Time.buildByString(value));

      // NOTE: startTime、勤務時間が設定されていない場合はそのまま更新する
      if (
        this.restPeriodStartStringValue === undefined ||
        this.regularWorkPeriodStartString === undefined ||
        this.regularWorkPeriodEndString === undefined
      ) {
        this.restPeriodEndStringValue = restPeriodEnd.toHhMm();
        return;
      }

      this.$emit(
        EventTypes.ChangeRestPeriod,
        this.driverAttendance,
        this.restPeriodStartStringValue,
        restPeriodEnd.toHhMm()
      );

      this.restPeriodEndStringKey = uuidv4();
    },
    correctRegularWorkPeriodStart(value: Time): Time {
      return this.driverAttendanceService.correctRegularWorkPeriodStart(
        value,
        this.restPeriodStartStringValue ? Time.buildByString(this.restPeriodStartStringValue) : undefined,
        this.restPeriodEndStringValue ? Time.buildByString(this.restPeriodEndStringValue) : undefined,
        this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
      );
    },
    correctRestPeriodStart(value: Time): Time {
      return this.driverAttendanceService.correctRestPeriodStart(
        this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
        value,
        this.restPeriodEndStringValue ? Time.buildByString(this.restPeriodEndStringValue) : undefined,
        this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
      );
    },
    correctRestPeriodEnd(value: Time): Time {
      return this.driverAttendanceService.correctRestPeriodEnd(
        this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
        this.restPeriodStartStringValue ? Time.buildByString(this.restPeriodStartStringValue) : undefined,
        value,
        this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
      );
    },
    correctRegularWorkPeriodEnd(value: Time): Time {
      return this.driverAttendanceService.correctRegularWorkPeriodEnd(
        this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
        this.restPeriodStartStringValue ? Time.buildByString(this.restPeriodStartStringValue) : undefined,
        this.restPeriodEndStringValue ? Time.buildByString(this.restPeriodEndStringValue) : undefined,
        value
      );
    },
    // NOTE: time-picker 上で選択させたくない値をチェックする。
    // 現状はハードリミットの確認のみで、過去に遡ることはないため endTime だけチェックできればよい
    onUpdateRegularWorkPeriodError(value: Maybe<string>): void {
      if (value === undefined || this.regularWorkPeriodStartString === undefined) {
        return;
      }

      this.regularWorkPeriodEndErrorMessage = this.checkErrors(
        hhMmToSecs(this.regularWorkPeriodStartString),
        hhMmToSecs(value)
      );
    },
    checkErrors(start: number, end: number): Maybe<string> {
      // NOTE: startTime > endTime の場合は日跨ぎとみなす
      if (isNextDay(start, end)) {
        const calculatedEndTime = end + DaySeconds;

        if (!validateHardLimitTime(calculatedEndTime)) {
          return '翌日12時以降は指定できません';
        }
      }
      return undefined;
    },
  },
});
