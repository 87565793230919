import { IUserData } from '~/framework/server-api/masters/user';
import { CreateDisposalSiteInput, DisposalSite, UpdateDisposalSiteInput } from '~/graphql/graphQLServerApi';
export const disposalSite$createSymbol = Symbol('disposalSite$create');
export const disposalSite$getAllSymbol = Symbol('disposalSite$getAll');
export const disposalSite$updateSymbol = Symbol('disposalSite$update');

export type DisposalSiteApiTypes = {
  [disposalSite$getAllSymbol]: IGetAllApi;
  [disposalSite$createSymbol]: ICreateApi;
  [disposalSite$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: ICreateDisposalSiteData[]): Promise<IDisposalSiteCreateResultData[]>;
}

export type ICreateDisposalSiteData = CreateDisposalSiteInput;

export interface IDisposalSiteCreateResultData {
  id: string;
  createdAt: Date;
  createdBy: IUserData;
}

export type IDisposalSiteData = DisposalSite;

export interface IGetAllApi {
  getAll(): Promise<IDisposalSiteData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateDisposalSiteData[]): Promise<string[]>;
}

export type IUpdateDisposalSiteData = UpdateDisposalSiteInput;
