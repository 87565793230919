import { HolidayRule } from '~/graphql/graphQLServerApi';

export const holidayRule$getSymbol = Symbol('holidayRule$getSymbol');
export const holidayRule$updateSymbol = Symbol('holidayRule$updateSymbol');

export type HolidayRuleApiTypes = {
  [holidayRule$getSymbol]: IGetApi;
  [holidayRule$updateSymbol]: IUpdateApi;
};

export interface IGetApi {
  get(): Promise<IHolidayRuleData>;
}
export type IHolidayRuleData = HolidayRule;

export interface IUpdateApi {
  update(data: IHolidayRuleData): Promise<void>;
}
