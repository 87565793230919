import { MapperBase } from '~/framework/core/mapper';
import { GenerationSiteTaskEntity } from '~/framework/domain/schedule/order/generation-site-task/generationSiteTaskEntity';
import { WasteTypeMapper } from '~/framework/domain/masters/waste-type/wasteTypeMapper';
import { WasteTypeStore } from '~/framework/domain/masters/waste-type/wasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IGenerationSiteTaskData } from '~/framework/server-api/schedule/order/generation-site-task/generationSiteTask';
import { ContainerTypeMapper } from '~/framework/domain/masters/container-type/containerTypeMapper';
import { ContainerTypeStore } from '~/framework/domain/masters/container-type/containerTypeStore';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { TaskTypeMapper } from '~/framework/domain/masters/task-type/taskTypeMapper';
import { DriverStore } from '~/framework/domain/masters/driver/driverStore';
import { CarStore } from '~/framework/domain/masters/car/carStore';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';

/**
 * トラックする必要はないので Mapper は厳密には必要ないが、同じ作法にしておいた方が
 * 分かりやすそうなので用意している
 */

export class GenerationSiteTaskMapper extends MapperBase<IGenerationSiteTaskData, GenerationSiteTaskEntity> {
  private readonly wasteTypeMapper: WasteTypeMapper;
  private readonly containerTypeMapper: ContainerTypeMapper;
  private readonly taskTypeMappter: TaskTypeMapper;

  constructor(
    wasteTypeStore: WasteTypeStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore,
    userStore: UserStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore,
    driverStore: DriverStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore
  ) {
    super();
    this.wasteTypeMapper = new WasteTypeMapper(wasteTypeStore, jwnetWasteMasterStore, userStore);
    this.containerTypeMapper = new ContainerTypeMapper(containerTypeStore, packingStyleStore);
    this.taskTypeMappter = new TaskTypeMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
  }

  protected instantiateWithData(data: IGenerationSiteTaskData): GenerationSiteTaskEntity {
    const wasteType = data.wasteType ? this.wasteTypeMapper.mapSingle(data.wasteType) : undefined;
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    return new GenerationSiteTaskEntity(data, wasteType, containerType, taskType);
  }
}
