import { PseudoOrderEntity } from '~/framework/domain/schedule/order/orderEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { InconsistencyData } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistencyData';
import { InconsistencyReasons } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { Maybe } from '~/framework/typeAliases';

export class Inconsistency {
  id: string;
  driver: DriverEntity;
  routeIndexes: number[];
  reason: InconsistencyReasons;
  /**
   * 最適化から返ってきた時の配列の中での順番
   */
  index: number;
  /**
   * クライアントサイドでひとまず解決したものとして扱うかどうか
   */
  deemedAsSolved: boolean;
  orders: Maybe<PseudoOrderEntity[]>;

  constructor(
    data: InconsistencyData,
    driver: DriverEntity,
    index: number,
    deemedAsSolved: boolean,
    // TODO: opt で必ずフィールドを返すようにしたら Maybe を外す
    orders: Maybe<PseudoOrderEntity[]>
  ) {
    this.id = data.id;
    this.routeIndexes = data.indexes;
    this.reason = data.reason;
    this.driver = driver;
    this.deemedAsSolved = deemedAsSolved;
    this.index = index;
    this.orders = orders;
  }
}
