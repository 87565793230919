import { WithoutId, Maybe } from '~/framework/typeAliases';
import { Attendance } from '~/graphql/graphQLServerApi';
export const attendance$createSymbol = Symbol('attendance$create');

export const attendance$getByDateSymbol = Symbol('attendance$getByDateSymbol');

export const attendance$getByDateRangeSymbol = Symbol('attendance$getByDateRange');

export const attendance$getByIdsSymbol = Symbol('attendance$getByIds');

export type AttendanceApiTypes = {
  [attendance$getByIdsSymbol]: IGetByIdsApi;
  [attendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [attendance$createSymbol]: ICreateApi;
  [attendance$getByDateSymbol]: IGetByDateApi;
};

export type IAttendanceData = Attendance;

export interface ICreateApi {
  create(data: WithoutId<IAttendanceData>[]): Promise<string[]>;
}

export interface IGetByDateApi {
  getByDate(date: Date): Promise<Maybe<IAttendanceData>>;
}

export interface IGetByDateRangeApi {
  getByDateRange(start: Date, end: Date): Promise<IAttendanceData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IAttendanceData[]>;
}
