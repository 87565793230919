import { StoredMapperBase } from '~/framework/core/mapper';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { BaseSiteStore } from '~/framework/domain/masters/base-site/baseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { IBaseSiteData } from '~/framework/server-api/masters/baseSite';

export class BaseSiteMapper extends StoredMapperBase<IBaseSiteData, BaseSiteEntity> {
  protected store: BaseSiteStore;
  private userMapper: UserMapper;

  constructor(store: BaseSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IBaseSiteData): BaseSiteEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new BaseSiteEntity(data, createdBy);
  }

  protected updateWithData(data: IBaseSiteData, entity: BaseSiteEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.update(data, createdBy);
  }
}
