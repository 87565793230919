import { CarAttendanceEntity } from '~/framework/domain/masters/car-attendance/carAttendanceEntity';
import { CarAttendanceStore } from '~/framework/domain/masters/car-attendance/carAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import { ICarAttendanceData } from '~/framework/server-api/masters/carAttendance';

export class CarAttendanceMapper extends StoredMapperBase<ICarAttendanceData, CarAttendanceEntity> {
  protected store: CarAttendanceStore;
  private attendanceMapper: AttendanceMapper;

  constructor(store: CarAttendanceStore, attendanceStore: AttendanceStore) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
  }

  protected instantiateWithData(data: ICarAttendanceData): CarAttendanceEntity {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);

    return new CarAttendanceEntity(data, attendanceEntity);
  }

  protected updateWithData(data: ICarAttendanceData, entity: CarAttendanceEntity): void {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);
    entity.update(data, attendanceEntity);
  }
}
