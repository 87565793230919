import {
  authentication$signInSymbol,
  SignInApi,
  SignInData,
  SignInStatus,
} from '~/framework/server-api/authentication/signIn';
import { authentication$signOutSymbol, ISignOutApi } from '~/framework/server-api/authentication/signOut';
import {
  authentication$currentSessionSymbol,
  ICurrentSessionApi,
  ISessionData,
  UnauthenticatedException,
} from '~/framework/server-api/authentication/session';
import { createLogger } from '~/framework/logger';

let signedIn: boolean = false;

export class LocalSignInApi implements SignInApi {
  [authentication$signInSymbol] = undefined;
  signIn(data: SignInData): Promise<SignInStatus> {
    logger.info(`trying to login`, data);
    signedIn = true;
    return Promise.resolve(SignInStatus.Success);
  }
}

export class LocalSignOutApi implements ISignOutApi {
  [authentication$signOutSymbol] = undefined;
  signOut(): Promise<void> {
    signedIn = false;
    return Promise.resolve();
  }
}

export class LocalCurrentSessionApi implements ICurrentSessionApi {
  [authentication$currentSessionSymbol] = undefined;
  getCurrentSession(): Promise<ISessionData> {
    // NOTE: 初回表示でログイン画面を表示したい場合は signedIn = true をコメントアウトする
    signedIn = true;
    if (signedIn) {
      return Promise.resolve({
        officeId: '1',
        officeName: 'テストオフィス',
        user: {
          id: '1',
          name: 'テストユーザー',
        },
      });
    } else {
      throw new UnauthenticatedException(`Stub unauthenticated exception`);
    }
  }
}

const logger = createLogger(`stub authentication apis`);
