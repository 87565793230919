import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';

/**
 * CarType を編集するためのもの
 */
export interface ICarTypeFormPanel extends IEntityFormPanel<CarTypeEntity> {}

export class CarTypeFormPanel extends AbstractEntityFormPanel<CarTypeEntity> implements ICarTypeFormPanel {}
