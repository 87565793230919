import {
  AcceptReservation,
  CancelReservation,
  PushBackReservationFromCancelledToWaitingReview,
  UpdateReservationDeadline,
} from '@/../graphql/server-api/mutations/reservation.gql';
import {
  CancelledReservationsByDate,
  ReservationDeadlineByDate,
  ReservationsByDate,
  WaitingReviewReservationDatesByDateRange,
} from '@/../graphql/server-api/queries/reservation.gql';

import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IReservationData, IReservationDeadlineData } from '~/framework/server-api/reservation/reservation';
import {
  IReservationsByDateApi,
  reservation$reservationsByDateSymbol,
} from '~/framework/server-api/reservation/reservationsByDate';
import {
  AcceptReservationMutation,
  AcceptReservationMutationVariables,
  CancelledReservationsByDateQuery,
  CancelledReservationsByDateQueryVariables,
  CancelReservationMutation,
  CancelReservationMutationVariables,
  ReservationsByDateQuery,
  ReservationsByDateQueryVariables,
  PushBackReservationFromCancelledToWaitingReviewMutation,
  PushBackReservationFromCancelledToWaitingReviewMutationVariables,
  WaitingReviewReservationDatesByDateRangeQuery,
  WaitingReviewReservationDatesByDateRangeQueryVariables,
  ReservationDeadlineByDateQuery,
  ReservationDeadlineByDateQueryVariables,
  UpdateReservationDeadlineMutation,
  UpdateReservationDeadlineMutationVariables,
  ReservationDeadlineStatus,
} from '~/graphql/graphQLServerApi';
import {
  IAcceptReservationApi,
  IAcceptReservationData,
  IAcceptReservationMutationResultData,
  reservation$acceptReservationSymbol,
} from '~/framework/server-api/reservation/acceptReservation';
import {
  ICancelledReservationsByDateApi,
  reservation$cancelledReservationsByDateSymbol,
} from '~/framework/server-api/reservation/cancelledReservationsByDate';
import {
  IPushBackReservationFromCancelledToWaitingReviewApi,
  IPushBackReservationFromCancelledToWaitingReviewData,
  reservation$pushBackReservationFromCancelledToWaitingReviewSymbol,
} from '~/framework/server-api/reservation/pushBackReservationFromCancelledToWaitingReview';
import {
  ICancelReservationApi,
  ICancelReservationData,
  reservation$cancelReservationSymbol,
} from '~/framework/server-api/reservation/cancelReservation';
import {
  IWaitingReviewReservationDatesByDateRangeApi,
  reservation$waitingReviewReservationDatesByDateRangeSymbol,
} from '~/framework/server-api/reservation/waitingReviewReservationByDateRange';
import {
  IReservationDeadlineByDateApi,
  reservation$reservationDeadlineByDateSymbol,
} from '~/framework/server-api/reservation/reservationDeadlineByDate';
import {
  IUpdateReservationDeadlineApi,
  reservation$updateReservationDeadlineSymbol,
} from '~/framework/server-api/reservation/updateReservationDeadline';

export class ReservationsByDateApi extends GraphqlApiBase implements IReservationsByDateApi {
  [reservation$reservationsByDateSymbol] = undefined;
  async reservationsByDate(date: Date): Promise<IReservationData[]> {
    const result = await this.query<ReservationsByDateQuery, ReservationsByDateQueryVariables>({
      query: ReservationsByDate,
      variables: { date },
    });

    return result.reservationsByDate.map((d) => ({ ...d, isCancelled: false }));
  }
}

export class CancelledReservationsByDateApi extends GraphqlApiBase implements ICancelledReservationsByDateApi {
  [reservation$cancelledReservationsByDateSymbol] = undefined;
  async cancelledReservationsByDate(date: Date): Promise<IReservationData[]> {
    const result = await this.query<CancelledReservationsByDateQuery, CancelledReservationsByDateQueryVariables>({
      query: CancelledReservationsByDate,
      variables: { date },
    });

    return result.cancelledReservationsByDate.map((d) => ({ ...d, isCancelled: true }));
  }
}

export class AcceptReservationApi extends GraphqlApiBase implements IAcceptReservationApi {
  [reservation$acceptReservationSymbol] = undefined;
  async acceptReservation(data: IAcceptReservationData): Promise<IAcceptReservationMutationResultData> {
    const result = await this.mutate<AcceptReservationMutation, AcceptReservationMutationVariables>({
      mutation: AcceptReservation,
      variables: { acceptReservationInput: data },
    });

    return result.acceptReservation;
  }
}

export class CancelReservationApi extends GraphqlApiBase implements ICancelReservationApi {
  [reservation$cancelReservationSymbol] = undefined;

  async cancelReservation(data: ICancelReservationData) {
    const result = await this.mutate<CancelReservationMutation, CancelReservationMutationVariables>({
      mutation: CancelReservation,
      variables: { cancelReservationInput: data },
    });

    return result.cancelReservation;
  }
}

export class PushBackReservationFromCancelledToWaitingReviewApi
  extends GraphqlApiBase
  implements IPushBackReservationFromCancelledToWaitingReviewApi
{
  [reservation$pushBackReservationFromCancelledToWaitingReviewSymbol] = undefined;

  async pushBackReservationFromCancelledToWaitingReview(data: IPushBackReservationFromCancelledToWaitingReviewData) {
    const result = await this.mutate<
      PushBackReservationFromCancelledToWaitingReviewMutation,
      PushBackReservationFromCancelledToWaitingReviewMutationVariables
    >({
      mutation: PushBackReservationFromCancelledToWaitingReview,
      variables: { pushBackReservationFromCancelledToWaitingReviewInput: data },
    });

    return result.pushBackReservationFromCancelledToWaitingReview;
  }
}

export class WaitingReviewReservationDatesByDateRangeApi
  extends GraphqlApiBase
  implements IWaitingReviewReservationDatesByDateRangeApi
{
  [reservation$waitingReviewReservationDatesByDateRangeSymbol] = undefined;
  async waitingReviewReservationDatesByDateRange(start: Date, end: Date): Promise<Date[]> {
    const result = await this.query<
      WaitingReviewReservationDatesByDateRangeQuery,
      WaitingReviewReservationDatesByDateRangeQueryVariables
    >({
      query: WaitingReviewReservationDatesByDateRange,
      variables: { condition: { start, end } },
    });

    return result.waitingReviewReservationDatesByDateRange;
  }
}

export class ReservationDeadlineByDateApi extends GraphqlApiBase implements IReservationDeadlineByDateApi {
  [reservation$reservationDeadlineByDateSymbol] = undefined;
  async reservationDeadlineByDate(date: Date): Promise<IReservationDeadlineData> {
    const result = await this.query<ReservationDeadlineByDateQuery, ReservationDeadlineByDateQueryVariables>({
      query: ReservationDeadlineByDate,
      variables: { date },
    });

    return {
      reservable: result.reservationDeadlineByDate.reservable,
      deadlineUpdatable: result.reservationDeadlineByDate.deadlineUpdatable,
    };
  }
}

export class UpdateReservationDeadlineApi extends GraphqlApiBase implements IUpdateReservationDeadlineApi {
  [reservation$updateReservationDeadlineSymbol] = undefined;
  async updateReservationDeadline(date: Date, status: ReservationDeadlineStatus): Promise<IReservationDeadlineData> {
    const result = await this.mutate<UpdateReservationDeadlineMutation, UpdateReservationDeadlineMutationVariables>({
      mutation: UpdateReservationDeadline,
      variables: { date, status },
    });

    return {
      reservable: result.updateReservationDeadline.reservable,
      deadlineUpdatable: result.updateReservationDeadline.deadlineUpdatable,
    };
  }
}
