
import Vue, { PropType } from 'vue';
import RDisposalSiteDurationTimesMultipleDialog from '~/components/panels/schedule/r-order-form/RDisposalSiteDurationTimesMultipleDialog.vue';
import RFieldClickableTooltip from '~/components/common/r-field-clickable-tooltip/RFieldClickableTooltip.vue';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import {
  getHoursOf,
  getMinutesOf,
  getSecondsFromMinutes,
  getSecondsFromHours,
} from '~/framework/services/date-time/date-time';
import { sanitizeNaturalNumber } from '~/framework/view-models/ruleLogics';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

enum EventTypes {
  Change = 'change',
}

type DataType = {
  localSingleDurationAtEntrance: Maybe<number>;
  singleDurationAtEntranceHours: Maybe<number>;
  singleDurationAtEntranceMinutes: Maybe<number>;
  isDurationTimesMultipleDialogActive: boolean;
};

export type DisposalSiteDuration = {
  disposalSiteId: string;
  durationAtEntrance: number;
};

export default Vue.extend({
  name: 'RDisposalSitesDurationTimeEditor',
  components: {
    RFieldClickableTooltip,
    RDisposalSiteDurationTimesMultipleDialog,
  },
  props: {
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<DisposalSiteEntity[]>,
      required: true,
    },
    totalDurationOfTasks: {
      type: Number,
      required: true,
    },
  },
  data(): DataType {
    const singleDurationAtEntrance: Maybe<number> =
      this.orderAssignedDisposalSites.length === 1 ? this.orderAssignedDisposalSites[0].durationAtEntrance : undefined;

    return {
      // NOTE: single~ は指定処分場が1件の場合パネルに表示する各時間
      localSingleDurationAtEntrance: singleDurationAtEntrance,
      singleDurationAtEntranceHours:
        singleDurationAtEntrance !== undefined ? getHoursOf(singleDurationAtEntrance) : undefined,
      singleDurationAtEntranceMinutes:
        singleDurationAtEntrance !== undefined ? getMinutesOf(singleDurationAtEntrance) : undefined,
      isDurationTimesMultipleDialogActive: false,
    };
  },
  computed: {
    isPrioritySingle(): boolean {
      return this.disposalSiteAssignmentType === OrderDisposalSiteAssignmentType.PrioritySingle;
    },
    durationOfTaskHours(): number {
      return getHoursOf(this.totalDurationOfTasks);
    },
    durationOfTaskMinutes(): number {
      return getMinutesOf(this.totalDurationOfTasks);
    },
    isSingleDurationAtEntranceEdited(): boolean {
      if (this.orderAssignedDisposalSites.length !== 1 || this.localSingleDurationAtEntrance === undefined) {
        return false;
      }
      const [disposalSite] = this.disposalSiteEntities.filter(
        (disposalSite) => disposalSite.id === this.orderAssignedDisposalSites[0].disposalSiteId
      );
      return this.localSingleDurationAtEntrance !== disposalSite.durationAtEntrance;
    },
    totalSingleDurationHours(): Maybe<number> {
      if (this.localSingleDurationAtEntrance === undefined) {
        return;
      }
      return getHoursOf(this.localSingleDurationAtEntrance + this.totalDurationOfTasks);
    },
    totalSingleDurationMinutes(): Maybe<number> {
      if (this.localSingleDurationAtEntrance === undefined) {
        return;
      }
      return getMinutesOf(this.localSingleDurationAtEntrance + this.totalDurationOfTasks);
    },
    // NOTE: ~AllDuration は処分場が複数選択されている場合にパネルに表示する合計
    totalAllDurations(): Maybe<number> {
      if (this.localSingleDurationAtEntrance !== undefined) {
        return;
      }
      let total: number = 0;

      this.orderAssignedDisposalSites.forEach((orderAssignedDisposalSite) => {
        total += orderAssignedDisposalSite.durationAtEntrance;
      });
      total += this.totalDurationOfTasks;

      return total;
    },
    totalAllDurationsHours(): Maybe<number> {
      if (this.localSingleDurationAtEntrance !== undefined) {
        return;
      }

      const totalDuration = this.totalAllDurations;

      if (totalDuration === undefined) {
        return;
      }

      return getHoursOf(totalDuration);
    },
    totalAllDurationsMinutes(): Maybe<number> {
      if (this.localSingleDurationAtEntrance !== undefined) {
        return;
      }

      const totalDuration = this.totalAllDurations;

      if (totalDuration === undefined) {
        return;
      }

      return getMinutesOf(totalDuration);
    },
  },
  watch: {
    orderAssignedDisposalSites() {
      if (this.orderAssignedDisposalSites.length !== 1) {
        this.localSingleDurationAtEntrance = undefined;
        return;
      }
      this.localSingleDurationAtEntrance = this.orderAssignedDisposalSites[0].durationAtEntrance;
    },
    localSingleDurationAtEntrance() {
      if (this.localSingleDurationAtEntrance === undefined) {
        return;
      }
      this.singleDurationAtEntranceHours = getHoursOf(this.localSingleDurationAtEntrance);
      this.singleDurationAtEntranceMinutes = getMinutesOf(this.localSingleDurationAtEntrance);
    },
  },
  methods: {
    getHoursOf,
    getMinutesOf,
    getSecondsFromHours,
    getSecondsFromMinutes,
    async onSingleDurationAtEntranceChange(hours: number, minutes: number): Promise<void> {
      await this.$nextTick();
      if (this.orderAssignedDisposalSites.length !== 1 || hours === undefined || minutes === undefined) {
        return;
      }

      const totalSeconds =
        getSecondsFromHours(sanitizeNaturalNumber(hours)) + getSecondsFromMinutes(sanitizeNaturalNumber(minutes));

      this.localSingleDurationAtEntrance = totalSeconds;
      this.singleDurationAtEntranceHours = getHoursOf(totalSeconds);
      this.singleDurationAtEntranceMinutes = getMinutesOf(totalSeconds);
      const durationTimeItems: DisposalSiteDuration[] = [
        {
          disposalSiteId: this.orderAssignedDisposalSites[0].disposalSiteId,
          durationAtEntrance: this.localSingleDurationAtEntrance,
        },
      ];
      this.onDurationTimesUpdate(durationTimeItems);
    },
    resetSingleDurationAtEntrance(): void {
      if (this.orderAssignedDisposalSites.length !== 1) return;

      const [disposalSite] = this.disposalSiteEntities.filter(
        (disposalSite) => disposalSite.id === this.orderAssignedDisposalSites[0].disposalSiteId
      );
      this.localSingleDurationAtEntrance = disposalSite.durationAtEntrance;

      const durationTimeItems: DisposalSiteDuration[] = [
        {
          disposalSiteId: disposalSite.id,
          durationAtEntrance: disposalSite.durationAtEntrance,
        },
      ];

      this.onDurationTimesUpdate(durationTimeItems);
    },
    toggleDurationTimesMultipleDialog(value: boolean) {
      this.isDurationTimesMultipleDialogActive = value;
    },
    onDurationTimesUpdate(values: DisposalSiteDuration[]): void {
      this.toggleDurationTimesMultipleDialog(false);
      this.$emit(EventTypes.Change, values);
    },
  },
});
