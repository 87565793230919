import {
  AllContainerTypesQuery,
  AllContainerTypesQueryVariables,
  ContainerTypesByIdsQuery,
  ContainerTypesByIdsQueryVariables,
  CreateContainerTypeMutation,
  CreateContainerTypeMutationVariables as OriginalCreateContainerTypeMutationVariables,
  UpdateContainerTypeMutation,
  UpdateContainerTypeMutationVariables as OriginalUpdateContainerTypeMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllContainerTypes, ContainerTypesByIds } from '@/../graphql/server-api/queries/container-type.gql';
import { CreateContainerType, UpdateContainerType } from '@/../graphql/server-api/mutations/container-type.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateContainerTypeData, IUpdateContainerTypeData } from '~/framework/server-api/masters/containerType';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllContainerTypesQuery, AllContainerTypesQueryVariables>({
      query: AllContainerTypes,
      variables: {},
    });
    return result.allContainerTypes;
  }
}

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<ContainerTypesByIdsQuery, ContainerTypesByIdsQueryVariables>({
      query: ContainerTypesByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.containerTypesByIds, `ContainerTypesByIds`);
    return result.containerTypesByIds;
  }
}

type CreateContainerTypeMutationVariables = {
  containerType: Omit<
    OriginalCreateContainerTypeMutationVariables['containerType'],
    'durationOfDisposal' | 'durationOfLoading' | 'durationOfUnloading' | 'volume'
  >;
};

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateContainerTypeData) {
    const result = await this.mutate<CreateContainerTypeMutation, CreateContainerTypeMutationVariables>({
      mutation: CreateContainerType,
      variables: { containerType: data },
    });
    return result.createContainerType;
  }
}

type UpdateContainerTypeMutationVariables = {
  containerType: Omit<
    OriginalUpdateContainerTypeMutationVariables['containerType'],
    'durationOfDisposal' | 'durationOfLoading' | 'durationOfUnloading' | 'volume'
  >;
};

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateContainerTypeData) {
    const result = await this.mutate<UpdateContainerTypeMutation, UpdateContainerTypeMutationVariables>({
      mutation: UpdateContainerType,
      variables: { containerType: data },
    });
    return result.updateContainerType;
  }
}
