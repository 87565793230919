import {
  AllPackingStyleTaskTypeDefaultsQuery,
  AllPackingStyleTaskTypeDefaultsQueryVariables,
  UpdatePackingStyleTaskTypeDefaultsMutation,
  UpdatePackingStyleTaskTypeDefaultsMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllPackingStyleTaskTypeDefaults } from '@/../graphql/server-api/queries/packing-style-task-type-default.gql';
import { UpdatePackingStyleTaskTypeDefaults } from '@/../graphql/server-api/mutations/packing-style-task-type-default.gql';
import { IUpdatePackingStyleTaskTypeDefaultData } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<
      AllPackingStyleTaskTypeDefaultsQuery,
      AllPackingStyleTaskTypeDefaultsQueryVariables
    >({
      query: AllPackingStyleTaskTypeDefaults,
      variables: {},
    });
    return result.allPackingStyleTaskTypeDefaults;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdatePackingStyleTaskTypeDefaultData[]) {
    const result = await this.mutate<
      UpdatePackingStyleTaskTypeDefaultsMutation,
      UpdatePackingStyleTaskTypeDefaultsMutationVariables
    >({
      mutation: UpdatePackingStyleTaskTypeDefaults,
      variables: { packingStyleTaskTypeDefaults: data },
    });
    this.validateArrayConsistency(
      data,
      result.updatePackingStyleTaskTypeDefaults,
      'UpdatePackingStyleTaskTypeDefaults'
    );
    return result.updatePackingStyleTaskTypeDefaults;
  }
}
