import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { Maybe } from '~/framework/typeAliases';
import { IDisposalSiteData } from '~/framework/server-api/masters/disposalSite';
import { AbstractSiteEntity } from '../abstractSiteEntity';
import { ClientEntity } from '../client/clientEntity';

export const AcceptanceLimitDefault = 9999;

export class DisposalSiteEntity extends AbstractSiteEntity {
  code: Maybe<string>;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;

  acceptanceLimit: number;

  note: Maybe<string>;
  isAddressComplete: boolean;
  client: Maybe<ClientEntity>;

  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;

  get hasRestPeriod(): boolean {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    return this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }

  constructor(data: IDisposalSiteData, client: Maybe<ClientEntity>, createdBy: UserEntity) {
    super(data, createdBy);
    this.code = data.code;
    this.disposablePeriodStart = data.disposablePeriodStart;
    this.disposablePeriodEnd = data.disposablePeriodEnd;
    this.durationAtEntrance = data.durationAtEntrance;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.acceptanceLimit = data.acceptanceLimit;
    this.note = data.note;
    this.isAddressComplete = data.isAddressComplete;
    this.client = client;
    this.temporalAcceptanceInterval = data.temporalAcceptanceInterval;
    this.temporalAcceptanceLimit = data.temporalAcceptanceLimit;
  }

  update(data: IDisposalSiteData, client: Maybe<ClientEntity>, createdBy: UserEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of DisposalSiteEntity');
    }
    this.code = data.code;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.zipCode = data.zipCode;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.address3 = data.address3;
    this.address4 = data.address4;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.disposablePeriodStart = data.disposablePeriodStart;
    this.disposablePeriodEnd = data.disposablePeriodEnd;
    this.durationAtEntrance = data.durationAtEntrance;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.acceptanceLimit = data.acceptanceLimit;
    this.note = data.note;
    this.isAddressComplete = data.isAddressComplete;
    this.createdAt = data.createdAt;
    this.client = client;
    this.temporalAcceptanceInterval = data.temporalAcceptanceInterval;
    this.temporalAcceptanceLimit = data.temporalAcceptanceLimit;
    this.createdBy = createdBy;
  }
}
