import {
  AllBaseSitesQuery,
  AllBaseSitesQueryVariables,
  CreateBaseSiteMutation,
  CreateBaseSiteMutationVariables,
  UpdateBaseSiteMutation,
  UpdateBaseSiteMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllBaseSites } from '@/../graphql/server-api/queries/base-site.gql';
import { CreateBaseSite, UpdateBaseSite } from '@/../graphql/server-api/mutations/base-site.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateBaseSiteData, IUpdateBaseSiteData } from '~/framework/server-api/masters/baseSite';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllBaseSitesQuery, AllBaseSitesQueryVariables>({
      query: AllBaseSites,
      variables: {},
    });
    return result.allBaseSites;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateBaseSiteData) {
    const result = await this.mutate<CreateBaseSiteMutation, CreateBaseSiteMutationVariables>({
      mutation: CreateBaseSite,
      variables: { baseSite: data },
    });

    return result.createBaseSite;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateBaseSiteData) {
    const result = await this.mutate<UpdateBaseSiteMutation, UpdateBaseSiteMutationVariables>({
      mutation: UpdateBaseSite,
      variables: { baseSite: data },
    });

    return result.updateBaseSite;
  }
}
