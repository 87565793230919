import { OrderGroup } from '~/graphql/graphQLServerApi';
export const orderGroup$getAllSymbol = Symbol('getAll');

export type OrderGroupApiTypes = {
  [orderGroup$getAllSymbol]: IGetAllApi;
};

export interface IGetAllApi {
  getAll(): Promise<IOrderGroupData[]>;
}

export type IOrderGroupData = OrderGroup;
