import {
  AllDriverAttendancesByDateRangeQuery,
  AllDriverAttendancesByDateRangeQueryVariables,
  CreateDefaultDriverAttendancesMutation,
  CreateDefaultDriverAttendancesMutationVariables,
  CreateDefaultMultipleDriversAttendancesMutation,
  CreateDefaultMultipleDriversAttendancesMutationVariables,
  CreateDriverAttendancesMutation,
  CreateDriverAttendancesMutationVariables,
  DeleteDriverAttendancesMutation,
  DeleteDriverAttendancesMutationVariables,
  DriverAttendancesByDateRangeQuery,
  DriverAttendancesByDateRangeQueryVariables,
  UpdateDriverAttendancesMutation,
  UpdateDriverAttendancesMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  AllDriverAttendancesByDateRange,
  DriverAttendancesByDateRange,
} from '@/../graphql/server-api/queries/driver-attendance.gql';
import {
  CreateDefaultDriverAttendances,
  CreateDefaultMultipleDriversAttendances,
  CreateDriverAttendances,
  DeleteDriverAttendances,
  UpdateDriverAttendances,
} from '@/../graphql/server-api/mutations/driver-attendance.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  ICreateDriverAttendanceData,
  IUpdateDriverAttendanceData,
} from '~/framework/server-api/masters/driverAttendance';

import { Exception } from '~/framework/core/exception';

// ほぼあり得ないが、念のためデータ整合性をチェックしておく
const validateData = (datas: ICreateDriverAttendanceData[]) => {
  for (const data of datas) {
    if (data.restPeriodStart && data.restPeriodEnd) {
      if (data.restPeriodStart < data.regularWorkPeriodStart) {
        throw new Exception(
          `restPeriodStart should be greater than or equal to regularWorkPeriodStart! regularWorkPeriodStart: ${data.regularWorkPeriodStart}, restPeriodStart: ${data.restPeriodStart}`
        );
      }
      if (data.regularWorkPeriodEnd < data.restPeriodEnd) {
        throw new Exception(
          `restPeriodEnd should be less than or equal to regularWorkPeriodEnd! regularWorkPeriodEnd: ${data.regularWorkPeriodEnd}, restPeriodEnd: ${data.restPeriodEnd}`
        );
      }
    }
  }
};

export class GetByDateRangeApi extends GraphqlApiBase {
  async getByDateRange(driverId: string, start: Date, end: Date) {
    const result = await this.query<DriverAttendancesByDateRangeQuery, DriverAttendancesByDateRangeQueryVariables>({
      query: DriverAttendancesByDateRange,
      variables: { condition: { driverId, start, end } },
    });
    return result.driverAttendancesByDateRange;
  }
}

export class GetByDateRangeOfAllDriversApi extends GraphqlApiBase {
  async getByDateRangeOfAllDrivers(start: Date, end: Date) {
    const result = await this.query<
      AllDriverAttendancesByDateRangeQuery,
      AllDriverAttendancesByDateRangeQueryVariables
    >({
      query: AllDriverAttendancesByDateRange,
      variables: { condition: { start, end } },
    });
    return result.allDriverAttendancesByDateRange;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateDriverAttendanceData[]) {
    validateData(data);
    const result = await this.mutate<CreateDriverAttendancesMutation, CreateDriverAttendancesMutationVariables>({
      mutation: CreateDriverAttendances,
      variables: { driverAttendances: data },
    });
    this.validateArrayConsistency(data, result.createDriverAttendances, `CreateDriverAttendances`);
    return result.createDriverAttendances;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateDriverAttendanceData[]) {
    validateData(data);
    const result = await this.mutate<UpdateDriverAttendancesMutation, UpdateDriverAttendancesMutationVariables>({
      mutation: UpdateDriverAttendances,
      variables: { driverAttendances: data },
    });
    this.validateArrayConsistency(data, result.updateDriverAttendances, `UpdateDriverAttendances`);
    return result.updateDriverAttendances;
  }
}

export class DeleteApi extends GraphqlApiBase {
  async delete(ids: string[]) {
    const result = await this.mutate<DeleteDriverAttendancesMutation, DeleteDriverAttendancesMutationVariables>({
      mutation: DeleteDriverAttendances,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.deleteDriverAttendances, `DeleteDriverAttendances`);
    return result.deleteDriverAttendances;
  }
}

export class CreateDefault extends GraphqlApiBase {
  async createDefault(driverId: string, start: Date, end: Date) {
    const result = await this.mutate<
      CreateDefaultDriverAttendancesMutation,
      CreateDefaultDriverAttendancesMutationVariables
    >({
      mutation: CreateDefaultDriverAttendances,
      variables: { condition: { driverId, start, end } },
    });
    return result.createDefaultDriverAttendances;
  }

  async createDefaultMultiple(driverIds: string[], start: Date, end: Date) {
    const result = await this.mutate<
      CreateDefaultMultipleDriversAttendancesMutation,
      CreateDefaultMultipleDriversAttendancesMutationVariables
    >({
      mutation: CreateDefaultMultipleDriversAttendances,
      variables: { condition: { driverIds, start, end } },
    });
    return result.createDefaultMultipleDriversAttendances;
  }
}
