import _ from 'lodash';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { getOrInit } from '~/framework/core/map';
import {
  IPackingStyleFormValues,
  PackingStyleFormValues,
} from '~/components/panels/packing-placement/r-packing-placements-form/packingStyleFormValues';
import { IPackingPlacementsAtSite } from '~/framework/view-models/panels/packingPlacementsFormPanel';
import {
  IPackingPlacementFormValues,
  PackingPlacementFormValuesFactory,
} from '~/components/panels/packing-placement/r-packing-placements-form/packingPlacementFormValues';
import { IUpdatePackingPlacementData } from '~/framework/server-api/packing-placement/update';
import { unwrap } from '~/framework/core/value';
import { PackingPlacementService } from '~/framework/domain/packing-placement/packing-placement/packingPlacementService';
import { Maybe } from '~/framework/typeAliases';

export interface IFormValues {
  packingStyles: IPackingStyleFormValues[];
  setLastAllocatedAt: boolean;
  lastAllocatedAt: Maybe<Date>;

  /**
   * 最終設置日を設定する必要があるかどうか
   * 残存数が 0 なら設定する必要はない
   * 数が 1 以上の場合は設定する必要がある
   */
  requireLastAllocatedAt(): boolean;
  isEqualTo(another: IFormValues): boolean;
}

export class FormValues implements IFormValues {
  packingStyles: IPackingStyleFormValues[];
  setLastAllocatedAt: boolean;
  lastAllocatedAt: Maybe<Date>;

  constructor(packingStyles: IPackingStyleFormValues[], setLastAllocatedAt: boolean, lastAllocatedAt: Maybe<Date>) {
    this.packingStyles = packingStyles;
    this.setLastAllocatedAt = setLastAllocatedAt;
    this.lastAllocatedAt = lastAllocatedAt;
  }

  static clone(original: IFormValues): IFormValues {
    const packingStyles = original.packingStyles.map((packingStyle) => PackingStyleFormValues.clone(packingStyle));
    return new FormValues(packingStyles, original.setLastAllocatedAt, original.lastAllocatedAt);
  }

  requireLastAllocatedAt(): boolean {
    const packingPlacements = _.flatten(this.packingStyles.map((packingStyle) => packingStyle.packingPlacements));
    if (packingPlacements.length === 0) return false;
    return packingPlacements.some((packingPlacement) => 1 <= packingPlacement.num);
  }

  isEqualTo(another: IFormValues): boolean {
    // 後でデバッグしやすい様にやや冗長なやり方をしている
    const equalityMap: Map<keyof IFormValues, boolean> = new Map<keyof IFormValues, boolean>();
    equalityMap.set('setLastAllocatedAt', this.setLastAllocatedAt === another.setLastAllocatedAt);
    const packingStyles =
      this.packingStyles.length === another.packingStyles.length &&
      this.packingStyles.every((item, index) => item.isEqualTo(another.packingStyles[index]));
    equalityMap.set('packingStyles', packingStyles);
    return Array.from(equalityMap.values()).every((equality) => equality === true);
  }
}

export class FormValuesFactory {
  private readonly packingStyleEntities: PackingStyleEntity[];
  private readonly containerTypeEntities: ContainerTypeEntity[];
  private readonly packingPlacementService: PackingPlacementService;

  constructor(packingStyleEntities: PackingStyleEntity[], containerTypeEntities: ContainerTypeEntity[]) {
    this.packingStyleEntities = [...packingStyleEntities].sort((a, b) => a.code.localeCompare(b.code));
    this.containerTypeEntities = containerTypeEntities;
    this.packingPlacementService = new PackingPlacementService();
  }

  build(packingPlacementsAtSite: IPackingPlacementsAtSite): IFormValues {
    // packingPlacement からは packingStyle の ID が引けないのでマップを用意しておく
    const containerTypePackingStyleMap: Map<string, string> = new Map();
    for (const containerTypeEntity of this.containerTypeEntities) {
      containerTypePackingStyleMap.set(containerTypeEntity.id, containerTypeEntity.packingStyle.id);
    }

    const packingStyleContainersMap: Map<string, ContainerTypeEntity[]> = new Map();
    for (const containerTypeEntity of this.containerTypeEntities) {
      const containers = getOrInit(packingStyleContainersMap, containerTypeEntity.packingStyle.id, []);
      containers.push(containerTypeEntity);
    }

    const packingStylePackingPlacementsMap: Map<string, IPackingPlacementFormValues[]> = new Map();
    for (const packingPlacementEntity of packingPlacementsAtSite.packingPlacements) {
      const packingPlacement = PackingPlacementFormValuesFactory.buildByPackingPlacement(packingPlacementEntity);
      const packingStyleId = containerTypePackingStyleMap.getOrError(packingPlacementEntity.containerType.id);
      const packingPlacements = getOrInit(packingStylePackingPlacementsMap, packingStyleId, []);
      packingPlacements.push(packingPlacement);
    }

    const packingStyles: IPackingStyleFormValues[] = [];
    for (const packingStyleEntity of this.packingStyleEntities) {
      if (this.packingPlacementService.isPackingPlacementTracked(packingStyleEntity) === false) continue;
      packingStyles.push(
        new PackingStyleFormValues(
          packingStyleEntity.id,
          packingStyleEntity.name,
          packingStyleContainersMap.get(packingStyleEntity.id) ?? [],
          packingStylePackingPlacementsMap.get(packingStyleEntity.id) ?? []
        )
      );
    }

    return new FormValues(packingStyles, false, undefined);
  }
}

export class ApiDataFactory {
  /**
   * lastAllocatedAt は個別に与える事も可能だが、ひとまず全体で同じ値を設定する事にしている
   *
   * @param formValues
   * @param baseLastAllocatedAt 新規追加時にフォールバックで設定する最終設置日
   * @param specifiedLastAllocatedAt ユーザーが指定した最終設置日
   */
  buildUpdateData(
    formValues: IFormValues,
    baseLastAllocatedAt: Date,
    specifiedLastAllocatedAt: Maybe<Date>
  ): IUpdatePackingPlacementData[] {
    const data: IUpdatePackingPlacementData[] = [];
    for (const packingStyle of formValues.packingStyles) {
      for (const packingPlacement of packingStyle.packingPlacements) {
        data.push(this.buildPackingPlacementData(packingPlacement, baseLastAllocatedAt, specifiedLastAllocatedAt));
      }
    }
    return data;
  }

  private buildPackingPlacementData(
    formValues: IPackingPlacementFormValues,
    baseLastAllocatedAt: Date,
    specifiedLastAllocatedAt: Maybe<Date>
  ): IUpdatePackingPlacementData {
    const containerTypeId = unwrap(formValues.containerTypeId);
    const num = unwrap(formValues.num);
    const lastAllocatedAt =
      num === 0
        ? undefined
        : specifiedLastAllocatedAt !== undefined
        ? specifiedLastAllocatedAt
        : formValues.lastAllocatedAt !== undefined
        ? formValues.lastAllocatedAt
        : baseLastAllocatedAt;
    return {
      containerTypeId,
      num,
      lastAllocatedAt,
    };
  }
}
