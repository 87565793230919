import { Context as NuxtContext } from '@nuxt/types';
import { Maybe } from '~/framework/typeAliases';
import { Mio } from '~/framework/services/mio/mio';
import { Secrets } from '~/framework/secrets';
import { EventManager } from '~/framework/events/eventManager';
import { createLogger } from '~/framework/logger';
import { PanelManager } from '~/framework/view-models/panels/panelManager';
import { NotificationManager } from '~/framework/notifications';
import { UIEventManager } from '~/framework/uiEventManager';
import { Clipboard } from '~/framework/clipboard';
import { FeatureManager } from '~/framework/featureManager';
import { ApplicationServiceManager } from '~/framework/application/applicationServiceManager';
import { Store } from '~/framework/domain/store';
import { SessionData } from '~/framework/application/authentication/authenticationApplicationService';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { SnackbarData, Snackbar, SnackbarOption } from '~/framework/view-models/snackbarData';
import { VuetifyColors } from '~/framework/constants';
import {
  RinEventNames,
  AdditionalInfoKeys,
  RinEventPageNameTypes,
  RinEventNameTypes,
  RinEventShortCutKeyTypes,
} from '~/framework/services/rin-events/rinEventParams';

const logger = createLogger('SystemContext');

export type EntityIdGenerateFunctor = () => string;

export type ErrorContext = {
  queries?: string;
  mutations?: string;
  result: string;
  'x-request-id': string | undefined | null;
};

export type ErrorContextHandler = (name: string, context: ErrorContext) => void;

export type SessionHandler = (session: SessionData) => void;

export type Gtm = {
  /**
   * rin_events の pv 送信用メソッド。
   * @param page
   */
  pv(page: RinEventPageNameTypes): void;
  /**
   * Google Tag Managerにイベントを送信する。
   * options を追加で指定する場合は Google Tag Manager側で設定しないとAnalyticsに現れないので注意
   * @param eventName
   * @param options
   */
  // TODO: レコードのキーも型設定したいがいい感じにならなかったので一旦stringのままになっている
  push: (eventName: RinEventNameTypes, options?: Record<string, Maybe<string>>) => void;
  /**
   * rin_events の shortcut ログ送信用メソッド。
   * @param keey
   * @param options
   */
  shortcut(key: RinEventShortCutKeyTypes, options?: Record<string, Maybe<string>>): void;
};

export class SystemContext {
  private readonly rinEnv: Maybe<string>;
  private readonly nodeEnv: Maybe<string>;
  readonly rinVersion: string;
  private _nuxtContext: Maybe<NuxtContext>;
  private _secrets: Maybe<Secrets>;
  private _mio: Maybe<Mio>;
  private _notification: Maybe<NotificationManager>;
  private _eventManager: Maybe<EventManager>;
  private _panelManager: Maybe<PanelManager>;
  private _uiEventManager: Maybe<UIEventManager>;
  private _clipboard: Maybe<Clipboard>;
  private _featureManager: Maybe<FeatureManager>;
  private readonly errorContextHandler: ErrorContextHandler;
  private readonly sessionHandler: SessionHandler;
  hasLoggedIn: boolean = false;
  _currentSession: Maybe<SessionData> = undefined;
  public gtm: Maybe<Gtm> = undefined;

  // 新フレームワーク
  private _applications: Maybe<ApplicationServiceManager>;
  private _apis: Maybe<ServerApiManager>;
  private _store: Maybe<Store>;

  public snackbar: Snackbar = {
    success: (message: string, options?: SnackbarOption) => {
      this.events.snackbarAddEvent.emit(new SnackbarData(message, { color: VuetifyColors.Success, ...options }));
    },
    primary: (message: string, options?: SnackbarOption) => {
      this.events.snackbarAddEvent.emit(new SnackbarData(message, { color: VuetifyColors.Primary, ...options }));
    },
    info: (message: string, options?: SnackbarOption) => {
      this.events.snackbarAddEvent.emit(new SnackbarData(message, { color: VuetifyColors.BluishGray40, ...options }));
    },
    error: (errorMessage: string, options?: SnackbarOption) => {
      this.events.snackbarAddEvent.emit(
        new SnackbarData(errorMessage, {
          color: VuetifyColors.Error,
          described: options?.described ?? true,
          timeout: options?.timeout ?? -1,
          buttons: options?.buttons ?? undefined,
        })
      );
    },
    warning: (warningMessage: string, options?: SnackbarOption) => {
      this.events.snackbarAddEvent.emit(
        new SnackbarData(warningMessage, { color: options?.color ?? VuetifyColors.Warning, ...options })
      );
    },
  };

  get applications(): ApplicationServiceManager {
    if (this._applications === undefined) throw new Error('Call initialize() first!');
    return this._applications;
  }

  get apis(): ServerApiManager {
    if (this._apis === undefined) throw new Error('Call initialize() first!');
    return this._apis;
  }

  get store(): Store {
    if (this._store === undefined) throw new Error('Call initialize() first!');
    return this._store;
  }

  get isDevelopment(): boolean {
    const env = this.getRuntimeEnvironment();
    return env?.toLowerCase() === 'development';
  }

  get isStaging(): boolean {
    const env = this.getRuntimeEnvironment();
    return env?.toLowerCase() === 'staging';
  }

  get isProduction(): boolean {
    const env = this.getRuntimeEnvironment();
    return env?.toLowerCase() === 'production';
  }

  get isTest(): boolean {
    const env = this.getRuntimeEnvironment();
    return env?.toLowerCase() === 'test';
  }

  set currentSession(value: Maybe<SessionData>) {
    this._currentSession = value;
    if (this._currentSession !== undefined) this.sessionHandler(this._currentSession);
  }

  get currentSession(): Maybe<SessionData> {
    return this._currentSession;
  }

  constructor(
    rinEnv: Maybe<string>,
    nodeEnv: Maybe<string>,
    rinVersion: string,
    errorContextHandler: ErrorContextHandler,
    sessionHandler: SessionHandler
  ) {
    this.rinEnv = rinEnv;
    this.nodeEnv = nodeEnv;
    this.rinVersion = rinVersion;
    this.errorContextHandler = errorContextHandler;
    this.sessionHandler = sessionHandler;
  }

  get nuxtContext(): NuxtContext {
    if (this._nuxtContext === undefined) throw new Error('Call initialize() first!');
    return this._nuxtContext;
  }

  get secrets(): Secrets {
    if (this._secrets === undefined) throw new Error('Call initialize() first!');
    return this._secrets;
  }

  get mio(): Mio {
    if (this._mio === undefined) throw new Error('Call initialize() first!');
    return this._mio;
  }

  get notification(): NotificationManager {
    if (this._notification === undefined) throw new Error('Call initialize() first!');
    return this._notification;
  }

  get events(): EventManager {
    if (this._eventManager === undefined) throw new Error('Call initialize() first!');
    return this._eventManager;
  }

  get panels(): PanelManager {
    if (this._panelManager === undefined) throw new Error('Call initialize() first!');
    return this._panelManager;
  }

  get uiEvents(): UIEventManager {
    if (this._uiEventManager === undefined) throw new Error('Call initialize() first!');
    return this._uiEventManager;
  }

  get clipboard(): Clipboard {
    if (this._clipboard === undefined) throw new Error('Call initialize() first!');
    return this._clipboard;
  }

  get features(): FeatureManager {
    if (this._featureManager === undefined) throw new Error('Call initialize() first!');
    return this._featureManager;
  }

  initialize(
    nuxtContext: NuxtContext,
    secrets: Secrets,
    mio: Mio,
    notification: NotificationManager,
    eventManager: EventManager,
    panelManager: PanelManager,
    uiEventManager: UIEventManager,
    clipboard: Clipboard,
    featureManager: FeatureManager,
    applicationServiceManager: ApplicationServiceManager,
    apis: ServerApiManager,
    store: Store
  ): void {
    this._nuxtContext = nuxtContext;
    this._secrets = secrets;
    this._mio = mio;
    this._notification = notification;
    this._eventManager = eventManager;
    this._panelManager = panelManager;
    this._uiEventManager = uiEventManager;
    this._clipboard = clipboard;
    this._featureManager = featureManager;
    this._applications = applicationServiceManager;
    this._apis = apis;
    this._store = store;
    const rinVersion = this.rinVersion;

    this.gtm = {
      pv(page: RinEventPageNameTypes) {
        const key = AdditionalInfoKeys.PAGE;
        this.push(RinEventNames.VIEW, { [key]: page });
      },
      push(eventName: RinEventNameTypes, options?: Record<string, Maybe<string>>) {
        nuxtContext.app.$gtm.push({
          event: 'rin_events',
          event_name: eventName,
          version: rinVersion,
          office_id: nuxtContext.app.$context.currentSession?.officeId,
          /**
           * src/assets/styles/rinVariables.scss
           * $breakpoints.sm
           */
          is_smart: window.innerWidth <= 576,
          user_id: nuxtContext.app.$context.currentSession?.user.id,
          additional_info: JSON.stringify(options),
        });
      },
      shortcut(key: RinEventShortCutKeyTypes, options?: Record<string, Maybe<string>>) {
        const keyParam = { [AdditionalInfoKeys.KEY]: key };
        this.push(RinEventNames.SHORTCUT, { ...keyParam, ...options });
      },
    };
  }

  setErrorContext(name: string, context: ErrorContext): void {
    logger.info(`setting error context, name: ${name}`, context);
    this.errorContextHandler(name, context);
  }

  isCompatibleWith(browser: string): boolean {
    return browser.toLowerCase() === 'chrome';
  }

  private getRuntimeEnvironment(): Maybe<string> {
    return this.rinEnv !== undefined ? this.rinEnv : this.nodeEnv;
  }
}
