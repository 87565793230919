import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { ContainerTypeEntity as IContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';

/**
 * Composite な ContainerTypeEntity を編集するためのもの
 */
export interface IContainerTypeFormPanel extends IEntityFormPanel<IContainerTypeEntity> {}

export class ContainerTypeFormPanel
  extends AbstractEntityFormPanel<IContainerTypeEntity>
  implements IContainerTypeFormPanel {}
