
import Vue from 'vue';
import RPackingStyleVisibleSettings from '~/components/panels/masters/r-client-form/RPackingStyleVisibleSettings.vue';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required, maxLength } from '~/framework/view-models/rules';
import { yomiganize } from '~/framework/services/string/string';

import { CloseGenericObjectArgs, OpenGenericObjectArgs } from '~/framework/view-models/panels/genericObjectPanel';
import {
  IClientFormPanelOption,
  ICloseData,
  IOpenData,
  IFormValues,
} from '~/framework/view-models/panels/clientFormPanel';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { ClientEntity as IClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  PageNames,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

enum FormMode {
  Register,
  Edit,
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
};

type AsyncDataType = {
  viewModel: Maybe<IClientForm>;
};

type InitialFormValues = {
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  reservationSiteEnabled: Maybe<boolean>;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
};

interface IClientForm {
  title: string;
  formMode: FormMode;
  openData: Maybe<IOpenData>;
  createdClient: Maybe<IClientEntity>;
  updatedClient: Maybe<IClientEntity>;
  rules: { [key: string]: ValidationRule };
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  reservationSiteEnabled: boolean;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
  isRegistering: boolean;
  isFormValid: boolean;
  isNameRubyTooltipOpen: boolean;
  isDeletable: boolean;
  isDirty: boolean;
  isRegisterButtonDisabled: boolean;
  option: Maybe<IClientFormPanelOption>;
  registerButtonLabel: string;

  onChangeNameRuby(): void;
  onDeleteClient(): void;
}

class ClientForm implements IClientForm {
  title: string;
  formMode: FormMode;
  openData: Maybe<IOpenData>;
  createdClient: Maybe<IClientEntity>;
  updatedClient: Maybe<IClientEntity>;
  rules: { [key: string]: ValidationRule };
  name: string = '';
  nameRuby: string = '';
  note: Maybe<string> = undefined;
  reservationSiteEnabled: boolean = false;
  packingStyleReservationSettings: IPackingStyleReservationSetting[] = [];
  isRegistering: boolean = false;
  _isFormValid: boolean = false;
  isNameRubyTooltipOpen: boolean = false;
  isDeletable: boolean = false;
  isRegisterButtonDisabled: boolean = true;
  private initialFormValues: InitialFormValues;
  option: Maybe<IClientFormPanelOption>;
  registerButtonLabel: string;

  get isFormValid() {
    return this._isFormValid;
  }

  set isFormValid(value) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  get isDirty(): boolean {
    return (
      this.name !== this.initialFormValues.name ||
      this.nameRuby !== this.initialFormValues.nameRuby ||
      this.isPackingStyleReservationSettingDirty()
    );
  }

  constructor(openData: Maybe<IOpenData>, option?: IClientFormPanelOption) {
    this.openData = openData;
    this.rules = { required };
    this.formMode = this.openData === undefined ? FormMode.Register : FormMode.Edit;
    this.initialFormValues = this.getInitialFormValues(this.openData?.client);
    this.isDeletable = this.formMode === FormMode.Edit && openData?.generationSiteNum === 0;
    this.title = this.formMode === FormMode.Register ? '会社の登録' : '会社の編集';
    this.option = option;
    this.registerButtonLabel =
      option?.registerButtonLabel ?? (this.formMode === FormMode.Register ? '登録' : '編集完了');
    this.setFormValues({ ...this.initialFormValues, ...option?.initialFormValues });
  }

  private setFormValues(initialFormValues: { [P in keyof IFormValues]+?: IFormValues[P] | undefined }) {
    this.name = initialFormValues.name ?? '';
    this.nameRuby = initialFormValues.nameRuby ?? '';
    this.note = initialFormValues.note;
    this.reservationSiteEnabled = initialFormValues.reservationSiteEnabled ?? false;
    this.packingStyleReservationSettings = initialFormValues.packingStyleReservationSettings ?? [];
  }

  onChangeNameRuby(): void {
    if (this.nameRuby) {
      const [replacedNameRuby, containedIllegalValue] = yomiganize(this.nameRuby);
      Vue.nextTick().then(() => {
        this.nameRuby = replacedNameRuby;
        if (containedIllegalValue) {
          this.isNameRubyTooltipOpen = true;
          setTimeout(() => {
            this.isNameRubyTooltipOpen = false;
          }, 2000);
        }
      });
    }
  }

  onDeleteClient(): void {}

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isFormValid;
  }

  private getInitialFormValues(client: Maybe<IClientEntity>): InitialFormValues {
    return client === undefined ? this.getDefaultInitialFormValues() : this.getInitialFormValuesByClient(client);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      name: '',
      nameRuby: '',
      note: '',
      reservationSiteEnabled: false,
      packingStyleReservationSettings: [],
    };
  }

  private getInitialFormValuesByClient(client: IClientEntity): InitialFormValues {
    return {
      name: client.name,
      nameRuby: client.nameRuby,
      note: client.note,
      reservationSiteEnabled: client.reservationSiteEnabled,
      packingStyleReservationSettings: client.packingStyleReservationSettings,
    };
  }

  // NOTE: packingStyleReservationSettings の initialFormValues との同一性確認が大変なのでメソッドに切り出した
  private isPackingStyleReservationSettingDirty(): boolean {
    if (this.packingStyleReservationSettings.length !== this.initialFormValues.packingStyleReservationSettings.length)
      return true;

    // packingStyleId 順に sort
    this.packingStyleReservationSettings.sort((a, b) => a.packingStyle.id.localeCompare(b.packingStyle.id));
    this.initialFormValues.packingStyleReservationSettings.sort((a, b) =>
      a.packingStyle.id.localeCompare(b.packingStyle.id)
    );

    for (let i = 0; i < this.packingStyleReservationSettings.length; i++) {
      const initial = this.initialFormValues.packingStyleReservationSettings[i];
      const current = this.packingStyleReservationSettings[i];

      if (initial.packingStyle.id !== current.packingStyle.id || initial.visible !== current.visible) {
        return true;
      }

      // NOTE: containerTypeId 順に sort
      initial.containerTypeReservationSettings.sort((a, b) => a.containerType.id.localeCompare(b.containerType.id));
      current.containerTypeReservationSettings.sort((a, b) => a.containerType.id.localeCompare(b.containerType.id));

      for (let j = 0; j < initial.containerTypeReservationSettings.length; j++) {
        if (
          initial.containerTypeReservationSettings[j].containerType.id !==
            current.containerTypeReservationSettings[j].containerType.id ||
          initial.containerTypeReservationSettings[j].visible !== current.containerTypeReservationSettings[j].visible
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RClientForm',
  components: {
    RPackingStyleVisibleSettings,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    return {
      isActive: false,
      isConfirmDialogActive: false,
      isDeleteConfirmDialogActive: false,
      viewModel: undefined,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      FormMode,
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CLIENT;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.clientFormPanel.openEvent.on(this.onOpen);
    this.$context.panels.clientFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    maxLength,
    onPackingStyleReservationSettingsChange(packingStyleReservationSettings: IPackingStyleReservationSetting[]) {
      if (this.viewModel === undefined) return;
      this.viewModel.packingStyleReservationSettings = packingStyleReservationSettings;
    },
    async open(openData: Maybe<IOpenData>, option?: IClientFormPanelOption): Promise<void> {
      if (this.isActive) return;
      this.viewModel = new ClientForm(openData, option);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async close(preserveContext: boolean): Promise<void> {
      if (this.isActive === false) return;
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: CloseGenericObjectArgs<IOpenData, ICloseData> = {
        openData: viewModel.openData,
        closeData: {
          client: viewModel.openData?.client,
          createdClient: viewModel.createdClient,
          updatedClient: viewModel.updatedClient,
        },
      };
      this.$context.panels.clientFormPanel.closeEvent.emit(closeArgs);
      if (preserveContext && viewModel.option?.closeCallback !== undefined) {
        /**
         * updatedClientが存在し、isDeletedがtrueの場合は会社が削除されている
         * createdClientが存在していル場合は、新しい会社が作成されている
         * それ以外の場合は編集されたか、何もせず閉じられた場合なのでopenDataにあるclientを返す
         */
        viewModel.option.closeCallback(
          viewModel.updatedClient?.isDeleted ? undefined : viewModel.createdClient ?? viewModel.openData?.client
        );
      }
    },
    async onOpen(args: OpenGenericObjectArgs<IOpenData, IClientFormPanelOption>): Promise<void> {
      await this.open(args.openData, args.option);
    },
    /**
     * @param forceClose 編集した状態であってもダイアログ表示せずに強制的に閉じる場合にtrue
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async onCloseForm(forceClose: boolean = false, preserveContext: boolean = false): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (forceClose === false && this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close(preserveContext);
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    /*
    // 現状使っていないが今後なにかやりたくなる可能性もあるのでいったん残しておく
    async loadAsyncData(context: Context): Promise<void> {
    },
     */
    async onRegisterButtonClicked(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;
      if (viewModel.formMode === FormMode.Register) {
        const service = this.$context.applications.get(clientSymbol);
        const entity = await service.create({
          name: viewModel.name,
          nameRuby: viewModel.nameRuby,
          note: viewModel.note,
          status: SoftDeleteStatus.Active,
        });

        viewModel.createdClient = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        if (viewModel.openData?.client === undefined) throw new Error('Impossible!');
        const client = viewModel.openData.client;
        const service = this.$context.applications.get(clientSymbol);
        const packingStyleReservationSettings = viewModel.packingStyleReservationSettings.map((packingStyleSetting) => {
          return {
            packingStyleId: packingStyleSetting.packingStyle.id,
            visible: packingStyleSetting.visible,
            containerTypeReservationSettings: packingStyleSetting.containerTypeReservationSettings.map(
              (containerTypeSetting) => {
                return {
                  containerTypeId: containerTypeSetting.containerType.id,
                  visible: containerTypeSetting.visible,
                };
              }
            ),
          };
        });
        const entity = await service.update({
          id: client.persistentId,
          name: viewModel.name,
          nameRuby: viewModel.nameRuby,
          note: viewModel.note,
          status: client.status,
          packingStyleReservationSettings,
        });

        viewModel.updatedClient = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CLIENT,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('会社の登録完了');
      await this.close(true);
    },
    onClickDeleteButton(): void {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`Impossible!`);
      if (viewModel.formMode !== FormMode.Edit) throw new Error(`Impossible!`);

      if (viewModel.isDeletable) {
        this.isDeleteConfirmDialogActive = true;
      } else {
        this.$context.snackbar.error('紐づく排出場が1件以上、登録されている場合は会社を削除できません。');
      }
    },
    async onConfirmDelete(): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`Impossible!`);
      if (viewModel.formMode !== FormMode.Edit) throw new Error(`Impossible!`);

      const client = viewModel.openData?.client;
      if (client === undefined) throw new Error(`Impossible!`);
      const service = this.$context.applications.get(clientSymbol);
      viewModel.isRegistering = true;
      const entity = await service.delete(client.persistentId);
      viewModel.updatedClient = entity;
      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.DELETE, {
        [AdditionalInfoKeys.CLIENT_ID]: client.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CLIENT,
        [AdditionalInfoKeys.REFERRER]: PageNames.CLIENT_FORM,
      });

      this.$context.snackbar.success('会社の削除完了');
      await this.close(true);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(() => this.onCloseForm(true));
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.clientFormPanel.updateDisplayedWidth(value);
    },
  },
});
