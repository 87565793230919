import { JwnetWasteMaster } from '~/graphql/graphQLServerApi';

export const jwnetWasteMaster$getAllSymbol = Symbol('getAll');

export type JwnetWasteMasterApiTypes = {
  [jwnetWasteMaster$getAllSymbol]: IGetAllApi;
};

export interface IGetAllApi {
  getAll(): Promise<IJwnetWasteMasterData[]>;
}
export type IJwnetWasteMasterData = JwnetWasteMaster;
