import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';

/**
 * Car を編集するためのもの
 */
export interface ICarFormPanel extends IEntityFormPanel<CarEntity> {}

export class CarFormPanel extends AbstractEntityFormPanel<CarEntity> implements ICarFormPanel {}
