import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { CarEntity } from '~/framework/domain/masters/car/carEntity';
import { Maybe } from '~/framework/typeAliases';
import { IDriverAttendanceData } from '~/framework/server-api/masters/driverAttendance';

export class DriverAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  overtimeWorkType: OvertimeWorkType;

  overtimeWorkableDuration: number;

  get hasRestPeriod(): boolean {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    return this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }

  get isOvertimeWorkable(): boolean {
    return this.overtimeWorkType !== OvertimeWorkType.None;
  }

  attendance: AttendanceEntity;
  driver: DriverEntity;
  primaryCar: CarEntity;

  constructor(data: IDriverAttendanceData, attendance: AttendanceEntity, driver: DriverEntity, primaryCar: CarEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.forceRidePrimaryCar = data.forceRidePrimaryCar;
    this.templateName = data.templateName;
    this.regularWorkPeriodStart = data.regularWorkPeriodStart;
    this.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.overtimeWorkType = data.overtimeWorkType;
    this.overtimeWorkableDuration = data.overtimeWorkableDuration;
    this.attendance = attendance;
    this.driver = driver;
    this.primaryCar = primaryCar;
  }

  update(data: IDriverAttendanceData, attendance: AttendanceEntity, driver: DriverEntity, primaryCar: CarEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of DriverAttendanceEntity');
    }
    this.forceRidePrimaryCar = data.forceRidePrimaryCar;
    this.templateName = data.templateName;
    this.regularWorkPeriodStart = data.regularWorkPeriodStart;
    this.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.overtimeWorkType = data.overtimeWorkType;
    this.overtimeWorkableDuration = data.overtimeWorkableDuration;
    this.attendance = attendance;
    this.driver = driver;
    this.primaryCar = primaryCar;
  }
}
