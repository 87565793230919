import { MapperBase } from '~/framework/core/mapper';
import { TaskTypeEntity, BaseTaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { IBaseTaskTypeData, ITaskTypeData } from '~/framework/server-api/masters/taskType';
import { CarStore } from '../car/carStore';
import { BaseSiteStore } from '../base-site/baseSiteStore';
import { UserStore } from '../user/userStore';
import { DriverMapper } from '../driver/driverMapper';
import { DriverStore } from '../driver/driverStore';
import { DriverAttendanceTemplateStore } from '../driver-attendance-template/driverAttendanceTemplateStore';

export class TaskTypeMapper extends MapperBase<ITaskTypeData, TaskTypeEntity> {
  private driverMapper: DriverMapper;
  private baseTaskTypeMapper: BaseTaskTypeMapper;

  constructor(
    driverStore: DriverStore,
    carStore: CarStore,
    baseSiteStore: BaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.driverMapper = new DriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.baseTaskTypeMapper = new BaseTaskTypeMapper();
  }

  protected instantiateWithData(data: ITaskTypeData): TaskTypeEntity {
    const baseTaskType = this.baseTaskTypeMapper.mapSingle(data.baseTaskType);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    return new TaskTypeEntity(data, baseTaskType, bannedDrivers);
  }

  protected updateWithData(data: ITaskTypeData, entity: TaskTypeEntity): void {
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    entity.update(data, bannedDrivers);
  }
}

export class BaseTaskTypeMapper extends MapperBase<IBaseTaskTypeData, BaseTaskTypeEntity> {
  protected instantiateWithData(data: IBaseTaskTypeData): BaseTaskTypeEntity {
    return new BaseTaskTypeEntity(data);
  }

  updateWithData() {
    throw new Error('Logic Error, BaseTaskTypeは変更される仕様ではありません');
  }
}
