import { ErpClient } from '~/graphql/graphQLServerApi';

export const erpClient$getAllTransportationClientsSymbol = Symbol('allTransportationClients');

export type erpClientApiTypes = {
  [erpClient$getAllTransportationClientsSymbol]: IAllTransportationClientsApi;
};

export type IErpClientData = ErpClient;

export interface IAllTransportationClientsApi {
  getAllTransportationClients(): Promise<IErpClientData[]>;
}
