import { Id } from '~/framework/typeAliases';
import { CreateDriverInput, Driver, UpdateDriverInput } from '~/graphql/graphQLServerApi';
export const driver$getAllSymbol = Symbol('getAll');
export const driver$getByIdsSymbol = Symbol('driver$getByIdsSymbol');
export const driver$createSymbol = Symbol('create');
export const driver$updateSymbol = Symbol('update');

export type DriverApiTypes = {
  [driver$getAllSymbol]: IGetAllApi;
  [driver$getByIdsSymbol]: IGetByIdsApi;
  [driver$createSymbol]: ICreateApi;
  [driver$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: ICreateDriverData[]): Promise<string[]>;
}

export type IDriverData = Driver;

export interface IGetAllApi {
  /**
   * @param ids 仮に deleted だったとしても取得したいデータ
   */
  getAll(ids?: string[]): Promise<IDriverData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IDriverData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateDriverData[]): Promise<string[]>;
  updateDriverSequence(data: IUpdateDriverSequenceData): Promise<Id[]>;
}

export type ICreateDriverData = CreateDriverInput;

export type IUpdateDriverData = UpdateDriverInput;

export type IUpdateDriverSequenceData = {
  sortedDriverIds: Id[];
};
