import { WithoutId } from '~/framework/typeAliases';
import { CollectablePeriodTemplate } from '~/graphql/graphQLServerApi';

export const collectablePeriodTemplate$createSymbol = Symbol('create');

export const collectablePeriodTemplate$getAllSymbol = Symbol('getAll');

export const collectablePeriodTemplate$updateSymbol = Symbol('update');

export type CollectablePeriodTemplateApiTypes = {
  [collectablePeriodTemplate$getAllSymbol]: IGetAllApi;
  [collectablePeriodTemplate$updateSymbol]: IUpdateApi;
  [collectablePeriodTemplate$createSymbol]: ICreateApi;
};
/**
 * API でやり取りするデータの型
 */
export type ICollectablePeriodTemplateData = CollectablePeriodTemplate;

export interface ICreateApi {
  /**
   * 作成して ID の配列を返す
   * @param data
   */
  create(data: WithoutId<ICollectablePeriodTemplateData>[]): Promise<string[]>;
}

export interface IGetAllApi {
  getAll(): Promise<ICollectablePeriodTemplateData[]>;
}

export interface IUpdateApi {
  update(data: ICollectablePeriodTemplateData[]): Promise<string[]>;
}
