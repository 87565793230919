
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { format } from 'date-fns';
import ROrderAcceptanceCheckItem from './ROrderAcceptanceCheckItem.vue';
import { Maybe } from '~/framework/typeAliases';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import {
  RinEventDialogComponentParam,
  ShortcutKeyParams,
  RinEventNames,
  AdditionalInfoKeys,
  RinEventDateFormat,
  PageNames,
} from '~/framework/services/rin-events/rinEventParams';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RawScheduleInfeasibilityJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { Path } from '~/framework/constants';
import { formatDateToString } from '~/framework/services/date/date';
import { getBasePlanFromOrderPlanInput } from '~/framework/domain/schedule/order/orderUtils';
import { PreloadStatus } from '~/framework/domain/typeAliases';
import { ICreateOrderAcceptanceCheckData } from '~/framework/server-api/schedule/order/order-acceptance-check/create';

type DataType = {
  // NOTE: 候補日の数
  candidateCount: number;
  orderAcceptanceCheckCandidates: ICreateOrderAcceptanceCheckData[];
  // NOTE: 候補日の瞬間チェック中コンポーネントのインデックス
  // 1つずつ順番にチェックする
  candidateActiveIndex: Maybe<number>;
  isAcceptanceCheckInProgress: boolean;
  nationalHolidayService: NationalHolidayService;
  listenerDisposer: Maybe<() => void>;
};
enum EventTypes {
  Close = 'close',
  Register = 'register',
  /**
   * Order, OrderOrMaster の infeasibilities がこのコンポーネントに input されたという意図
   * フォームで使われるので合わせた形
   */
  InputPrecheckInfeasibilities = 'input:precheck-infeasibilities',
}

export default Vue.extend({
  name: 'ROrderAcceptanceCheckDialog',
  components: {
    ROrderAcceptanceCheckItem,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    orderAcceptanceCheckData: {
      type: Object as PropType<ICreateOrderAcceptanceCheckData>,
      required: true,
    },
    holidayRules: {
      type: Object as PropType<HolidayRuleEntity>,
      required: true,
    },
    registerButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      candidateCount: 5,
      orderAcceptanceCheckCandidates: [],
      candidateActiveIndex: undefined,
      isAcceptanceCheckInProgress: true,
      nationalHolidayService: new NationalHolidayService(),
      listenerDisposer: undefined,
    };
  },
  computed: {
    date(): Date {
      return getBasePlanFromOrderPlanInput(this.orderAcceptanceCheckData.order.plan).date;
    },
  },
  watch: {
    candidateActiveIndex(index: number) {
      // NOTE: candidateActiveIndex === this.candidateCount となるのは最後まで瞬間チェックした場合
      if (index >= this.candidateCount) {
        this.isAcceptanceCheckInProgress = false;
      }
    },
  },
  mounted() {
    this.orderAcceptanceCheckCandidates = this.generateOrderAcceptanceCheckCandidates(
      this.candidateCount,
      this.orderAcceptanceCheckData
    );

    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    format,
    // NOTE: count 件分翌営業日のデータを生成する
    generateOrderAcceptanceCheckCandidates(
      count: number,
      orderAcceptanceCheckData: ICreateOrderAcceptanceCheckData
    ): ICreateOrderAcceptanceCheckData[] {
      const result: ICreateOrderAcceptanceCheckData[] = [];
      let currentDate: Date = getBasePlanFromOrderPlanInput(orderAcceptanceCheckData.order.plan).date ?? this.date;
      _.times(count, () => {
        const date = this.nextBusinessDay(currentDate);

        // NOTE: 宵積みの荷下ろし日は自動的に候補日の翌日に変更する
        const unloadDate =
          this.orderAcceptanceCheckData.order.preloadStatus === PreloadStatus.Forced
            ? this.nextBusinessDay(date)
            : undefined;

        const plan = getBasePlanFromOrderPlanInput(orderAcceptanceCheckData.order.plan);

        const updatedOrderAcceptanceCheckData = {
          ...orderAcceptanceCheckData,
          order: {
            ...orderAcceptanceCheckData.order,
            plan: {
              candidateDates: [
                {
                  date,
                  collectablePeriodTemplateName: plan.collectablePeriodTemplateName,
                  collectablePeriodStart: plan.collectablePeriodStart,
                  collectablePeriodEnd: plan.collectablePeriodEnd,
                  unloadDate,
                },
              ],
            },
          },
        } as ICreateOrderAcceptanceCheckData;

        result.push(updatedOrderAcceptanceCheckData);
        currentDate = date;
      });
      return result;
    },
    onClickClose(): void {
      if (this.isAcceptanceCheckInProgress) {
        this.$rinGtm.push(RinEventNames.QUIT_ACCEPTANCE_CHECK, {
          [AdditionalInfoKeys.ORDER_ID]: this.orderAcceptanceCheckData.order.id,
        });
      } else {
        this.$rinGtm.push(RinEventNames.CLOSE_FORM, {
          [AdditionalInfoKeys.TARGET]: 'acceptance_check',
        });
      }
      this.$emit(EventTypes.Close, false);
    },
    onCompleteTodayAcceptanceCheck(isAcceptable: boolean): void {
      if (isAcceptable) {
        this.$rinGtm.push(RinEventNames.PASS_ACCEPTANCE_CHECK, {
          [AdditionalInfoKeys.ORDER_ID]: this.orderAcceptanceCheckData.order.id,
          [AdditionalInfoKeys.ERROR_COUNTS]: '0',
        });
        this.isAcceptanceCheckInProgress = false;
        return;
      }

      // NOTE: 当日受注不可の場合は、候補日の瞬間チェックを開始する
      this.candidateActiveIndex = 0;
    },
    onCompleteCandidateItemAcceptanceCheck(isAcceptable: boolean): void {
      if (isAcceptable) {
        const errorCounts = String(this.candidateActiveIndex);

        // NOTE: resume した場合は受注可になっている件数も error_counts に含める
        this.$rinGtm.push(RinEventNames.PASS_ACCEPTANCE_CHECK, {
          [AdditionalInfoKeys.ORDER_ID]: this.orderAcceptanceCheckData.order.id,
          [AdditionalInfoKeys.ERROR_COUNTS]: errorCounts,
        });
        this.isAcceptanceCheckInProgress = false;
      }

      // NOTE: undefined になることはありえないが、 typeguard が必要
      if (this.candidateActiveIndex === undefined) return;
      this.candidateActiveIndex += 1;
    },
    onAbortAcceptanceCheck(): void {
      this.isAcceptanceCheckInProgress = false;
    },
    onRegisterOrder(orderAcceptanceCheckData: ICreateOrderAcceptanceCheckData): void {
      // NOTE: date が undefined の時瞬間チェックは押せないはずだが、 typeguard が必要
      if (this.date === undefined) {
        return;
      }

      this.$rinGtm.push(RinEventNames.CHANGE_ORDER_DATE, {
        [AdditionalInfoKeys.ORDER_ID]: this.orderAcceptanceCheckData.order.id,
        [AdditionalInfoKeys.SELECTED_DATE]: format(this.date, RinEventDateFormat),
        [AdditionalInfoKeys.REFERRER]: PageNames.ACCEPTANCE_CHECK_DIALOG,
      });

      this.$emit(EventTypes.Register, orderAcceptanceCheckData);
    },
    onInputPrecheckInfeasibilities(errors: RawScheduleInfeasibilityJsonObject[]): void {
      this.$emit(EventTypes.InputPrecheckInfeasibilities, errors);
    },
    async onCheckSchedule(orderAcceptanceCheckData: ICreateOrderAcceptanceCheckData): Promise<void> {
      await this.$router.push({
        path: Path.schedule.index,
        hash: 'errors',
        query: {
          date: formatDateToString(this.date),
          orderGroupId: orderAcceptanceCheckData.order.orderGroupId,
        },
        replace: true,
      });
      this.onClickClose();
    },
    async onCreateSchedule(orderAcceptanceCheckData: ICreateOrderAcceptanceCheckData): Promise<void> {
      if (this.date === undefined) throw new Error(`date is undefined! ${JSON.stringify(orderAcceptanceCheckData)}`);

      await this.$context.events.createScheduleEvent.emit({
        date: this.date,
        orderGroupId: orderAcceptanceCheckData.order.orderGroupId,
      });
      this.onClickClose();
    },
    async onEditAttendance(): Promise<void> {
      await this.$router.push({
        path: Path.masters.driver,
        hash: 'driver-attendances',
        query: { date: formatDateToString(this.date) },
      });
      this.onClickClose();
    },
    async onClickRinLink(path: string): Promise<void> {
      await this.$router.push({ path });
      this.onClickClose();
    },
    // NOTE: さらに5営業日候補を追加する
    onClickAddCandidateDates(): void {
      this.$rinGtm.push(RinEventNames.RESUME_ACCEPTANCE_CHECK);
      this.candidateCount += 5;
      // NOTE: 候補の最後の日から次の営業日を探索する
      const lastCandidate = this.orderAcceptanceCheckCandidates[this.orderAcceptanceCheckCandidates.length - 1];
      const newCandidates = this.generateOrderAcceptanceCheckCandidates(5, lastCandidate);
      this.orderAcceptanceCheckCandidates = [...this.orderAcceptanceCheckCandidates, ...newCandidates];
      this.isAcceptanceCheckInProgress = true;
    },
    nextBusinessDay(baseDate: Date) {
      const date = _.cloneDeep(baseDate);
      date.setDate(date.getDate() + 1);
      while (this.nationalHolidayService.isHoliday(date, this.holidayRules)) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickClose);
      }
      context.stop();
    },
  },
});
