
import Vue from 'vue';
import prefectures from '~/assets/settings/prefectures.json';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import {
  baseSiteSymbol,
  BaseSiteApplicationService,
} from '~/framework/application/masters/base-site/baseSiteApplicationService';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { GoogleMapService, IGoogleMapService } from '~/framework/services/google-maps/googleMapService';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { required } from '~/framework/view-models/rules';
import { BaseSiteMutationErrorTypes, IBaseSiteMutationError } from '~/framework/server-api/masters/baseSite';
import { ensure } from '~/framework/core/value';
import { yomiganize } from '~/framework/services/string/string';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

enum FormMode {
  Register,
  Edit,
}

const FormTitles = {
  [FormMode.Register]: '駐車・荷姿設置拠点の登録',
  [FormMode.Edit]: '駐車・荷姿設置拠点の編集',
} as const;

enum EventTypes {
  Change = 'change',
}

type DataType = {
  /** 拠点のサービス */
  baseSiteApplicationService: BaseSiteApplicationService;
  /** Google Map */
  google: IGoogleMapService;
  /** `FormMode` への参照 */
  FormMode: typeof FormMode;
  prefectures: typeof prefectures;

  /* 入力フォームの制御 */
  formMode: FormMode;
  formValues: IFormValues;
  initialFormValues: Maybe<Readonly<IFormValues>>;
  formErrors: IFormErrors;
  rules: {
    required: typeof required;
  };
  isFormValid: boolean;
  isAddressValidated: boolean;

  /** 編集中/登録完了/更新完了したこのフォームに関連する拠点エンティティの参照 */
  entities: {
    editingBaseSite: Maybe<BaseSiteEntity>;
    createdBaseSite: Maybe<BaseSiteEntity>;
    updatedBaseSite: Maybe<BaseSiteEntity>;
  };

  /* パネルの状態 */
  isActive: boolean;
  isRegistering: boolean;
  isPastingAddress: boolean;
  listenerDisposer: Maybe<() => void>;

  /* パネルの制御下にあるコンポーネントの状態 */
  widgets: {
    nameRubyTooltip: {
      isOpen: boolean;
    };
    addressAutocompleteAlert: {
      isActive: boolean;
    };
    closeConfirmDialog: {
      isActive: boolean;
      resolver: Maybe<(value: boolean) => void>;
    };
  };
};

type ErrorTypes = BaseSiteMutationErrorTypes['__typename'];

const errorMessages: Map<ErrorTypes, string> = new Map([
  ['DuplicatedNameError', '同じ名前が存在します。'],
  ['HasCarsMustBeTrueError', 'この拠点に駐車している車番が存在するため、駐車を不可にできません。'],
  ['HasCarsOrHasContainersMustBeTrueError', '駐車か荷姿の設置のどちらかは可能にする必要があります。'],
]);

interface IFormErrors {
  // リアクティブに動かしたいため、配列にしてる
  fields: {
    name: ErrorTypes[];
    hasCars: ErrorTypes[];
    hasContainers: ErrorTypes[];
  };

  addError(field: keyof IFormErrors['fields'], error: ErrorTypes): void;
  removeError(field: keyof IFormErrors['fields'], error: ErrorTypes): void;
  resetAllErrors(): void;
}

class FormErrors implements IFormErrors {
  fields: IFormErrors['fields'];

  constructor() {
    this.fields = {
      name: [],
      hasCars: [],
      hasContainers: [],
    };
  }

  addError(field: keyof IFormErrors['fields'], error: ErrorTypes): void {
    this.fields[field].push(error);
  }

  removeError(field: keyof IFormErrors['fields'], error: ErrorTypes): void {
    this.fields[field] = this.fields[field].filter((item) => item !== error);
  }

  resetAllErrors(): void {
    this.fields = {
      name: [],
      hasCars: [],
      hasContainers: [],
    };
  }
}

interface IFormValues {
  name: string;
  nameRuby: string;
  zipCode: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
  hasCars: boolean;
  hasContainers: boolean;

  hasChanges(that: IFormValues): boolean;
}

/**
 * 入力フォームの状態
 */
class FormValues implements IFormValues {
  name: string = '';
  nameRuby: string = '';
  zipCode: string = '';
  address1: string = '';
  address2: string = '';
  address3: string = '';
  address4: string = '';
  latitude: Maybe<number>;
  longitude: Maybe<number>;
  hasCars: boolean = false;
  hasContainers: boolean = false;

  constructor(
    name: string = '',
    nameRuby: string = '',
    zipCode: string = '',
    address1: string = '',
    address2: string = '',
    address3: string = '',
    address4: string = '',
    latitude: Maybe<number> = undefined,
    longitude: Maybe<number> = undefined,
    hasCars: boolean = false,
    hasContainers: boolean = false
  ) {
    this.name = name;
    this.nameRuby = nameRuby;
    this.zipCode = zipCode;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.address4 = address4;
    this.latitude = latitude;
    this.longitude = longitude;
    this.hasCars = hasCars;
    this.hasContainers = hasContainers;
  }

  hasChanges(another: IFormValues): boolean {
    return (
      this.name !== another.name ||
      this.nameRuby !== another.nameRuby ||
      this.zipCode !== another.zipCode ||
      this.address1 !== another.address1 ||
      this.address2 !== another.address2 ||
      this.address3 !== another.address3 ||
      this.address4 !== another.address4 ||
      this.latitude !== another.latitude ||
      this.longitude !== another.longitude ||
      this.hasCars !== another.hasCars ||
      this.hasContainers !== another.hasContainers
    );
  }

  static createEmpty(): FormValues {
    return new FormValues();
  }

  /**
   * 拠点のエンティティから入力フォームの初期値を補完する
   *
   * @param baseSite
   * @returns
   */
  static fromEntity(baseSite: BaseSiteEntity): FormValues {
    return new FormValues(
      baseSite.name,
      baseSite.nameRuby,
      baseSite.zipCode ?? '',
      baseSite.address1,
      baseSite.address2,
      baseSite.address3,
      baseSite.address4 ?? '',
      baseSite.latitude,
      baseSite.longitude,
      baseSite.hasCars,
      baseSite.hasContainers
    );
  }
}

export default Vue.extend({
  name: 'RBaseSiteForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const baseSiteApplicationService = this.$context.applications.get(baseSiteSymbol);
    return {
      baseSiteApplicationService,
      google: new GoogleMapService(this.$google),
      FormMode,
      prefectures,

      formMode: FormMode.Register,
      formValues: FormValues.createEmpty(),
      initialFormValues: undefined,
      formErrors: new FormErrors(),
      rules: {
        required,
      },
      isFormValid: true,
      isAddressValidated: false,

      entities: {
        editingBaseSite: undefined,
        createdBaseSite: undefined,
        updatedBaseSite: undefined,
      },

      isActive: false,
      isRegistering: false,
      isPastingAddress: false,
      listenerDisposer: undefined,
      widgets: {
        nameRubyTooltip: {
          isOpen: false,
        },
        addressAutocompleteAlert: {
          isActive: false,
        },
        closeConfirmDialog: {
          isActive: false,
          resolver: undefined,
        },
      },
    };
  },
  computed: {
    title(): string {
      return FormTitles[this.formMode];
    },
    address1Options(): { id: string; name: string }[] {
      return this.prefectures.map((value) => ({ id: value, name: value }));
    },
    toggleOptions(): { value: boolean; label: string }[] {
      return [
        {
          value: true,
          label: '可能',
        },
        {
          value: false,
          label: '不可能',
        },
      ];
    },
    isRegisterButtonEnabled(): boolean {
      return this.isAddressValidated && this.isFormValid;
    },
    registerLabel(): string {
      return this.formMode === FormMode.Register ? '登録' : '編集完了';
    },
    isDirty(): boolean {
      if (this.formValues && this.initialFormValues) {
        return this.formValues.hasChanges(this.initialFormValues);
      } else {
        return false;
      }
    },
    nameErrorMessages(): string[] {
      return this.formErrors.fields.name.map((error) => errorMessages.getOrError(error));
    },
    hasCarsErrorMessages(): string[] {
      return this.formErrors.fields.hasCars.map((error) => errorMessages.getOrError(error));
    },
    hasContainersErrorMessages(): string[] {
      return this.formErrors.fields.hasContainers.map((error) => errorMessages.getOrError(error));
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.BASE_SITE;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.baseSiteFormPanel.openFormEvent.on(this.onOpen);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );

    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };

    this.$context.panels.baseSiteFormPanel.registerCloseHandler(this.onCloseForm);
    this.baseSiteApplicationService.addCreatePresenter({
      create: this.onCreate,
      errorOnCreate: this.onFormError,
    });
    this.baseSiteApplicationService.addUpdatePresenter({
      update: this.onUpdate,
      errorOnUpdate: this.onFormError,
    });
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    /* event handlers for the components' own event */
    /**
     * パネルが開かれた時のイベント処理
     * @param baseSite
     * @returns
     */
    async open(baseSite: Maybe<BaseSiteEntity>): Promise<void> {
      if (this.isActive) return;

      this.initializeForm(baseSite);
      await (this.$refs.RSideform as RSideformInstance).open();

      this.isActive = true;
      this.$emit(EventTypes.Change, true);
    },
    /**
     * パネルが閉じられた時のイベント処理
     * @returns
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;

      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, false);

      const closeArgs: ICloseEntityFormArgs<BaseSiteEntity> = {
        entity: this.entities.editingBaseSite,
        createdEntity: this.entities.createdBaseSite,
        updatedEntity: this.entities.updatedBaseSite,
        removedEntity: undefined,
      };
      this.$context.panels.baseSiteFormPanel.closeFormEvent.emit(closeArgs);
      this.widgets.closeConfirmDialog = {
        isActive: false,
        resolver: undefined,
      };
      this.formErrors.resetAllErrors();
    },
    /**
     * 郵便番号から住所を入力
     */
    async onClickAutocomplete(): Promise<void> {
      this.$rinGtm.push(RinEventNames.AUTO_COMPLETE_ADDRESS_FROM_ZIPCODE);
      try {
        const zipCode = this.formValues.zipCode;
        if (zipCode === undefined) throw new Error(`zipCode is null`);
        const addresses = await this.google.getAddresses(zipCode);
        const preferredAddress = addresses.preferredAddress;
        if (preferredAddress === undefined) throw new Error(`preferredAddress is null`);
        this.formValues.address1 = preferredAddress.address1;
        this.formValues.address2 = preferredAddress.address2;
      } catch (error) {
        this.widgets.addressAutocompleteAlert.isActive = true;
      }
    },
    onClickConfirmAutocompleteError(): void {
      this.widgets.addressAutocompleteAlert.isActive = false;
    },
    async onInputZipCode(): Promise<void> {
      let value = this.formValues.zipCode;
      if (window.event && window.event.type === 'input') {
        const event = window.event as InputEvent;
        if (event.inputType === 'insertText' && event.isComposing === false) {
          value = value.replace(/[^0-9-]/g, '');
        }
      }
      value = value
        // 全角を半角に変換
        .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
        // 半角数字以外を除外
        .replace(/[^0-9-]/g, '');
      if (value.length === 4 && value.match(/^[0-9]{4}$/)) {
        // ハイフンを自動入力
        value = value.slice(0, 3) + '-' + value.slice(3, 4);
      } else if (9 <= value.length) {
        // ハイフン含めて9文字以上の郵便番号はあり得ないので削る
        value = value.slice(0, 8);
      }
      await Vue.nextTick();
      this.formValues.zipCode = value;
    },
    /** 住所をペースト */
    async onClickPaste(): Promise<void> {
      this.$rinGtm.push(RinEventNames.PASTE_ADDRESS);
      this.isPastingAddress = true;
      let success = false;
      const text = await this.$context.clipboard.readText();
      if (text === undefined) {
        this.$context.snackbar.error('クリップボードが利用できません。\nブラウザの設定を確認して下さい。');
      } else {
        try {
          const addresses = await this.google.getAddressesFrom(text);

          const address = addresses.preferredAddress;
          if (address !== undefined) {
            this.formValues.zipCode = address.postalCode;
            this.formValues.address1 = address.address1;
            this.formValues.address2 = address.address2;
            this.formValues.address3 = address.address3;
            this.formValues.address4 = address.address4;
            success = true;
          }
        } catch (e) {}
        if (!success) {
          this.$context.snackbar.error('住所ではないためペーストできませんでした。');
        }
      }
      this.isPastingAddress = false;
    },
    /** 登録 */
    async onClickRegister(): Promise<void> {
      this.isRegistering = true;
      this.formErrors.resetAllErrors();
      ensure(this.formValues.latitude);
      ensure(this.formValues.longitude);
      if (this.formMode === FormMode.Register) {
        await this.baseSiteApplicationService.create({
          name: this.formValues.name,
          nameRuby: this.formValues.nameRuby,
          zipCode: this.formValues.zipCode,
          address1: this.formValues.address1,
          address2: this.formValues.address2,
          address3: this.formValues.address3,
          address4: this.formValues.address4,
          latitude: this.formValues.latitude,
          longitude: this.formValues.longitude,
          hasCars: this.formValues.hasCars,
          hasContainers: this.formValues.hasContainers,
        });
      } else if (this.formMode === FormMode.Edit) {
        ensure(this.entities.editingBaseSite);
        await this.baseSiteApplicationService.update({
          id: this.entities.editingBaseSite.id,
          name: this.formValues.name,
          nameRuby: this.formValues.nameRuby,
          zipCode: this.formValues.zipCode,
          address1: this.formValues.address1,
          address2: this.formValues.address2,
          address3: this.formValues.address3,
          address4: this.formValues.address4,
          latitude: this.formValues.latitude,
          longitude: this.formValues.longitude,
          hasCars: this.formValues.hasCars,
          hasContainers: this.formValues.hasContainers,
        });
      }

      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.BASE_SITE,
        [AdditionalInfoKeys.MODE]: this.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onClickConfirmClose(confirm: boolean): void {
      this.widgets.closeConfirmDialog.isActive = false;
      if (this.widgets.closeConfirmDialog.resolver === undefined)
        throw new Error(`RBaseSiteForm: closeConfirmDialog resolver is not set`);

      this.widgets.closeConfirmDialog.resolver(confirm);
    },
    onChangeName(): void {
      this.formErrors.removeError('name', 'DuplicatedNameError');
    },
    onChangeHasCars(value: boolean): void {
      if (!value) return;
      this.formErrors.removeError('hasCars', 'HasCarsMustBeTrueError');
      this.formErrors.removeError('hasCars', 'HasCarsOrHasContainersMustBeTrueError');
      this.formErrors.removeError('hasContainers', 'HasCarsOrHasContainersMustBeTrueError');
    },
    onChangeHasContainers(value: boolean): void {
      if (!value) return;
      this.formErrors.removeError('hasContainers', 'HasCarsOrHasContainersMustBeTrueError');
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.close);
        context.stop();
      }
    },
    onCreate(baseSite: BaseSiteEntity): void {
      this.entities.createdBaseSite = baseSite;
      this.$context.snackbar.success('駐車・荷姿設置拠点の登録完了');
      this.$nextTick(this.close);
    },
    onUpdate(baseSite: BaseSiteEntity): void {
      this.entities.updatedBaseSite = baseSite;
      this.$context.snackbar.success('駐車・荷姿設置拠点の編集完了');
      this.$nextTick(this.close);
    },
    onFormError({ errors }: IBaseSiteMutationError): void {
      for (const err of errors) {
        switch (err.__typename) {
          case 'DuplicatedNameError':
            this.formErrors.addError('name', 'DuplicatedNameError');
            break;

          case 'HasCarsMustBeTrueError':
            this.formErrors.addError('hasCars', 'HasCarsMustBeTrueError');
            break;

          case 'HasCarsOrHasContainersMustBeTrueError':
            this.formErrors.addError('hasCars', 'HasCarsOrHasContainersMustBeTrueError');
            this.formErrors.addError('hasContainers', 'HasCarsOrHasContainersMustBeTrueError');
            break;

          default:
            break;
        }
      }
    },

    /* subscribe to panel manager events */
    async onOpen({ entity }: IOpenEntityFormArgs<BaseSiteEntity>): Promise<void> {
      await this.open(entity);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.isDirty) {
        const closeConfirmPromise = new Promise<boolean>((resolve) => {
          this.widgets.closeConfirmDialog.isActive = true;
          this.widgets.closeConfirmDialog.resolver = resolve;
        });
        const isClosable = await closeConfirmPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.baseSiteFormPanel.updateDisplayedWidth(value);
    },
    onChangeNameRuby(): void {
      if (!this.formValues.nameRuby) return;
      const [replacedNameRuby, containedIllegalValue] = yomiganize(this.formValues.nameRuby);
      Vue.nextTick().then(() => {
        this.formValues.nameRuby = replacedNameRuby;
        if (containedIllegalValue) {
          this.widgets.nameRubyTooltip.isOpen = true;
          setTimeout(() => {
            this.widgets.nameRubyTooltip.isOpen = false;
          }, 2000);
        }
      });
    },

    /* helpers */
    initializeForm(baseSite: Maybe<BaseSiteEntity>): void {
      const newFormMode = baseSite === undefined ? FormMode.Register : FormMode.Edit;

      // 編集用のオブジェクトとサーバーから取得したデータは個別に保持する
      this.initialFormValues = this.getInitialFormValues(baseSite);
      this.formValues = this.getInitialFormValues(baseSite);

      this.formMode = newFormMode;

      // 編集中のデータに関連するエンティティを最新の状態にする
      this.entities.editingBaseSite = baseSite;

      // 編集モードの場合は住所の補完ができているものとみなす
      this.isAddressValidated = baseSite !== undefined;
    },
    getInitialFormValues(baseSite: Maybe<BaseSiteEntity>): IFormValues {
      return baseSite === undefined ? FormValues.createEmpty() : FormValues.fromEntity(baseSite);
    },
  },
});
