import { RawLocation } from 'vue-router/types/router';

export interface ISnackbarButton {
  label: string;
  /**
   * 画面遷移先
   */
  location: RawLocation;
  /**
   * 画面遷移後にページをリロードするか
   */
  shouldReload?: boolean;
}

export class SnackbarButton implements ISnackbarButton {
  label: string;
  location: RawLocation;
  shouldReload?: boolean;

  constructor(label: string, location: RawLocation, shouldReload: boolean = false) {
    this.label = label;
    this.location = location;
    this.shouldReload = shouldReload;
  }
}
