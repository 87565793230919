import { StoredMapperBase } from '~/framework/core/mapper';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { CarTypeStore } from '~/framework/domain/masters/car-type/carTypeStore';
import { CarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { CarTypeContainerTypeMapper } from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeMapper';
import { ICarTypeData } from '~/framework/server-api/masters/carType';
import { ContainerTypeStore } from '../container-type/containerTypeStore';
import { PackingStyleStore } from '../packing-style/packingStyleStore';

export class CarTypeMapper extends StoredMapperBase<ICarTypeData, CarTypeEntity> {
  protected store: CarTypeStore;
  private orderGroupMapper: OrderGroupMapper;
  private carTypeContainerTypeMapper: CarTypeContainerTypeMapper;

  constructor(
    store: CarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: CarTypeContainerTypeStore,
    containerTypeStore: ContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.carTypeContainerTypeMapper = new CarTypeContainerTypeMapper(
      carTypeContainerTypeStore,
      containerTypeStore,
      packingStyleStore
    );
  }

  protected instantiateWithData(data: ICarTypeData): CarTypeEntity {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    return new CarTypeEntity(data, orderGroup, loadableContainerTypes);
  }

  protected updateWithData(data: ICarTypeData, entity: CarTypeEntity): void {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    entity.update(data, orderGroup, loadableContainerTypes);
  }
}
