import { AcceptReservationInput, AcceptReservationMutationResult } from '@/graphql/graphQLServerApi';

export const reservation$acceptReservationSymbol = Symbol('reservation$acceptReservationSymbol');

export interface IAcceptReservationApi {
  [reservation$acceptReservationSymbol]: void;
  acceptReservation(data: IAcceptReservationData): Promise<IAcceptReservationMutationResultData>;
}

export type IAcceptReservationData = AcceptReservationInput;

export type IAcceptReservationMutationResultData = AcceptReservationMutationResult;
