import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IRoutingRegulationGenerationSiteData } from '~/framework/server-api/masters/routingRegulation';
import { GenerationSiteEntity } from '../generation-site/generationSiteEntity';

export class RoutingRegulationEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  createdBy: UserEntity;
  updatedBy: UserEntity;
  createdAt: Date;
  updatedAt: Date;
  generationSites: GenerationSiteEntity[];
  optionalGenerationSites: GenerationSiteEntity[];
  preserveGenerationSiteSequence: boolean;

  constructor(
    data: IRoutingRegulationGenerationSiteData,
    generationSites: GenerationSiteEntity[],
    optionalGenerationSites: GenerationSiteEntity[],
    createdBy: UserEntity,
    updatedBy: UserEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.generationSites = generationSites;
    this.optionalGenerationSites = optionalGenerationSites;
    this.preserveGenerationSiteSequence = data.preserveGenerationSiteSequence;
  }

  update(
    data: IRoutingRegulationGenerationSiteData,
    generationSites: GenerationSiteEntity[],
    optionalGenerationSites: GenerationSiteEntity[],
    createdBy: UserEntity,
    updatedBy: UserEntity
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of RoutingRegulationEntity');
    }
    this.name = data.name;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.generationSites = generationSites;
    this.optionalGenerationSites = optionalGenerationSites;
    this.preserveGenerationSiteSequence = data.preserveGenerationSiteSequence;
  }
}
